var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-snackbar",
    {
      staticClass: "pt-0",
      attrs: {
        top: "",
        color: _vm.color,
        timeout: _vm.color === "error" ? -1 : 10000
      },
      scopedSlots: _vm._u([
        {
          key: "action",
          fn: function() {
            return [
              _c(
                "v-btn",
                {
                  staticClass: "mt-1",
                  attrs: { icon: "" },
                  on: {
                    click: function($event) {
                      _vm.show = false
                    }
                  }
                },
                [_c("v-icon", [_vm._v("mdi-close")])],
                1
              )
            ]
          },
          proxy: true
        }
      ]),
      model: {
        value: _vm.show,
        callback: function($$v) {
          _vm.show = $$v
        },
        expression: "show"
      }
    },
    [
      _c(
        "v-row",
        { staticClass: "ma-0", attrs: { align: "center" } },
        [
          _c("div", { domProps: { innerHTML: _vm._s(_vm.message) } }),
          _vm._v(" "),
          this.message.length > 400
            ? _c(
                "v-btn",
                {
                  staticClass: "mt-1",
                  attrs: { color: "secondary", small: "" },
                  on: {
                    click: function($event) {
                      _vm.show = false
                    }
                  }
                },
                [_vm._v("\n      Fenster schließen\n    ")]
              )
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }