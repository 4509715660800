import { render, staticRenderFns } from "./FieldTable.vue?vue&type=template&id=e3b7175c&scoped=true&"
import script from "./FieldTable.vue?vue&type=script&lang=js&"
export * from "./FieldTable.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e3b7175c",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/var/www/html/game-portal/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('e3b7175c')) {
      api.createRecord('e3b7175c', component.options)
    } else {
      api.reload('e3b7175c', component.options)
    }
    module.hot.accept("./FieldTable.vue?vue&type=template&id=e3b7175c&scoped=true&", function () {
      api.rerender('e3b7175c', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "resources/js/admin/FieldTable.vue"
export default component.exports