var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-row",
        [
          _c(
            "v-col",
            [
              _c(
                "v-btn",
                {
                  staticClass: "mb-6",
                  attrs: { color: "primary" },
                  on: {
                    click: function($event) {
                      _vm.show = true
                    }
                  }
                },
                [_vm._v("Export")]
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _vm.itemsLoaded
        ? _c("v-data-iterator", {
            staticClass: "d-none",
            attrs: {
              search: _vm.search,
              items: _vm.items,
              "items-per-page": -1,
              "sort-by": _vm.tableOptions.sortBy,
              "sort-desc": _vm.tableOptions.sortDesc,
              locale: "de-DE",
              "hide-default-footer": "",
              "footer-props": {
                itemsPerPageText: "Zeilen pro Seite: ",
                itemsPerPageOptions: [-1]
              }
            },
            on: {
              "current-items": function($event) {
                _vm.cloneItems = $event
              }
            }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.itemsLoaded && _vm.groupBy.field
        ? _c("v-data-table", {
            attrs: {
              "group-by": _vm.groupBy.field.table_value,
              "item-key": "uuid",
              search: _vm.search,
              headers: _vm.computedHeaders,
              items: _vm.items,
              "item-class": _vm.setCursor,
              "items-per-page": _vm.tableOptions.itemsPerPage,
              "sort-by": _vm.tableOptions.sortBy,
              "sort-desc": _vm.tableOptions.sortDesc,
              page: _vm.tableOptions.page,
              dense: "",
              locale: "de-DE",
              "hide-default-footer": "",
              loading: _vm.loading,
              "footer-props": {
                itemsPerPageText: "Zeilen pro Seite: ",
                itemsPerPageOptions: [10, 25, 50, 100]
              }
            },
            on: {
              "update:itemsPerPage": function($event) {
                return _vm.$set(_vm.tableOptions, "itemsPerPage", $event)
              },
              "update:items-per-page": function($event) {
                return _vm.$set(_vm.tableOptions, "itemsPerPage", $event)
              },
              "update:sortBy": function($event) {
                return _vm.$set(_vm.tableOptions, "sortBy", $event)
              },
              "update:sort-by": function($event) {
                return _vm.$set(_vm.tableOptions, "sortBy", $event)
              },
              "update:sortDesc": function($event) {
                return _vm.$set(_vm.tableOptions, "sortDesc", $event)
              },
              "update:sort-desc": function($event) {
                return _vm.$set(_vm.tableOptions, "sortDesc", $event)
              },
              "update:page": function($event) {
                return _vm.$set(_vm.tableOptions, "page", $event)
              },
              "page-count": function($event) {
                _vm.pageCount = $event
              },
              "current-items": function($event) {
                return _vm.$emit("current-items", $event)
              }
            },
            scopedSlots: _vm._u(
              [
                _vm._l(_vm.headers, function(header) {
                  return {
                    key: "item." + header.value,
                    fn: function(ref) {
                      var value = ref.value
                      return [
                        header.data_type == "date"
                          ? [
                              _vm._v(
                                "\n        " +
                                  _vm._s(_vm._f("formatDate")(value)) +
                                  "\n      "
                              )
                            ]
                          : header.data_type == "number"
                          ? [
                              _vm._v(
                                "\n        " +
                                  _vm._s(_vm._f("formatNumber")(value)) +
                                  "\n      "
                              )
                            ]
                          : header.data_type == "coordinate"
                          ? [
                              _vm._v(
                                "\n        " +
                                  _vm._s(_vm._f("formatCoordinate")(value)) +
                                  "\n      "
                              )
                            ]
                          : [_vm._v("\n        " + _vm._s(value) + "\n      ")]
                      ]
                    }
                  }
                }),
                {
                  key: "group.header",
                  fn: function(ref) {
                    var group = ref.group
                    var isOpen = ref.isOpen
                    var toggle = ref.toggle
                    var groupBy = ref.groupBy
                    return [
                      _c(
                        "th",
                        { attrs: { colspan: "25" } },
                        [
                          _c("v-icon", { on: { click: toggle } }, [
                            _vm._v(
                              _vm._s(isOpen ? "mdi-minus" : "mdi-plus") +
                                "\n        "
                            )
                          ]),
                          _vm._v(" "),
                          _c(
                            "span",
                            {
                              staticStyle: {
                                "font-weight": "600",
                                "vertical-align": "middle"
                              }
                            },
                            [_vm._v(_vm._s(groupBy[0]) + ": " + _vm._s(group))]
                          )
                        ],
                        1
                      )
                    ]
                  }
                },
                {
                  key: "footer",
                  fn: function(ref) {
                    var ref_props = ref.props
                    var pagination = ref_props.pagination
                    var itemsPerPageText = ref_props.itemsPerPageText
                    var itemsPerPageOptions = ref_props.itemsPerPageOptions
                    return [
                      _c("table-footer", {
                        attrs: {
                          pagination: pagination,
                          itemsPerPageText: itemsPerPageText,
                          itemsPerPageOptions: itemsPerPageOptions,
                          tableOptions: _vm.tableOptions,
                          pageCount: _vm.pageCount
                        }
                      }),
                      _vm._v(" "),
                      _c("v-row", [
                        _c("code", [
                          _vm._v(
                            "\n          " + _vm._s(_vm.sql) + "\n        "
                          )
                        ])
                      ])
                    ]
                  }
                }
              ],
              null,
              true
            )
          })
        : _vm.itemsLoaded && !_vm.groupBy.field
        ? _c("v-data-table", {
            attrs: {
              "item-key": "uuid",
              search: _vm.search,
              headers: _vm.computedHeaders,
              items: _vm.items,
              "item-class": _vm.setCursor,
              "items-per-page": _vm.tableOptions.itemsPerPage,
              "sort-by": _vm.tableOptions.sortBy,
              "sort-desc": _vm.tableOptions.sortDesc,
              page: _vm.tableOptions.page,
              dense: "",
              locale: "de-DE",
              "hide-default-footer": "",
              loading: _vm.loading,
              "footer-props": {
                itemsPerPageText: "Zeilen pro Seite: ",
                itemsPerPageOptions: [10, 25, 50, -1]
              }
            },
            on: {
              "update:itemsPerPage": function($event) {
                return _vm.$set(_vm.tableOptions, "itemsPerPage", $event)
              },
              "update:items-per-page": function($event) {
                return _vm.$set(_vm.tableOptions, "itemsPerPage", $event)
              },
              "update:sortBy": function($event) {
                return _vm.$set(_vm.tableOptions, "sortBy", $event)
              },
              "update:sort-by": function($event) {
                return _vm.$set(_vm.tableOptions, "sortBy", $event)
              },
              "update:sortDesc": function($event) {
                return _vm.$set(_vm.tableOptions, "sortDesc", $event)
              },
              "update:sort-desc": function($event) {
                return _vm.$set(_vm.tableOptions, "sortDesc", $event)
              },
              "update:page": function($event) {
                return _vm.$set(_vm.tableOptions, "page", $event)
              },
              "page-count": function($event) {
                _vm.pageCount = $event
              },
              "current-items": function($event) {
                return _vm.$emit("current-items", $event)
              }
            },
            scopedSlots: _vm._u(
              [
                _vm._l(_vm.headers, function(header) {
                  return {
                    key: "item." + header.value,
                    fn: function(ref) {
                      var value = ref.value
                      return [
                        header.data_type == "date"
                          ? [
                              _vm._v(
                                "\n        " +
                                  _vm._s(_vm._f("formatDate")(value)) +
                                  "\n      "
                              )
                            ]
                          : header.data_type == "number"
                          ? [
                              _vm._v(
                                "\n        " +
                                  _vm._s(_vm._f("formatNumber")(value)) +
                                  "\n      "
                              )
                            ]
                          : [_vm._v("\n        " + _vm._s(value) + "\n      ")]
                      ]
                    }
                  }
                }),
                {
                  key: "group.header",
                  fn: function(ref) {
                    var group = ref.group
                    var isOpen = ref.isOpen
                    var toggle = ref.toggle
                    var groupBy = ref.groupBy
                    return [
                      _c(
                        "th",
                        { attrs: { colspan: "25" } },
                        [
                          _c("v-icon", { on: { click: toggle } }, [
                            _vm._v(
                              _vm._s(isOpen ? "mdi-minus" : "mdi-plus") +
                                "\n        "
                            )
                          ]),
                          _vm._v(" "),
                          _c(
                            "span",
                            {
                              staticStyle: {
                                "font-weight": "600",
                                "vertical-align": "middle"
                              }
                            },
                            [_vm._v(_vm._s(groupBy[0]) + ": " + _vm._s(group))]
                          )
                        ],
                        1
                      )
                    ]
                  }
                },
                {
                  key: "footer",
                  fn: function(ref) {
                    var ref_props = ref.props
                    var pagination = ref_props.pagination
                    var itemsPerPageText = ref_props.itemsPerPageText
                    var itemsPerPageOptions = ref_props.itemsPerPageOptions
                    return [
                      _c("table-footer", {
                        attrs: {
                          pagination: pagination,
                          itemsPerPageText: itemsPerPageText,
                          itemsPerPageOptions: itemsPerPageOptions,
                          tableOptions: _vm.tableOptions,
                          pageCount: _vm.pageCount
                        }
                      }),
                      _vm._v(" "),
                      _c("v-row", [
                        _c("code", [
                          _vm._v(
                            "\n          " + _vm._s(_vm.sql) + "\n        "
                          )
                        ])
                      ])
                    ]
                  }
                }
              ],
              null,
              true
            )
          })
        : _c(
            "div",
            { staticClass: "d-flex justify-center mt-16" },
            [
              _c("v-progress-circular", {
                attrs: { indeterminate: "", size: "60", color: "primary" }
              })
            ],
            1
          ),
      _vm._v(" "),
      _c(
        "v-dialog",
        {
          attrs: { "max-width": "400" },
          model: {
            value: _vm.show,
            callback: function($$v) {
              _vm.show = $$v
            },
            expression: "show"
          }
        },
        [
          _c(
            "v-card",
            [
              _c(
                "v-toolbar",
                { attrs: { color: "info", dark: "", flat: "" } },
                [_c("v-toolbar-title", [_vm._v("Tabelle exportieren")])],
                1
              ),
              _vm._v(" "),
              _c(
                "v-card-text",
                { staticClass: "pt-4 px-4" },
                [
                  _c(
                    "v-form",
                    {
                      ref: "exportSettings",
                      model: {
                        value: _vm.exportValid,
                        callback: function($$v) {
                          _vm.exportValid = $$v
                        },
                        expression: "exportValid"
                      }
                    },
                    [
                      _c("v-text-field", {
                        staticClass: "mb-4",
                        attrs: {
                          rules: _vm.validationRules,
                          filled: "",
                          dense: "",
                          "hide-details": "auto",
                          label: "Dateiname"
                        },
                        model: {
                          value: _vm.fileName,
                          callback: function($$v) {
                            _vm.fileName = $$v
                          },
                          expression: "fileName"
                        }
                      }),
                      _vm._v(" "),
                      _c("v-select", {
                        attrs: {
                          rules: _vm.validationRules,
                          filled: "",
                          dense: "",
                          "hide-details": "auto",
                          items: _vm.dropdownItems,
                          label: "Als Excel oder CSV exportieren?"
                        },
                        model: {
                          value: _vm.exportFormat,
                          callback: function($$v) {
                            _vm.exportFormat = $$v
                          },
                          expression: "exportFormat"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-card-actions",
                [
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "error", "x-small": "" },
                      on: {
                        click: function($event) {
                          $event.stopPropagation()
                          _vm.show = false
                        }
                      }
                    },
                    [_vm._v("Abbrechen")]
                  ),
                  _vm._v(" "),
                  _c("v-spacer"),
                  _vm._v(" "),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "primary", type: "btn", small: "" },
                      on: { click: _vm.exportData }
                    },
                    [_vm._v("Exportieren")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }