var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-btn",
        {
          attrs: { color: "primary" },
          on: {
            click: function($event) {
              _vm.show = true
            }
          }
        },
        [_vm._v("E-Mail-Adresse ändern")]
      ),
      _vm._v(" "),
      _c(
        "v-dialog",
        {
          attrs: { "max-width": "400" },
          model: {
            value: _vm.show,
            callback: function($$v) {
              _vm.show = $$v
            },
            expression: "show"
          }
        },
        [
          _c(
            "v-card",
            [
              _c(
                "v-toolbar",
                { attrs: { color: "primary", dark: "", flat: "" } },
                [_c("v-toolbar-title", [_vm._v("E-Mail-Adresse ändern")])],
                1
              ),
              _vm._v(" "),
              _c(
                "v-form",
                {
                  on: {
                    submit: function($event) {
                      $event.preventDefault()
                      return _vm.sendEmailVerificationLink.apply(
                        null,
                        arguments
                      )
                    }
                  }
                },
                [
                  _c(
                    "v-card-text",
                    { staticClass: "pt-4 px-4" },
                    [
                      _c("v-text-field", {
                        attrs: {
                          filled: "",
                          dense: "",
                          "hide-details": "auto",
                          label: "Neue E-Mail-Adresse",
                          "error-messages": _vm.errors
                        },
                        model: {
                          value: _vm.newEmail,
                          callback: function($$v) {
                            _vm.newEmail = $$v
                          },
                          expression: "newEmail"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-card-actions",
                    [
                      _c(
                        "v-btn",
                        {
                          attrs: { color: "error", "x-small": "" },
                          on: {
                            click: function($event) {
                              $event.stopPropagation()
                              _vm.show = false
                            }
                          }
                        },
                        [_vm._v("abbrechen")]
                      ),
                      _vm._v(" "),
                      _c("v-spacer"),
                      _vm._v(" "),
                      _c(
                        "v-btn",
                        {
                          attrs: { color: "primary", type: "submit", small: "" }
                        },
                        [_vm._v("E-Mail-Adresse ändern")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }