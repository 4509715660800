var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-form",
    {
      attrs: { method: "POST" },
      on: {
        submit: function($event) {
          $event.preventDefault()
          return _vm.sendPasswordResetLink.apply(null, arguments)
        }
      }
    },
    [
      _c("v-btn", { attrs: { color: "primary", type: "submit", block: "" } }, [
        _vm._v("Passwort zurücksetzen")
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }