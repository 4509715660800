var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-form",
    {
      ref: "newsForm",
      on: {
        submit: function($event) {
          $event.preventDefault()
          return _vm.saveNewsArticle.apply(null, arguments)
        }
      },
      model: {
        value: _vm.valid,
        callback: function($$v) {
          _vm.valid = $$v
        },
        expression: "valid"
      }
    },
    [
      _c(
        "v-row",
        [
          _c(
            "v-col",
            [
              _c("v-text-field", {
                attrs: {
                  filled: "",
                  dense: "",
                  label: "Titel",
                  rules: _vm.inputRules,
                  "hide-details": "auto"
                },
                model: {
                  value: _vm.title,
                  callback: function($$v) {
                    _vm.title = $$v
                  },
                  expression: "title"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-col",
            [
              _c("v-text-field", {
                attrs: {
                  filled: "",
                  dense: "",
                  label: "News-URL",
                  "hide-details": "auto",
                  loading: _vm.slugLoading,
                  "error-messages": _vm.slugError
                },
                model: {
                  value: _vm.seoSlug,
                  callback: function($$v) {
                    _vm.seoSlug = $$v
                  },
                  expression: "seoSlug"
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-row",
        [
          _c(
            "v-col",
            [
              _c("v-text-field", {
                attrs: {
                  filled: "",
                  dense: "",
                  label: "Einleitungs-Text",
                  rules: _vm.inputRules,
                  "hide-details": "auto"
                },
                model: {
                  value: _vm.excerpt,
                  callback: function($$v) {
                    _vm.excerpt = $$v
                  },
                  expression: "excerpt"
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-row",
        [
          _c(
            "v-col",
            [
              _c("v-file-input", {
                attrs: {
                  accept: "image/png, image/jpeg, image/webp",
                  rules: _vm.fileRules,
                  "show-size": "",
                  dense: "",
                  filled: "",
                  label: "Bild",
                  "hide-details": "auto",
                  "prepend-inner-icon": "mdi-camera",
                  "prepend-icon": ""
                },
                model: {
                  value: _vm.featuredImage,
                  callback: function($$v) {
                    _vm.featuredImage = $$v
                  },
                  expression: "featuredImage"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-col",
            [
              _c(
                "v-menu",
                {
                  ref: "menu",
                  attrs: {
                    "close-on-content-click": false,
                    transition: "scale-transition",
                    "offset-y": "",
                    "min-width": "auto"
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "activator",
                      fn: function(ref) {
                        var on = ref.on
                        var attrs = ref.attrs
                        return [
                          _c(
                            "v-text-field",
                            _vm._g(
                              _vm._b(
                                {
                                  attrs: {
                                    rules: _vm.inputRules,
                                    label: "Veröffentlichungsdatum",
                                    "prepend-inner-icon": "mdi-calendar",
                                    readonly: "",
                                    filled: "",
                                    dense: "",
                                    "hide-details": "auto"
                                  },
                                  model: {
                                    value: _vm.publishDateTime,
                                    callback: function($$v) {
                                      _vm.publishDateTime = $$v
                                    },
                                    expression: "publishDateTime"
                                  }
                                },
                                "v-text-field",
                                attrs,
                                false
                              ),
                              on
                            )
                          )
                        ]
                      }
                    }
                  ]),
                  model: {
                    value: _vm.menu,
                    callback: function($$v) {
                      _vm.menu = $$v
                    },
                    expression: "menu"
                  }
                },
                [
                  _vm._v(" "),
                  _c(
                    "v-tabs",
                    {
                      attrs: {
                        "background-color": "primary",
                        grow: "",
                        centered: "",
                        dark: "",
                        "icons-and-text": ""
                      },
                      model: {
                        value: _vm.tab,
                        callback: function($$v) {
                          _vm.tab = $$v
                        },
                        expression: "tab"
                      }
                    },
                    [
                      _c("v-tabs-slider"),
                      _vm._v(" "),
                      _c(
                        "v-tab",
                        { attrs: { href: "#date-tab" } },
                        [
                          _vm._v("\n            Datum\n            "),
                          _c("v-icon", [_vm._v("mdi-calendar")])
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-tab",
                        { attrs: { href: "#clock-tab", target: "" } },
                        [
                          _vm._v("\n            Uhrzeit\n            "),
                          _c("v-icon", [_vm._v("mdi-clock")])
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-tabs-items",
                    {
                      model: {
                        value: _vm.tab,
                        callback: function($$v) {
                          _vm.tab = $$v
                        },
                        expression: "tab"
                      }
                    },
                    [
                      _c(
                        "v-tab-item",
                        { attrs: { value: "date-tab" } },
                        [
                          _c(
                            "v-card",
                            { attrs: { flat: "" } },
                            [
                              _c(
                                "v-card-text",
                                [
                                  _c("v-date-picker", {
                                    attrs: {
                                      color: "secondary",
                                      "first-day-of-week": "1",
                                      landscape: "",
                                      locale: "de-DE"
                                    },
                                    on: {
                                      input: function($event) {
                                        _vm.tab = "clock-tab"
                                      }
                                    },
                                    model: {
                                      value: _vm.publishDate,
                                      callback: function($$v) {
                                        _vm.publishDate = $$v
                                      },
                                      expression: "publishDate"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-tab-item",
                        { attrs: { value: "clock-tab" } },
                        [
                          _c(
                            "v-card",
                            { attrs: { flat: "" } },
                            [
                              _c(
                                "v-card-text",
                                [
                                  _c("v-time-picker", {
                                    attrs: {
                                      color: "secondary",
                                      format: "24hr",
                                      landscape: ""
                                    },
                                    on: {
                                      "click:minute": function($event) {
                                        _vm.menu = false
                                      }
                                    },
                                    model: {
                                      value: _vm.publishTime,
                                      callback: function($$v) {
                                        _vm.publishTime = $$v
                                      },
                                      expression: "publishTime"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-row",
        [
          _c("v-col", [
            _c("h2", { staticClass: "primary--text" }, [_vm._v("Nachricht")])
          ])
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-row",
        [
          _c(
            "v-col",
            [
              _c("ckeditor", {
                attrs: { editor: _vm.editor, config: _vm.editorConfig },
                model: {
                  value: _vm.editorData,
                  callback: function($$v) {
                    _vm.editorData = $$v
                  },
                  expression: "editorData"
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-row",
        [
          _c(
            "v-col",
            [
              _c("v-autocomplete", {
                attrs: {
                  dense: "",
                  filled: "",
                  clearable: "",
                  multiple: "",
                  "hide-details": "auto",
                  label: "Rollen auswählen:",
                  name: "roles",
                  items: _vm.roles,
                  "item-value": "name",
                  "item-text": "name"
                },
                model: {
                  value: _vm.selectedRoles,
                  callback: function($$v) {
                    _vm.selectedRoles = $$v
                  },
                  expression: "selectedRoles"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-col",
            [
              _c("v-autocomplete", {
                attrs: {
                  dense: "",
                  filled: "",
                  clearable: "",
                  multiple: "",
                  "hide-details": "auto",
                  label: "Benutzer auswählen:",
                  name: "users",
                  items: _vm.filteredUsers,
                  "item-value": "id",
                  "item-text": "name"
                },
                model: {
                  value: _vm.selectedUsers,
                  callback: function($$v) {
                    _vm.selectedUsers = $$v
                  },
                  expression: "selectedUsers"
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { attrs: { cols: "12", lg: "6" } },
            [
              _c("v-file-input", {
                attrs: {
                  rules: _vm.additionalFileRules,
                  multiple: "",
                  "show-size": "",
                  dense: "",
                  filled: "",
                  label: "Anhänge",
                  "hide-details": "auto",
                  "prepend-inner-icon": "mdi-file",
                  "prepend-icon": ""
                },
                model: {
                  value: _vm.additionalFiles,
                  callback: function($$v) {
                    _vm.additionalFiles = $$v
                  },
                  expression: "additionalFiles"
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-row",
        [
          _c("v-spacer"),
          _vm._v(" "),
          _c(
            "v-btn",
            {
              staticClass: "mt-4 mr-3 mb-4",
              attrs: { color: "primary", type: "submit" }
            },
            [_vm._v("Nachricht speichern & veröffentlichen")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }