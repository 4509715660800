var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-snackbar",
    {
      staticClass: "pt-0",
      attrs: { top: "", color: "info", timeout: "-1" },
      scopedSlots: _vm._u([
        {
          key: "action",
          fn: function() {
            return [
              _c(
                "v-btn",
                {
                  staticClass: "mt-1",
                  attrs: { icon: "" },
                  on: {
                    click: function($event) {
                      _vm.show = false
                    }
                  }
                },
                [_c("v-icon", [_vm._v("mdi-close")])],
                1
              )
            ]
          },
          proxy: true
        }
      ]),
      model: {
        value: _vm.show,
        callback: function($$v) {
          _vm.show = $$v
        },
        expression: "show"
      }
    },
    [
      _c(
        "v-row",
        { staticClass: "ma-0", attrs: { align: "center" } },
        [
          _c("v-progress-circular", {
            staticClass: "mr-4",
            attrs: { size: "24", indeterminate: "", color: "white" }
          }),
          _vm._v(" "),
          _c("div", [_vm._v("Daten werden exportiert...")])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }