var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-form",
    {
      ref: "roleForm",
      attrs: { action: _vm.route("admin.roles.save") },
      on: {
        submit: function($event) {
          $event.preventDefault()
          return _vm.saveRole()
        }
      },
      model: {
        value: _vm.valid,
        callback: function($$v) {
          _vm.valid = $$v
        },
        expression: "valid"
      }
    },
    [
      _c("h2", { staticClass: "primary--text :hover" }, [
        _vm._v("Rollendaten")
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "profile-grid" }, [
        _c("div", { staticClass: "profile-item my-1" }, [
          _c("div", { staticClass: "profile-title pa-2" }, [
            _vm._v("Rollenname")
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "profile-divider" }),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "profile-value py-2" },
            [
              _c("v-text-field", {
                attrs: {
                  filled: "",
                  dense: "",
                  "hide-details": "auto",
                  rules: _vm.inputRules
                },
                model: {
                  value: _vm.role.name,
                  callback: function($$v) {
                    _vm.$set(_vm.role, "name", $$v)
                  },
                  expression: "role.name"
                }
              })
            ],
            1
          )
        ])
      ]),
      _vm._v(" "),
      _c(
        "v-row",
        { staticClass: "mb-8" },
        [
          _c(
            "v-col",
            { attrs: { cols: "12", md: "6" } },
            [
              _c("h3", { staticClass: "primary--text mb-4" }, [
                _vm._v("alle Bedingungen treffen zu:")
              ]),
              _vm._v(" "),
              _vm.conditionAll.length == 0
                ? _c(
                    "v-row",
                    {
                      staticClass: "py-4",
                      attrs: { justify: "center", align: "center" }
                    },
                    [
                      _c(
                        "v-btn",
                        {
                          staticClass: "mr-2",
                          attrs: {
                            "x-small": "",
                            color: "primary",
                            "min-width": "20px",
                            "max-width": "20px"
                          },
                          on: {
                            click: function($event) {
                              return _vm.addCondition(0, "all")
                            }
                          }
                        },
                        [_c("v-icon", [_vm._v("mdi-plus")])],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-btn",
                        {
                          attrs: {
                            "x-small": "",
                            color: "error",
                            "min-width": "20px",
                            "max-width": "20px"
                          },
                          on: {
                            click: function($event) {
                              return _vm.removeCondition(0, "all")
                            }
                          }
                        },
                        [_c("v-icon", [_vm._v("mdi-close")])],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm._l(_vm.conditionAll, function(condition, index) {
                return _c(
                  "v-row",
                  { key: index },
                  [
                    _c(
                      "v-col",
                      [
                        _c("v-autocomplete", {
                          attrs: {
                            dense: "",
                            filled: "",
                            clearable: "",
                            "hide-details": "auto",
                            rules: _vm.inputRules,
                            items: _vm.availableFields,
                            "item-value": "id",
                            "item-text": function(item) {
                              return (
                                item.portal_field_category.name +
                                ": " +
                                item.field_name
                              )
                            },
                            required: ""
                          },
                          on: {
                            change: function($event) {
                              return _vm.setDataType(condition)
                            }
                          },
                          model: {
                            value: condition.field,
                            callback: function($$v) {
                              _vm.$set(condition, "field", $$v)
                            },
                            expression: "condition.field"
                          }
                        })
                      ],
                      1
                    ),
                    _vm._v(" "),
                    condition.data_type !== "text"
                      ? _c(
                          "v-col",
                          { attrs: { cols: "2" } },
                          [
                            _c("v-select", {
                              staticClass: "condition-select mt-0",
                              attrs: {
                                "hide-details": "auto",
                                dense: "",
                                height: "100%",
                                "full-width": "",
                                value: "e",
                                "menu-props": {
                                  "content-class": "condition-select-menu",
                                  bottom: true,
                                  "offset-y": true
                                },
                                "hide-selected": "",
                                items: _vm.operators.number
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "selection",
                                    fn: function(ref) {
                                      var item = ref.item
                                      return [
                                        item.hasOwnProperty("icon")
                                          ? _c(
                                              "v-icon",
                                              { attrs: { color: "white" } },
                                              [_vm._v(_vm._s(item.icon))]
                                            )
                                          : _c(
                                              "span",
                                              {
                                                staticClass:
                                                  "white--text body-2 text-center"
                                              },
                                              [_vm._v(_vm._s(item.text))]
                                            )
                                      ]
                                    }
                                  },
                                  {
                                    key: "item",
                                    fn: function(ref) {
                                      var item = ref.item
                                      return [
                                        item.hasOwnProperty("icon")
                                          ? _c("v-icon", [
                                              _vm._v(_vm._s(item.icon))
                                            ])
                                          : _c(
                                              "span",
                                              { staticClass: "body-2" },
                                              [_vm._v(_vm._s(item.text))]
                                            )
                                      ]
                                    }
                                  }
                                ],
                                null,
                                true
                              ),
                              model: {
                                value: condition.operator,
                                callback: function($$v) {
                                  _vm.$set(condition, "operator", $$v)
                                },
                                expression: "condition.operator"
                              }
                            })
                          ],
                          1
                        )
                      : _c(
                          "v-col",
                          { attrs: { cols: "2" } },
                          [
                            _c("v-select", {
                              staticClass: "condition-select mt-0",
                              attrs: {
                                "hide-details": "auto",
                                dense: "",
                                height: "100%",
                                "full-width": "",
                                value: "e",
                                "menu-props": {
                                  "content-class": "condition-select-menu",
                                  bottom: true,
                                  "offset-y": true
                                },
                                "hide-selected": "",
                                items: _vm.operators.text
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "selection",
                                    fn: function(ref) {
                                      var item = ref.item
                                      return [
                                        _c(
                                          "span",
                                          {
                                            staticClass:
                                              "white--text body-2 text-center"
                                          },
                                          [_vm._v(_vm._s(item.text))]
                                        )
                                      ]
                                    }
                                  },
                                  {
                                    key: "item",
                                    fn: function(ref) {
                                      var item = ref.item
                                      return [
                                        _c("span", { staticClass: "body-2" }, [
                                          _vm._v(_vm._s(item.text))
                                        ])
                                      ]
                                    }
                                  },
                                  {
                                    key: "append-outer",
                                    fn: function() {
                                      return [
                                        _c(
                                          "v-tooltip",
                                          {
                                            attrs: { bottom: "" },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "activator",
                                                  fn: function(ref) {
                                                    var on = ref.on
                                                    var attrs = ref.attrs
                                                    return [
                                                      _c(
                                                        "v-btn",
                                                        _vm._g(
                                                          _vm._b(
                                                            {
                                                              staticClass:
                                                                "mt-n1",
                                                              attrs: {
                                                                small: "",
                                                                color: "info",
                                                                icon: ""
                                                              }
                                                            },
                                                            "v-btn",
                                                            attrs,
                                                            false
                                                          ),
                                                          on
                                                        ),
                                                        [
                                                          _c("v-icon", [
                                                            _vm._v(
                                                              " mdi-help-circle-outline "
                                                            )
                                                          ])
                                                        ],
                                                        1
                                                      )
                                                    ]
                                                  }
                                                }
                                              ],
                                              null,
                                              true
                                            )
                                          },
                                          [
                                            _vm._v(" "),
                                            _c("span", [
                                              _vm._v(
                                                "% als Platzhalter verwenden"
                                              )
                                            ])
                                          ]
                                        )
                                      ]
                                    },
                                    proxy: true
                                  }
                                ],
                                null,
                                true
                              ),
                              model: {
                                value: condition.operator,
                                callback: function($$v) {
                                  _vm.$set(condition, "operator", $$v)
                                },
                                expression: "condition.operator"
                              }
                            })
                          ],
                          1
                        ),
                    _vm._v(" "),
                    [
                      condition.data_type == "date"
                        ? _c(
                            "v-col",
                            [
                              _c(
                                "v-menu",
                                {
                                  ref: "menu",
                                  refInFor: true,
                                  attrs: {
                                    "close-on-content-click": false,
                                    transition: "scale-transition",
                                    "offset-y": "",
                                    "min-width": "auto"
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "activator",
                                        fn: function(ref) {
                                          var on = ref.on
                                          var attrs = ref.attrs
                                          return [
                                            _c(
                                              "v-text-field",
                                              _vm._g(
                                                _vm._b(
                                                  {
                                                    attrs: {
                                                      "prepend-inner-icon":
                                                        "mdi-calendar",
                                                      readonly: "",
                                                      filled: "",
                                                      dense: "",
                                                      "hide-details": "auto",
                                                      disabled:
                                                        condition.operator ===
                                                          "null" ||
                                                        condition.operator ===
                                                          "not_null",
                                                      value: _vm.$filters.formatDate(
                                                        condition.value
                                                      )
                                                    },
                                                    scopedSlots: _vm._u(
                                                      [
                                                        {
                                                          key: "append-outer",
                                                          fn: function() {
                                                            return [
                                                              _c(
                                                                "v-btn",
                                                                {
                                                                  staticClass:
                                                                    "mr-2",
                                                                  attrs: {
                                                                    "x-small":
                                                                      "",
                                                                    color:
                                                                      "primary",
                                                                    "min-width":
                                                                      "20px",
                                                                    "max-width":
                                                                      "20px"
                                                                  },
                                                                  on: {
                                                                    click: function(
                                                                      $event
                                                                    ) {
                                                                      return _vm.addCondition(
                                                                        index,
                                                                        "all"
                                                                      )
                                                                    }
                                                                  }
                                                                },
                                                                [
                                                                  _c("v-icon", [
                                                                    _vm._v(
                                                                      "mdi-plus"
                                                                    )
                                                                  ])
                                                                ],
                                                                1
                                                              ),
                                                              _vm._v(" "),
                                                              _c(
                                                                "v-btn",
                                                                {
                                                                  attrs: {
                                                                    "x-small":
                                                                      "",
                                                                    color:
                                                                      "error",
                                                                    "min-width":
                                                                      "20px",
                                                                    "max-width":
                                                                      "20px"
                                                                  },
                                                                  on: {
                                                                    click: function(
                                                                      $event
                                                                    ) {
                                                                      return _vm.removeCondition(
                                                                        index,
                                                                        "all"
                                                                      )
                                                                    }
                                                                  }
                                                                },
                                                                [
                                                                  _c("v-icon", [
                                                                    _vm._v(
                                                                      "mdi-close"
                                                                    )
                                                                  ])
                                                                ],
                                                                1
                                                              )
                                                            ]
                                                          },
                                                          proxy: true
                                                        }
                                                      ],
                                                      null,
                                                      true
                                                    )
                                                  },
                                                  "v-text-field",
                                                  attrs,
                                                  false
                                                ),
                                                on
                                              )
                                            )
                                          ]
                                        }
                                      }
                                    ],
                                    null,
                                    true
                                  ),
                                  model: {
                                    value: condition.menu,
                                    callback: function($$v) {
                                      _vm.$set(condition, "menu", $$v)
                                    },
                                    expression: "condition.menu"
                                  }
                                },
                                [
                                  _vm._v(" "),
                                  _c(
                                    "v-card",
                                    { attrs: { flat: "" } },
                                    [
                                      _c(
                                        "v-card-text",
                                        [
                                          _c("v-date-picker", {
                                            attrs: {
                                              color: "secondary",
                                              "first-day-of-week": "1",
                                              landscape: "",
                                              locale: "de-DE"
                                            },
                                            model: {
                                              value: condition.value,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  condition,
                                                  "value",
                                                  $$v
                                                )
                                              },
                                              expression: "condition.value"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        : _c(
                            "v-col",
                            [
                              _c("v-text-field", {
                                attrs: {
                                  dense: "",
                                  filled: "",
                                  "hide-details": "auto",
                                  disabled:
                                    condition.operator === "null" ||
                                    condition.operator === "not_null",
                                  required: ""
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "append-outer",
                                      fn: function() {
                                        return [
                                          _c(
                                            "v-btn",
                                            {
                                              staticClass: "mr-2",
                                              attrs: {
                                                "x-small": "",
                                                color: "primary",
                                                "min-width": "20px",
                                                "max-width": "20px"
                                              },
                                              on: {
                                                click: function($event) {
                                                  return _vm.addCondition(
                                                    index,
                                                    "all"
                                                  )
                                                }
                                              }
                                            },
                                            [
                                              _c("v-icon", [_vm._v("mdi-plus")])
                                            ],
                                            1
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "v-btn",
                                            {
                                              attrs: {
                                                "x-small": "",
                                                color: "error",
                                                "min-width": "20px",
                                                "max-width": "20px"
                                              },
                                              on: {
                                                click: function($event) {
                                                  return _vm.removeCondition(
                                                    index,
                                                    "all"
                                                  )
                                                }
                                              }
                                            },
                                            [
                                              _c("v-icon", [
                                                _vm._v("mdi-close")
                                              ])
                                            ],
                                            1
                                          )
                                        ]
                                      },
                                      proxy: true
                                    }
                                  ],
                                  null,
                                  true
                                ),
                                model: {
                                  value: condition.value,
                                  callback: function($$v) {
                                    _vm.$set(condition, "value", $$v)
                                  },
                                  expression: "condition.value"
                                }
                              })
                            ],
                            1
                          )
                    ]
                  ],
                  2
                )
              })
            ],
            2
          ),
          _vm._v(" "),
          _c(
            "v-col",
            { attrs: { cols: "12", md: "6" } },
            [
              _c("h3", { staticClass: "primary--text mb-4" }, [
                _vm._v("mindestens eine Bedingung trifft zu:")
              ]),
              _vm._v(" "),
              _vm.conditionOne.length == 0
                ? _c(
                    "v-row",
                    {
                      staticClass: "py-4",
                      attrs: { justify: "center", align: "center" }
                    },
                    [
                      _c(
                        "v-btn",
                        {
                          staticClass: "mr-2",
                          attrs: {
                            "x-small": "",
                            color: "primary",
                            "min-width": "20px",
                            "max-width": "20px"
                          },
                          on: {
                            click: function($event) {
                              return _vm.addCondition(0, "one")
                            }
                          }
                        },
                        [_c("v-icon", [_vm._v("mdi-plus")])],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-btn",
                        {
                          attrs: {
                            "x-small": "",
                            color: "error",
                            "min-width": "20px",
                            "max-width": "20px"
                          },
                          on: {
                            click: function($event) {
                              return _vm.removeCondition(0, "one")
                            }
                          }
                        },
                        [_c("v-icon", [_vm._v("mdi-close")])],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm._l(_vm.conditionOne, function(condition, index) {
                return _c(
                  "v-row",
                  { key: index },
                  [
                    _c(
                      "v-col",
                      [
                        _c("v-autocomplete", {
                          attrs: {
                            dense: "",
                            filled: "",
                            clearable: "",
                            "hide-details": "auto",
                            rules: _vm.inputRules,
                            items: _vm.availableFields,
                            "item-value": "id",
                            "item-text": function(item) {
                              return (
                                item.portal_field_category.name +
                                ": " +
                                item.field_name
                              )
                            },
                            required: ""
                          },
                          on: {
                            change: function($event) {
                              return _vm.setDataType(condition)
                            }
                          },
                          model: {
                            value: condition.field,
                            callback: function($$v) {
                              _vm.$set(condition, "field", $$v)
                            },
                            expression: "condition.field"
                          }
                        })
                      ],
                      1
                    ),
                    _vm._v(" "),
                    condition.data_type !== "text"
                      ? _c(
                          "v-col",
                          { attrs: { cols: "2" } },
                          [
                            _c("v-select", {
                              staticClass: "condition-select mt-0",
                              attrs: {
                                "hide-details": "auto",
                                dense: "",
                                height: "100%",
                                "full-width": "",
                                value: "e",
                                "menu-props": {
                                  "content-class": "condition-select-menu",
                                  bottom: true,
                                  "offset-y": true
                                },
                                "hide-selected": "",
                                items: _vm.operators.number
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "selection",
                                    fn: function(ref) {
                                      var item = ref.item
                                      return [
                                        item.hasOwnProperty("icon")
                                          ? _c(
                                              "v-icon",
                                              { attrs: { color: "white" } },
                                              [_vm._v(_vm._s(item.icon))]
                                            )
                                          : _c(
                                              "span",
                                              {
                                                staticClass:
                                                  "white--text body-2 text-center"
                                              },
                                              [_vm._v(_vm._s(item.text))]
                                            )
                                      ]
                                    }
                                  },
                                  {
                                    key: "item",
                                    fn: function(ref) {
                                      var item = ref.item
                                      return [
                                        item.hasOwnProperty("icon")
                                          ? _c("v-icon", [
                                              _vm._v(_vm._s(item.icon))
                                            ])
                                          : _c(
                                              "span",
                                              { staticClass: "body-2" },
                                              [_vm._v(_vm._s(item.text))]
                                            )
                                      ]
                                    }
                                  }
                                ],
                                null,
                                true
                              ),
                              model: {
                                value: condition.operator,
                                callback: function($$v) {
                                  _vm.$set(condition, "operator", $$v)
                                },
                                expression: "condition.operator"
                              }
                            })
                          ],
                          1
                        )
                      : _c(
                          "v-col",
                          { attrs: { cols: "2" } },
                          [
                            _c("v-select", {
                              staticClass: "condition-select mt-0",
                              attrs: {
                                "hide-details": "auto",
                                dense: "",
                                height: "100%",
                                "full-width": "",
                                value: "e",
                                "menu-props": {
                                  "content-class": "condition-select-menu",
                                  bottom: true,
                                  "offset-y": true
                                },
                                "hide-selected": "",
                                items: _vm.operators.text
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "selection",
                                    fn: function(ref) {
                                      var item = ref.item
                                      return [
                                        _c(
                                          "span",
                                          {
                                            staticClass:
                                              "white--text body-2 text-center"
                                          },
                                          [_vm._v(_vm._s(item.text))]
                                        )
                                      ]
                                    }
                                  },
                                  {
                                    key: "item",
                                    fn: function(ref) {
                                      var item = ref.item
                                      return [
                                        _c("span", { staticClass: "body-2" }, [
                                          _vm._v(_vm._s(item.text))
                                        ])
                                      ]
                                    }
                                  },
                                  {
                                    key: "append-outer",
                                    fn: function() {
                                      return [
                                        _c(
                                          "v-tooltip",
                                          {
                                            attrs: { bottom: "" },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "activator",
                                                  fn: function(ref) {
                                                    var on = ref.on
                                                    var attrs = ref.attrs
                                                    return [
                                                      _c(
                                                        "v-btn",
                                                        _vm._g(
                                                          _vm._b(
                                                            {
                                                              staticClass:
                                                                "mt-n1",
                                                              attrs: {
                                                                small: "",
                                                                color: "info",
                                                                icon: ""
                                                              }
                                                            },
                                                            "v-btn",
                                                            attrs,
                                                            false
                                                          ),
                                                          on
                                                        ),
                                                        [
                                                          _c("v-icon", [
                                                            _vm._v(
                                                              " mdi-help-circle-outline "
                                                            )
                                                          ])
                                                        ],
                                                        1
                                                      )
                                                    ]
                                                  }
                                                }
                                              ],
                                              null,
                                              true
                                            )
                                          },
                                          [
                                            _vm._v(" "),
                                            _c("span", [
                                              _vm._v(
                                                "% als Platzhalter verwenden"
                                              )
                                            ])
                                          ]
                                        )
                                      ]
                                    },
                                    proxy: true
                                  }
                                ],
                                null,
                                true
                              ),
                              model: {
                                value: condition.operator,
                                callback: function($$v) {
                                  _vm.$set(condition, "operator", $$v)
                                },
                                expression: "condition.operator"
                              }
                            })
                          ],
                          1
                        ),
                    _vm._v(" "),
                    [
                      condition.data_type == "date"
                        ? _c(
                            "v-col",
                            [
                              _c(
                                "v-menu",
                                {
                                  ref: "menu",
                                  refInFor: true,
                                  attrs: {
                                    "close-on-content-click": false,
                                    transition: "scale-transition",
                                    "offset-y": "",
                                    "min-width": "auto"
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "activator",
                                        fn: function(ref) {
                                          var on = ref.on
                                          var attrs = ref.attrs
                                          return [
                                            _c(
                                              "v-text-field",
                                              _vm._g(
                                                _vm._b(
                                                  {
                                                    attrs: {
                                                      "prepend-inner-icon":
                                                        "mdi-calendar",
                                                      readonly: "",
                                                      filled: "",
                                                      dense: "",
                                                      disabled:
                                                        condition.operator ===
                                                          "null" ||
                                                        condition.operator ===
                                                          "not_null",
                                                      "hide-details": "auto",
                                                      value: _vm.$filters.formatDate(
                                                        condition.value
                                                      )
                                                    },
                                                    scopedSlots: _vm._u(
                                                      [
                                                        {
                                                          key: "append-outer",
                                                          fn: function() {
                                                            return [
                                                              _c(
                                                                "v-btn",
                                                                {
                                                                  staticClass:
                                                                    "mr-2",
                                                                  attrs: {
                                                                    "x-small":
                                                                      "",
                                                                    color:
                                                                      "primary",
                                                                    "min-width":
                                                                      "20px",
                                                                    "max-width":
                                                                      "20px"
                                                                  },
                                                                  on: {
                                                                    click: function(
                                                                      $event
                                                                    ) {
                                                                      return _vm.addCondition(
                                                                        index,
                                                                        "one"
                                                                      )
                                                                    }
                                                                  }
                                                                },
                                                                [
                                                                  _c("v-icon", [
                                                                    _vm._v(
                                                                      "mdi-plus"
                                                                    )
                                                                  ])
                                                                ],
                                                                1
                                                              ),
                                                              _vm._v(" "),
                                                              _c(
                                                                "v-btn",
                                                                {
                                                                  attrs: {
                                                                    "x-small":
                                                                      "",
                                                                    color:
                                                                      "error",
                                                                    "min-width":
                                                                      "20px",
                                                                    "max-width":
                                                                      "20px"
                                                                  },
                                                                  on: {
                                                                    click: function(
                                                                      $event
                                                                    ) {
                                                                      return _vm.removeCondition(
                                                                        index,
                                                                        "one"
                                                                      )
                                                                    }
                                                                  }
                                                                },
                                                                [
                                                                  _c("v-icon", [
                                                                    _vm._v(
                                                                      "mdi-close"
                                                                    )
                                                                  ])
                                                                ],
                                                                1
                                                              )
                                                            ]
                                                          },
                                                          proxy: true
                                                        }
                                                      ],
                                                      null,
                                                      true
                                                    )
                                                  },
                                                  "v-text-field",
                                                  attrs,
                                                  false
                                                ),
                                                on
                                              )
                                            )
                                          ]
                                        }
                                      }
                                    ],
                                    null,
                                    true
                                  ),
                                  model: {
                                    value: condition.menu,
                                    callback: function($$v) {
                                      _vm.$set(condition, "menu", $$v)
                                    },
                                    expression: "condition.menu"
                                  }
                                },
                                [
                                  _vm._v(" "),
                                  _c(
                                    "v-card",
                                    { attrs: { flat: "" } },
                                    [
                                      _c(
                                        "v-card-text",
                                        [
                                          _c("v-date-picker", {
                                            attrs: {
                                              color: "secondary",
                                              "first-day-of-week": "1",
                                              landscape: "",
                                              locale: "de-DE"
                                            },
                                            model: {
                                              value: condition.value,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  condition,
                                                  "value",
                                                  $$v
                                                )
                                              },
                                              expression: "condition.value"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        : _c(
                            "v-col",
                            [
                              _c("v-text-field", {
                                attrs: {
                                  dense: "",
                                  filled: "",
                                  "hide-details": "auto",
                                  disabled:
                                    condition.operator === "null" ||
                                    condition.operator === "not_null",
                                  required: ""
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "append-outer",
                                      fn: function() {
                                        return [
                                          _c(
                                            "v-btn",
                                            {
                                              staticClass: "mr-2",
                                              attrs: {
                                                "x-small": "",
                                                color: "primary",
                                                "min-width": "20px",
                                                "max-width": "20px"
                                              },
                                              on: {
                                                click: function($event) {
                                                  return _vm.addCondition(
                                                    index,
                                                    "one"
                                                  )
                                                }
                                              }
                                            },
                                            [
                                              _c("v-icon", [_vm._v("mdi-plus")])
                                            ],
                                            1
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "v-btn",
                                            {
                                              attrs: {
                                                "x-small": "",
                                                color: "error",
                                                "min-width": "20px",
                                                "max-width": "20px"
                                              },
                                              on: {
                                                click: function($event) {
                                                  return _vm.removeCondition(
                                                    index,
                                                    "one"
                                                  )
                                                }
                                              }
                                            },
                                            [
                                              _c("v-icon", [
                                                _vm._v("mdi-close")
                                              ])
                                            ],
                                            1
                                          )
                                        ]
                                      },
                                      proxy: true
                                    }
                                  ],
                                  null,
                                  true
                                ),
                                model: {
                                  value: condition.value,
                                  callback: function($$v) {
                                    _vm.$set(condition, "value", $$v)
                                  },
                                  expression: "condition.value"
                                }
                              })
                            ],
                            1
                          )
                    ]
                  ],
                  2
                )
              })
            ],
            2
          )
        ],
        1
      ),
      _vm._v(" "),
      _c("h2", { staticClass: "primary--text mb-6" }, [
        _vm._v("Berechtigungen")
      ]),
      _vm._v(" "),
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { attrs: { cols: "12", lg: "6" } },
            [
              _c("v-switch", {
                attrs: {
                  label:
                    "Kann Karte von Tabelle erstellen? " +
                    (_vm.canViewMap ? "Ja" : "Nein")
                },
                model: {
                  value: _vm.canViewMap,
                  callback: function($$v) {
                    _vm.canViewMap = $$v
                  },
                  expression: "canViewMap"
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { attrs: { cols: "12", lg: "6" } },
            [
              _c("h3", { staticClass: "primary--text mb-5" }, [
                _vm._v("Feld-Berechtigungen")
              ]),
              _vm._v(" "),
              _vm.fieldPermissions.length > 0
                ? _c("roles-field-draggable", {
                    attrs: {
                      items: _vm.fieldPermissions,
                      "items-selected": _vm.selectedFieldPermissions
                    },
                    on: {
                      change: function($event) {
                        _vm.selectedFieldPermissions = $event
                      }
                    }
                  })
                : _vm._e()
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-col",
            { attrs: { cols: "12", lg: "6" } },
            [
              _c("h3", { staticClass: "primary--text mb-5" }, [
                _vm._v("Modul-Berechtigungen")
              ]),
              _vm._v(" "),
              _vm.modulePermissions.length > 0
                ? _c("roles-module-draggable", {
                    attrs: {
                      items: _vm.modulePermissions,
                      "items-selected": _vm.selectedModulePermissions
                    },
                    on: {
                      change: function($event) {
                        _vm.selectedModulePermissions = $event
                      }
                    }
                  })
                : _vm._e()
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-row",
        { staticClass: "mx-0 mt-4" },
        [
          _c("v-btn", { attrs: { color: "primary", type: "submit" } }, [
            _vm._v("Rolle speichern")
          ])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }