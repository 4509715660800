var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-chip-group",
    { attrs: { column: "" } },
    [
      _c(
        "draggable",
        _vm._b(
          {
            attrs: { move: _vm.setMoved },
            on: { remove: _vm.removeElement },
            model: {
              value: _vm.categoryFields,
              callback: function($$v) {
                _vm.categoryFields = $$v
              },
              expression: "categoryFields"
            }
          },
          "draggable",
          _vm.dragOptions,
          false
        ),
        _vm._l(_vm.categoryFields, function(element) {
          return _c(
            "v-chip",
            {
              key: element.id,
              staticClass: "draggable-chip",
              attrs: { small: "", color: element.color },
              on: {
                dblclick: function($event) {
                  return _vm.moveElement(element)
                },
                click: function($event) {
                  return _vm.moveElementClick(element)
                }
              }
            },
            [_vm._v("\n      " + _vm._s(element.field_name) + "\n    ")]
          )
        }),
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }