var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-form",
    {
      ref: "panelForm",
      attrs: { action: _vm.route("admin.panels.save", _vm.panel.id) },
      on: {
        submit: function($event) {
          $event.preventDefault()
          return _vm.saveConfig()
        }
      },
      model: {
        value: _vm.valid,
        callback: function($$v) {
          _vm.valid = $$v
        },
        expression: "valid"
      }
    },
    [
      _c(
        "v-expansion-panels",
        _vm._l(_vm.blocks, function(block, index) {
          return _c(
            "div",
            { key: block.id, staticClass: "panel-wrapper" },
            [
              _c(
                "v-expansion-panel",
                { key: block.id, attrs: { "active-class": "active" } },
                [
                  _c("v-expansion-panel-header", { staticClass: "text-h6" }, [
                    _vm._v("Block: " + _vm._s(block.title))
                  ]),
                  _vm._v(" "),
                  _c(
                    "v-expansion-panel-content",
                    [
                      _c("v-divider", { staticClass: "mb-4" }),
                      _vm._v(" "),
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            { attrs: { cols: "12", lg: "3" } },
                            [
                              _c(
                                "v-row",
                                { attrs: { align: "center" } },
                                [
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "12" } },
                                    [
                                      _c("v-text-field", {
                                        attrs: {
                                          filled: "",
                                          dense: "",
                                          "hide-details": "auto",
                                          rules: _vm.inputRules,
                                          label: "Titel"
                                        },
                                        model: {
                                          value: block.title,
                                          callback: function($$v) {
                                            _vm.$set(block, "title", $$v)
                                          },
                                          expression: "block.title"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "12" } },
                                    [
                                      _c("v-file-input", {
                                        attrs: {
                                          accept:
                                            "image/png, image/jpeg, image/webp",
                                          "show-size": "",
                                          dense: "",
                                          filled: "",
                                          label: "Bild",
                                          "hide-details": "auto",
                                          "prepend-inner-icon": "mdi-camera",
                                          "prepend-icon": ""
                                        },
                                        model: {
                                          value: block.featured_image,
                                          callback: function($$v) {
                                            _vm.$set(
                                              block,
                                              "featured_image",
                                              $$v
                                            )
                                          },
                                          expression: "block.featured_image"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "12" } },
                                    [
                                      _c("v-text-field", {
                                        attrs: {
                                          filled: "",
                                          dense: "",
                                          "hide-details": "auto",
                                          label: "Untertitel"
                                        },
                                        model: {
                                          value: block.subtitle,
                                          callback: function($$v) {
                                            _vm.$set(block, "subtitle", $$v)
                                          },
                                          expression: "block.subtitle"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c("v-row", [
                                _c(
                                  "div",
                                  { staticClass: "px-4" },
                                  [
                                    _c("v-switch", {
                                      staticClass: "my-2 inputSwitch",
                                      attrs: {
                                        dense: "",
                                        inset: "",
                                        label:
                                          "Geöffnet beim Laden? " +
                                          (block.open_on_load ? "Ja" : "Nein"),
                                        "hide-details": "auto"
                                      },
                                      model: {
                                        value: block.open_on_load,
                                        callback: function($$v) {
                                          _vm.$set(block, "open_on_load", $$v)
                                        },
                                        expression: "block.open_on_load"
                                      }
                                    })
                                  ],
                                  1
                                )
                              ]),
                              _vm._v(" "),
                              _c(
                                "v-row",
                                [
                                  _c(
                                    "v-col",
                                    [
                                      _c(
                                        "h4",
                                        { staticClass: "primary--text" },
                                        [_vm._v("Block-Typ")]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "v-radio-group",
                                        {
                                          attrs: {
                                            row: "",
                                            "active-class": "type-selected",
                                            "hide-details": "auto"
                                          },
                                          model: {
                                            value: block.type,
                                            callback: function($$v) {
                                              _vm.$set(block, "type", $$v)
                                            },
                                            expression: "block.type"
                                          }
                                        },
                                        [
                                          _c("v-radio", {
                                            staticClass:
                                              "elevation-4 rounded pa-2",
                                            attrs: { value: "table" },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "label",
                                                  fn: function() {
                                                    return [
                                                      _c(
                                                        "v-tooltip",
                                                        {
                                                          attrs: { bottom: "" },
                                                          scopedSlots: _vm._u(
                                                            [
                                                              {
                                                                key:
                                                                  "activator",
                                                                fn: function(
                                                                  ref
                                                                ) {
                                                                  var on =
                                                                    ref.on
                                                                  var attrs =
                                                                    ref.attrs
                                                                  return [
                                                                    _c(
                                                                      "v-icon",
                                                                      _vm._g(
                                                                        _vm._b(
                                                                          {
                                                                            attrs: {
                                                                              size:
                                                                                "48"
                                                                            }
                                                                          },
                                                                          "v-icon",
                                                                          attrs,
                                                                          false
                                                                        ),
                                                                        on
                                                                      ),
                                                                      [
                                                                        _vm._v(
                                                                          "\n                              mdi-table\n                            "
                                                                        )
                                                                      ]
                                                                    )
                                                                  ]
                                                                }
                                                              }
                                                            ],
                                                            null,
                                                            true
                                                          )
                                                        },
                                                        [
                                                          _vm._v(" "),
                                                          _c("span", [
                                                            _vm._v("Tabelle")
                                                          ])
                                                        ]
                                                      )
                                                    ]
                                                  },
                                                  proxy: true
                                                }
                                              ],
                                              null,
                                              true
                                            )
                                          }),
                                          _vm._v(" "),
                                          _c("v-radio", {
                                            staticClass:
                                              "elevation-4 rounded pa-2",
                                            attrs: { value: "map" },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "label",
                                                  fn: function() {
                                                    return [
                                                      _c(
                                                        "v-tooltip",
                                                        {
                                                          attrs: { bottom: "" },
                                                          scopedSlots: _vm._u(
                                                            [
                                                              {
                                                                key:
                                                                  "activator",
                                                                fn: function(
                                                                  ref
                                                                ) {
                                                                  var on =
                                                                    ref.on
                                                                  var attrs =
                                                                    ref.attrs
                                                                  return [
                                                                    _c(
                                                                      "v-icon",
                                                                      _vm._g(
                                                                        _vm._b(
                                                                          {
                                                                            attrs: {
                                                                              size:
                                                                                "48"
                                                                            }
                                                                          },
                                                                          "v-icon",
                                                                          attrs,
                                                                          false
                                                                        ),
                                                                        on
                                                                      ),
                                                                      [
                                                                        _vm._v(
                                                                          "\n                              mdi-map\n                            "
                                                                        )
                                                                      ]
                                                                    )
                                                                  ]
                                                                }
                                                              }
                                                            ],
                                                            null,
                                                            true
                                                          )
                                                        },
                                                        [
                                                          _vm._v(" "),
                                                          _c("span", [
                                                            _vm._v("Karte")
                                                          ])
                                                        ]
                                                      )
                                                    ]
                                                  },
                                                  proxy: true
                                                }
                                              ],
                                              null,
                                              true
                                            )
                                          })
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "v-row",
                                [
                                  _c(
                                    "v-col",
                                    [
                                      block.type === "table"
                                        ? _c("v-autocomplete", {
                                            attrs: {
                                              dense: "",
                                              filled: "",
                                              "hide-details": "auto",
                                              items: _vm.categories,
                                              "item-value": "field_name",
                                              "item-text": "field_name"
                                            },
                                            model: {
                                              value: block.module,
                                              callback: function($$v) {
                                                _vm.$set(block, "module", $$v)
                                              },
                                              expression: "block.module"
                                            }
                                          })
                                        : _vm._e(),
                                      _vm._v(" "),
                                      block.type === "map"
                                        ? _c("v-autocomplete", {
                                            attrs: {
                                              dense: "",
                                              filled: "",
                                              "hide-details": "auto",
                                              items: _vm.fields,
                                              "item-value": "id",
                                              "item-text": "field_name"
                                            },
                                            model: {
                                              value: block.location_field_id,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  block,
                                                  "location_field_id",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "block.location_field_id"
                                            }
                                          })
                                        : _vm._e()
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-col",
                            { attrs: { cols: "12", lg: "9" } },
                            [
                              block.type === "table"
                                ? [
                                    _c("panel-config-draggable", {
                                      attrs: {
                                        columns: block.panel_columns,
                                        module: block.module,
                                        index: index
                                      },
                                      on: { change: _vm.saveColumns }
                                    })
                                  ]
                                : _vm._e()
                            ],
                            2
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "panel-buttons flex-grow-1 flex-md-grow-0" },
                [
                  _c(
                    "v-btn",
                    {
                      staticClass: "mr-2",
                      attrs: {
                        disabled: index <= 0,
                        color: "secondary",
                        "min-width": "20px",
                        "max-width": "20px"
                      },
                      on: {
                        click: function($event) {
                          return _vm.moveUp(index)
                        }
                      }
                    },
                    [_c("v-icon", [_vm._v("mdi-menu-up")])],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-btn",
                    {
                      staticClass: "mr-2",
                      attrs: {
                        disabled: index >= _vm.blocks.length - 1,
                        color: "secondary",
                        "min-width": "20px",
                        "max-width": "20px"
                      },
                      on: {
                        click: function($event) {
                          return _vm.moveDown(index)
                        }
                      }
                    },
                    [_c("v-icon", [_vm._v("mdi-menu-down")])],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-btn",
                    {
                      staticClass: "mr-2",
                      attrs: {
                        color: "primary",
                        "min-width": "20px",
                        "max-width": "20px"
                      },
                      on: {
                        click: function($event) {
                          return _vm.addBlock(index)
                        }
                      }
                    },
                    [_c("v-icon", [_vm._v("mdi-plus")])],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        color: "error",
                        "min-width": "20px",
                        "max-width": "20px"
                      },
                      on: {
                        click: function($event) {
                          return _vm.removeBlock(index)
                        }
                      }
                    },
                    [_c("v-icon", [_vm._v("mdi-close")])],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        }),
        0
      ),
      _vm._v(" "),
      _c(
        "v-row",
        { staticClass: "mx-0" },
        [
          _c("v-spacer"),
          _vm._v(" "),
          _c(
            "v-btn",
            {
              staticClass: "mt-8",
              attrs: { type: "submit", color: "primary" }
            },
            [_vm._v("Konfiguration speichern")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }