var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-row",
    { attrs: { justify: "center" } },
    [
      _c(
        "v-col",
        { staticClass: "card-wrapper", attrs: { cols: "auto" } },
        [
          _c(
            "v-card",
            {
              staticClass: "mx-auto rounded-lg login-card",
              class: { back: _vm.showRegisterForm },
              attrs: { elevation: "24" }
            },
            [
              _vm.status
                ? _c(
                    "v-snackbar",
                    {
                      attrs: { top: "", color: "primary", absolute: "" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "action",
                            fn: function(ref) {
                              var attrs = ref.attrs
                              return [
                                _c(
                                  "v-btn",
                                  _vm._b(
                                    {
                                      attrs: { color: "white", icon: "" },
                                      on: {
                                        click: function($event) {
                                          _vm.showSnackbar = false
                                        }
                                      }
                                    },
                                    "v-btn",
                                    attrs,
                                    false
                                  ),
                                  [_c("v-icon", [_vm._v("mdi-close")])],
                                  1
                                )
                              ]
                            }
                          }
                        ],
                        null,
                        false,
                        3373482967
                      ),
                      model: {
                        value: _vm.showSnackbar,
                        callback: function($$v) {
                          _vm.showSnackbar = $$v
                        },
                        expression: "showSnackbar"
                      }
                    },
                    [_vm._v("\n        " + _vm._s(_vm.status) + "\n\n        ")]
                  )
                : _vm._e(),
              _vm._v(" "),
              _c("v-card-title", [_vm._t("logo")], 2),
              _vm._v(" "),
              _c(
                "v-card-text",
                { staticClass: "py-0" },
                [_vm._t("content")],
                2
              ),
              _vm._v(" "),
              _c(
                "v-card-actions",
                { class: { "py-7": !_vm.showActions } },
                [
                  _vm.showActions
                    ? _c(
                        "v-row",
                        { staticClass: "mx-0" },
                        [
                          _vm._t("actions"),
                          _vm._v(" "),
                          _c("v-spacer"),
                          _vm._v(" "),
                          _vm.allowRegister
                            ? _c(
                                "v-btn",
                                {
                                  staticClass: "text-transform-none px-0",
                                  attrs: {
                                    link: "",
                                    text: "",
                                    color: "primary",
                                    plain: ""
                                  },
                                  on: { click: _vm.toggleRegisterForm }
                                },
                                [
                                  _vm._v(
                                    "\n            (+) registrieren\n          "
                                  )
                                ]
                              )
                            : _vm._e()
                        ],
                        2
                      )
                    : _vm._e()
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-card",
                {
                  staticClass: "register-card",
                  class: { active: _vm.showRegisterForm },
                  attrs: { color: "primary", dark: "" }
                },
                [
                  _c("v-card-title", [
                    _c("h1", { staticClass: "white--text" }, [
                      _vm._v("Konto registrieren")
                    ])
                  ]),
                  _vm._v(" "),
                  _c(
                    "v-card-text",
                    { staticClass: "py-0" },
                    [_vm._t("register")],
                    2
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _vm.allowRegister
            ? _c(
                "v-btn",
                {
                  staticClass: "register-fab",
                  class: { active: _vm.showRegisterForm },
                  attrs: {
                    color: !_vm.showRegisterForm ? "primary" : "error",
                    "x-large": "",
                    absolute: "",
                    fab: ""
                  },
                  on: { click: _vm.toggleRegisterForm }
                },
                [_c("v-icon", { attrs: { size: "30" } }, [_vm._v("mdi-plus")])],
                1
              )
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }