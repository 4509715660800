var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    { attrs: { flat: "" } },
    [
      _c("v-card-title", { staticClass: "primary--text" }, [
        _vm._v(" aktuelle Nachrichten ")
      ]),
      _vm._v(" "),
      _c(
        "v-container",
        { staticClass: "py-0" },
        [
          _c(
            "v-row",
            {
              class: { articleRow: _vm.$vuetify.breakpoint.lgAndUp },
              attrs: { dense: "" }
            },
            _vm._l(_vm.news, function(article) {
              return _c(
                "v-col",
                { key: article.id, attrs: { cols: "12", lg: "6" } },
                [
                  _c(
                    "v-card",
                    [
                      _c(
                        "v-card-title",
                        { staticClass: "pt-2 pb-4" },
                        [
                          _vm._v(_vm._s(article.title)),
                          _c("v-spacer"),
                          _c("span", { staticClass: "body-2" }, [
                            _vm._v(
                              _vm._s(
                                _vm._f("formatDateTime")(article.publishDate)
                              )
                            )
                          ])
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-card-text",
                        { staticClass: "pb-0 articleText" },
                        [
                          _c("v-img", {
                            attrs: {
                              width: "125px",
                              "max-width": "125px",
                              "aspect-ratio": "1",
                              src: article.featuredImage + "_thumbnail.webp"
                            }
                          }),
                          _vm._v(" "),
                          _c("div", [_vm._v(_vm._s(article.excerpt))])
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-card-actions",
                        [
                          _c("v-spacer"),
                          _vm._v(" "),
                          _c(
                            "v-btn",
                            {
                              attrs: {
                                color: "primary",
                                text: "",
                                link: "",
                                href: "/news/" + article.slug
                              }
                            },
                            [_vm._v("weiterlesen")]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            }),
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-card-actions",
        [
          _c("v-spacer"),
          _vm._v(" "),
          _c(
            "v-btn",
            {
              staticClass: "mx-auto",
              attrs: { color: "secondary", text: "", link: "", href: "/news" }
            },
            [_vm._v("Alle Artikel anzeigen")]
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-overlay",
        {
          attrs: { absolute: "" },
          model: {
            value: _vm.loading,
            callback: function($$v) {
              _vm.loading = $$v
            },
            expression: "loading"
          }
        },
        [
          _c("v-progress-circular", {
            attrs: { indeterminate: "", size: "64" }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }