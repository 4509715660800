var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.bookmarks && _vm.bookmarks.length > 0
    ? _c(
        "v-card",
        { attrs: { flat: "" } },
        [
          _c("v-card-title", { staticClass: "primary--text" }, [
            _vm._v(" Lesezeichen ")
          ]),
          _vm._v(" "),
          _c(
            "v-container",
            { staticClass: "py-0" },
            _vm._l(_vm.bookmarks, function(bookmark) {
              return _c(
                "v-row",
                { key: bookmark.id, attrs: { dense: "" } },
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "12" } },
                    [
                      _c(
                        "v-card",
                        [
                          _c("v-card-title", { staticClass: "py-2" }, [
                            _vm._v(_vm._s(bookmark.title))
                          ]),
                          _vm._v(" "),
                          _c(
                            "v-card-actions",
                            [
                              _c("v-spacer"),
                              _vm._v(" "),
                              _c(
                                "v-btn",
                                {
                                  attrs: {
                                    color: "primary",
                                    text: "",
                                    link: "",
                                    href: _vm.route(
                                      bookmark.route,
                                      bookmark.bookmark_id
                                    )
                                  }
                                },
                                [_vm._v("Zum Lesezeichen")]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            }),
            1
          ),
          _vm._v(" "),
          _c("v-card-actions")
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }