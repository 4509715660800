var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-stepper",
    {
      model: {
        value: _vm.step,
        callback: function($$v) {
          _vm.step = $$v
        },
        expression: "step"
      }
    },
    [
      _c(
        "v-stepper-header",
        [
          _c(
            "v-stepper-step",
            {
              attrs: { complete: _vm.step > 1, rules: _vm.dataRules, step: "1" }
            },
            [_vm._v("\n      Allgemeine Daten\n    ")]
          ),
          _vm._v(" "),
          _c("v-divider"),
          _vm._v(" "),
          _c(
            "v-stepper-step",
            { attrs: { complete: _vm.step > 2, step: "2" } },
            [_vm._v("\n      Gruppierungen und Berechnungen\n    ")]
          ),
          _vm._v(" "),
          _c("v-divider"),
          _vm._v(" "),
          _c(
            "v-stepper-step",
            { attrs: { complete: _vm.step > 3, step: "3" } },
            [_vm._v("\n      Bedingungen\n    ")]
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-stepper-items",
        [
          _c(
            "v-stepper-content",
            { attrs: { step: "1" } },
            [
              _c(
                "v-form",
                {
                  ref: "dataForm",
                  attrs: { "lazy-validation": "" },
                  model: {
                    value: _vm.dataFormValid,
                    callback: function($$v) {
                      _vm.dataFormValid = $$v
                    },
                    expression: "dataFormValid"
                  }
                },
                [
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "12", lg: "6" } },
                        [
                          _c("v-text-field", {
                            attrs: {
                              filled: "",
                              dense: "",
                              "hide-details": "auto",
                              label: "Name",
                              rules: _vm.inputRules
                            },
                            model: {
                              value: _vm.name,
                              callback: function($$v) {
                                _vm.name = $$v
                              },
                              expression: "name"
                            }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-col",
                        { attrs: { cols: "12", lg: "6" } },
                        [
                          _c("v-text-field", {
                            attrs: {
                              filled: "",
                              dense: "",
                              "hide-details": "auto",
                              label: "Beschreibung",
                              rules: _vm.inputRules
                            },
                            model: {
                              value: _vm.description,
                              callback: function($$v) {
                                _vm.description = $$v
                              },
                              expression: "description"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "12", lg: "6" } },
                        [
                          _c("v-autocomplete", {
                            attrs: {
                              filled: "",
                              dense: "",
                              "hide-details": "auto",
                              label: "Primäres Modul",
                              items: _vm.categories,
                              "item-text": "name",
                              clearable: "",
                              "return-object": "",
                              rules: _vm.inputRules
                            },
                            model: {
                              value: _vm.primaryModule,
                              callback: function($$v) {
                                _vm.primaryModule = $$v
                              },
                              expression: "primaryModule"
                            }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-col",
                        { attrs: { cols: "12", lg: "6" } },
                        [
                          _c("v-autocomplete", {
                            attrs: {
                              filled: "",
                              dense: "",
                              "hide-details": "auto",
                              label: "Weitere Module (Max 2)",
                              items: _vm.filteredCategories,
                              "item-text": "name",
                              multiple: "",
                              chips: "",
                              "deletable-chips": "",
                              "small-chips": "",
                              clearable: "",
                              "return-object": "",
                              rules: _vm.additionalModulesRules
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "selection",
                                fn: function(data) {
                                  return [
                                    _c(
                                      "v-chip",
                                      {
                                        attrs: {
                                          small: "",
                                          close: "",
                                          color: "primary"
                                        },
                                        on: {
                                          "click:close": function($event) {
                                            return _vm.removeModule(data.item)
                                          }
                                        }
                                      },
                                      [_vm._v(_vm._s(data.item.name))]
                                    )
                                  ]
                                }
                              }
                            ]),
                            model: {
                              value: _vm.additionalModules,
                              callback: function($$v) {
                                _vm.additionalModules = $$v
                              },
                              expression: "additionalModules"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _vm.primaryModule
                    ? [
                        _c(
                          "v-row",
                          [
                            _c(
                              "v-col",
                              { attrs: { cols: "12" } },
                              [
                                _c("v-autocomplete", {
                                  attrs: {
                                    filled: "",
                                    dense: "",
                                    "hide-details": "auto",
                                    label: "Spalten auswählen",
                                    counter: "25",
                                    "counter-value": function() {
                                      return _vm.columns.length
                                    },
                                    items: _vm.availableFields,
                                    "item-text": function(item) {
                                      return (
                                        item.portal_field_category.name +
                                        ": " +
                                        item.field_name
                                      )
                                    },
                                    multiple: "",
                                    "item-value": "id",
                                    clearable: "",
                                    "return-object": "",
                                    rules: _vm.columnRules
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "selection",
                                        fn: function(data) {
                                          return [
                                            _c(
                                              "v-chip",
                                              {
                                                staticClass: "my-2",
                                                attrs: {
                                                  small: "",
                                                  close: "",
                                                  color:
                                                    data.item
                                                      .portal_field_category
                                                      .color
                                                },
                                                on: {
                                                  "click:close": function(
                                                    $event
                                                  ) {
                                                    return _vm.removeColumn(
                                                      data.item
                                                    )
                                                  }
                                                }
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    data.item
                                                      .portal_field_category
                                                      .name
                                                  ) +
                                                    ":\n                    " +
                                                    _vm._s(data.item.field_name)
                                                )
                                              ]
                                            )
                                          ]
                                        }
                                      }
                                    ],
                                    null,
                                    false,
                                    2614847413
                                  ),
                                  model: {
                                    value: _vm.columns,
                                    callback: function($$v) {
                                      _vm.columns = $$v
                                    },
                                    expression: "columns"
                                  }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _vm.sortedFields && _vm.sortedFields.length > 0
                          ? _c(
                              "v-row",
                              [
                                _c("v-col", { attrs: { cols: "12" } }, [
                                  _c("h3", { staticClass: "primary--text" }, [
                                    _vm._v("Spalten sortieren")
                                  ])
                                ]),
                                _vm._v(" "),
                                _c(
                                  "v-col",
                                  [
                                    _c(
                                      "draggable",
                                      _vm._b(
                                        {
                                          staticClass: "d-flex flex-wrap",
                                          staticStyle: { "column-gap": "12px" },
                                          model: {
                                            value: _vm.sortedFields,
                                            callback: function($$v) {
                                              _vm.sortedFields = $$v
                                            },
                                            expression: "sortedFields"
                                          }
                                        },
                                        "draggable",
                                        _vm.dragOptions,
                                        false
                                      ),
                                      _vm._l(_vm.sortedFields, function(
                                        element
                                      ) {
                                        return _c(
                                          "v-chip",
                                          {
                                            key: element.id,
                                            staticClass: "draggable-chip my-2",
                                            attrs: {
                                              small: "",
                                              color:
                                                element.portal_field_category
                                                  .color
                                            }
                                          },
                                          [
                                            _vm._v(
                                              "\n                  " +
                                                _vm._s(element.field_name) +
                                                "\n                "
                                            )
                                          ]
                                        )
                                      }),
                                      1
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          : _vm._e()
                      ]
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "v-row",
                    { staticClass: "ma-0 mt-4" },
                    [
                      _c("v-spacer"),
                      _vm._v(" "),
                      _c(
                        "v-btn",
                        {
                          attrs: {
                            color: "primary",
                            disabled: !_vm.dataFormValid
                          },
                          on: { click: _vm.checkStep1 }
                        },
                        [_vm._v("\n            Weiter\n          ")]
                      )
                    ],
                    1
                  )
                ],
                2
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-stepper-content",
            { attrs: { step: "2" } },
            [
              _c(
                "v-row",
                [
                  _c("v-col", { attrs: { cols: "12" } }, [
                    _c("h3", { staticClass: "primary--text" }, [
                      _vm._v("Gruppierung")
                    ])
                  ]),
                  _vm._v(" "),
                  _c(
                    "v-col",
                    [
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            { attrs: { cols: "8", lg: "6" } },
                            [
                              _c("v-autocomplete", {
                                attrs: {
                                  filled: "",
                                  dense: "",
                                  "hide-details": "auto",
                                  label: "Feld auswählen",
                                  items: _vm.availableFields,
                                  "item-text": function(item) {
                                    return (
                                      item.portal_field_category.name +
                                      ": " +
                                      item.field_name
                                    )
                                  },
                                  "item-value": "id",
                                  clearable: "",
                                  "return-object": ""
                                },
                                model: {
                                  value: _vm.groupBy.field,
                                  callback: function($$v) {
                                    _vm.$set(_vm.groupBy, "field", $$v)
                                  },
                                  expression: "groupBy.field"
                                }
                              })
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-col",
                            {
                              staticClass: "d-flex align-center",
                              attrs: { cols: "4", lg: "4" }
                            },
                            [
                              _c(
                                "v-radio-group",
                                {
                                  staticClass: "pt-0 mt-0",
                                  attrs: { row: "", "hide-details": "auto" },
                                  model: {
                                    value: _vm.groupBy.sort,
                                    callback: function($$v) {
                                      _vm.$set(_vm.groupBy, "sort", $$v)
                                    },
                                    expression: "groupBy.sort"
                                  }
                                },
                                [
                                  _c("v-radio", {
                                    attrs: {
                                      label: "Aufsteigend",
                                      value: "ASC"
                                    }
                                  }),
                                  _vm._v(" "),
                                  _c("v-radio", {
                                    attrs: {
                                      label: "Absteigend",
                                      value: "DESC"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-row",
                [
                  _c("v-col", { attrs: { cols: "12" } }, [
                    _c("h3", { staticClass: "primary--text" }, [
                      _vm._v("Berechnungen")
                    ])
                  ]),
                  _vm._v(" "),
                  _c(
                    "v-col",
                    [
                      _vm.calculations.length === 0
                        ? _c(
                            "v-row",
                            [
                              _c(
                                "v-col",
                                {
                                  staticClass: "d-flex justify-center",
                                  attrs: { cols: "8", lg: "6" }
                                },
                                [
                                  _c(
                                    "v-btn",
                                    {
                                      attrs: {
                                        "x-small": "",
                                        color: "primary",
                                        "min-width": "20px",
                                        "max-width": "20px"
                                      },
                                      on: {
                                        click: function($event) {
                                          return _vm.addCalculation(0)
                                        }
                                      }
                                    },
                                    [_c("v-icon", [_vm._v("mdi-plus")])],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm._l(_vm.calculations, function(item, index) {
                        return [
                          _c(
                            "div",
                            { key: index, staticClass: "mb-4" },
                            [
                              _c(
                                "v-row",
                                [
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "7", lg: "6" } },
                                    [
                                      _c("v-autocomplete", {
                                        attrs: {
                                          filled: "",
                                          dense: "",
                                          "hide-details": "auto",
                                          label: "Feld auswählen",
                                          items: _vm.numberColumns,
                                          "item-text": function(item) {
                                            return (
                                              item.portal_field_category.name +
                                              ": " +
                                              item.field_name
                                            )
                                          },
                                          "item-value": "id",
                                          clearable: "",
                                          "return-object": ""
                                        },
                                        model: {
                                          value: item.field,
                                          callback: function($$v) {
                                            _vm.$set(item, "field", $$v)
                                          },
                                          expression: "item.field"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-col",
                                    {
                                      staticClass: "d-flex align-center",
                                      attrs: { cols: "3", lg: "5" }
                                    },
                                    [
                                      _c(
                                        "v-radio-group",
                                        {
                                          staticClass: "pt-0 mt-0",
                                          attrs: {
                                            row: "",
                                            "hide-details": "auto"
                                          },
                                          model: {
                                            value: item.method,
                                            callback: function($$v) {
                                              _vm.$set(item, "method", $$v)
                                            },
                                            expression: "item.method"
                                          }
                                        },
                                        [
                                          _c("v-radio", {
                                            attrs: {
                                              label: "Summe",
                                              value: "SUM"
                                            }
                                          }),
                                          _vm._v(" "),
                                          _c("v-radio", {
                                            attrs: {
                                              label: "Durchschnitt",
                                              value: "AVG"
                                            }
                                          }),
                                          _vm._v(" "),
                                          _c("v-radio", {
                                            attrs: {
                                              label: "kleinster Wert",
                                              value: "MIN"
                                            }
                                          }),
                                          _vm._v(" "),
                                          _c("v-radio", {
                                            attrs: {
                                              label: "größter Wert",
                                              value: "MAX"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-col",
                                    {
                                      staticClass:
                                        "d-flex justify-center align-center",
                                      staticStyle: { "column-gap": "8px" },
                                      attrs: { cols: "2", lg: "1" }
                                    },
                                    [
                                      _c(
                                        "v-btn",
                                        {
                                          attrs: {
                                            "x-small": "",
                                            color: "primary",
                                            "min-width": "20px",
                                            "max-width": "20px"
                                          },
                                          on: {
                                            click: function($event) {
                                              return _vm.addCalculation(index)
                                            }
                                          }
                                        },
                                        [_c("v-icon", [_vm._v("mdi-plus")])],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "v-btn",
                                        {
                                          attrs: {
                                            "x-small": "",
                                            color: "error",
                                            "min-width": "20px",
                                            "max-width": "20px"
                                          },
                                          on: {
                                            click: function($event) {
                                              return _vm.removeCalculation(
                                                index
                                              )
                                            }
                                          }
                                        },
                                        [_c("v-icon", [_vm._v("mdi-close")])],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ]
                      })
                    ],
                    2
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-row",
                { staticClass: "ma-0 mt-4" },
                [
                  _c(
                    "v-btn",
                    {
                      attrs: { text: "" },
                      on: {
                        click: function($event) {
                          _vm.step = 1
                        }
                      }
                    },
                    [_vm._v(" Zurück ")]
                  ),
                  _vm._v(" "),
                  _c("v-spacer"),
                  _vm._v(" "),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "primary" },
                      on: {
                        click: function($event) {
                          _vm.step = 3
                        }
                      }
                    },
                    [_vm._v(" Weiter ")]
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-stepper-content",
            { attrs: { step: "3" } },
            [
              _c(
                "v-form",
                {
                  ref: "conditionsForm",
                  attrs: { "lazy-validation": "" },
                  model: {
                    value: _vm.conditionFormValid,
                    callback: function($$v) {
                      _vm.conditionFormValid = $$v
                    },
                    expression: "conditionFormValid"
                  }
                },
                [
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "12", md: "6" } },
                        [
                          _c("h3", { staticClass: "primary--text mb-4" }, [
                            _vm._v("alle Bedingungen treffen zu:")
                          ]),
                          _vm._v(" "),
                          _vm.conditionAll.length == 0
                            ? _c(
                                "v-row",
                                {
                                  staticClass: "py-4",
                                  attrs: { justify: "center", align: "center" }
                                },
                                [
                                  _c(
                                    "v-btn",
                                    {
                                      staticClass: "mr-2",
                                      attrs: {
                                        "x-small": "",
                                        color: "primary",
                                        "min-width": "20px",
                                        "max-width": "20px"
                                      },
                                      on: {
                                        click: function($event) {
                                          return _vm.addCondition(0, "all")
                                        }
                                      }
                                    },
                                    [_c("v-icon", [_vm._v("mdi-plus")])],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-btn",
                                    {
                                      attrs: {
                                        "x-small": "",
                                        color: "error",
                                        "min-width": "20px",
                                        "max-width": "20px"
                                      },
                                      on: {
                                        click: function($event) {
                                          return _vm.removeCondition(0, "all")
                                        }
                                      }
                                    },
                                    [_c("v-icon", [_vm._v("mdi-close")])],
                                    1
                                  )
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm._l(_vm.conditionAll, function(condition, index) {
                            return _c(
                              "v-row",
                              { key: index },
                              [
                                _c(
                                  "v-col",
                                  [
                                    _c("v-autocomplete", {
                                      attrs: {
                                        dense: "",
                                        filled: "",
                                        clearable: "",
                                        "hide-details": "auto",
                                        rules: _vm.inputRules,
                                        items: _vm.availableFields,
                                        "item-value": "id",
                                        "item-text": function(item) {
                                          return (
                                            item.portal_field_category.name +
                                            ": " +
                                            item.field_name
                                          )
                                        },
                                        required: ""
                                      },
                                      on: {
                                        change: function($event) {
                                          return _vm.setDataType(condition)
                                        }
                                      },
                                      model: {
                                        value: condition.field,
                                        callback: function($$v) {
                                          _vm.$set(condition, "field", $$v)
                                        },
                                        expression: "condition.field"
                                      }
                                    })
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                condition.data_type !== "text"
                                  ? _c(
                                      "v-col",
                                      { attrs: { cols: "2" } },
                                      [
                                        _c("v-select", {
                                          staticClass: "condition-select mt-0",
                                          attrs: {
                                            "hide-details": "auto",
                                            dense: "",
                                            height: "100%",
                                            "full-width": "",
                                            value: "e",
                                            "menu-props": {
                                              "content-class":
                                                "condition-select-menu",
                                              bottom: true,
                                              "offset-y": true
                                            },
                                            "hide-selected": "",
                                            items: _vm.operators.number
                                          },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "selection",
                                                fn: function(ref) {
                                                  var item = ref.item
                                                  return [
                                                    item.hasOwnProperty("icon")
                                                      ? _c(
                                                          "v-icon",
                                                          {
                                                            attrs: {
                                                              color: "white"
                                                            }
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(item.icon)
                                                            )
                                                          ]
                                                        )
                                                      : _c(
                                                          "span",
                                                          {
                                                            staticClass:
                                                              "white--text body-2 text-center"
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(item.text)
                                                            )
                                                          ]
                                                        )
                                                  ]
                                                }
                                              },
                                              {
                                                key: "item",
                                                fn: function(ref) {
                                                  var item = ref.item
                                                  return [
                                                    item.hasOwnProperty("icon")
                                                      ? _c("v-icon", [
                                                          _vm._v(
                                                            _vm._s(item.icon)
                                                          )
                                                        ])
                                                      : _c(
                                                          "span",
                                                          {
                                                            staticClass:
                                                              "body-2"
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(item.text)
                                                            )
                                                          ]
                                                        )
                                                  ]
                                                }
                                              }
                                            ],
                                            null,
                                            true
                                          ),
                                          model: {
                                            value: condition.operator,
                                            callback: function($$v) {
                                              _vm.$set(
                                                condition,
                                                "operator",
                                                $$v
                                              )
                                            },
                                            expression: "condition.operator"
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  : _c(
                                      "v-col",
                                      { attrs: { cols: "2" } },
                                      [
                                        _c("v-select", {
                                          staticClass: "condition-select mt-0",
                                          attrs: {
                                            "hide-details": "auto",
                                            dense: "",
                                            height: "100%",
                                            "full-width": "",
                                            value: "e",
                                            "menu-props": {
                                              "content-class":
                                                "condition-select-menu",
                                              bottom: true,
                                              "offset-y": true
                                            },
                                            "hide-selected": "",
                                            items: _vm.operators.text
                                          },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "selection",
                                                fn: function(ref) {
                                                  var item = ref.item
                                                  return [
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "white--text body-2 text-center"
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(item.text)
                                                        )
                                                      ]
                                                    )
                                                  ]
                                                }
                                              },
                                              {
                                                key: "item",
                                                fn: function(ref) {
                                                  var item = ref.item
                                                  return [
                                                    _c(
                                                      "span",
                                                      { staticClass: "body-2" },
                                                      [
                                                        _vm._v(
                                                          _vm._s(item.text)
                                                        )
                                                      ]
                                                    )
                                                  ]
                                                }
                                              },
                                              {
                                                key: "append-outer",
                                                fn: function() {
                                                  return [
                                                    _c(
                                                      "v-tooltip",
                                                      {
                                                        attrs: { bottom: "" },
                                                        scopedSlots: _vm._u(
                                                          [
                                                            {
                                                              key: "activator",
                                                              fn: function(
                                                                ref
                                                              ) {
                                                                var on = ref.on
                                                                var attrs =
                                                                  ref.attrs
                                                                return [
                                                                  _c(
                                                                    "v-btn",
                                                                    _vm._g(
                                                                      _vm._b(
                                                                        {
                                                                          staticClass:
                                                                            "mt-n1",
                                                                          attrs: {
                                                                            small:
                                                                              "",
                                                                            color:
                                                                              "info",
                                                                            icon:
                                                                              ""
                                                                          }
                                                                        },
                                                                        "v-btn",
                                                                        attrs,
                                                                        false
                                                                      ),
                                                                      on
                                                                    ),
                                                                    [
                                                                      _c(
                                                                        "v-icon",
                                                                        [
                                                                          _vm._v(
                                                                            " mdi-help-circle-outline "
                                                                          )
                                                                        ]
                                                                      )
                                                                    ],
                                                                    1
                                                                  )
                                                                ]
                                                              }
                                                            }
                                                          ],
                                                          null,
                                                          true
                                                        )
                                                      },
                                                      [
                                                        _vm._v(" "),
                                                        _c("span", [
                                                          _vm._v(
                                                            "% als Platzhalter verwenden"
                                                          )
                                                        ])
                                                      ]
                                                    )
                                                  ]
                                                },
                                                proxy: true
                                              }
                                            ],
                                            null,
                                            true
                                          ),
                                          model: {
                                            value: condition.operator,
                                            callback: function($$v) {
                                              _vm.$set(
                                                condition,
                                                "operator",
                                                $$v
                                              )
                                            },
                                            expression: "condition.operator"
                                          }
                                        })
                                      ],
                                      1
                                    ),
                                _vm._v(" "),
                                [
                                  condition.data_type == "date"
                                    ? _c(
                                        "v-col",
                                        [
                                          _c(
                                            "v-menu",
                                            {
                                              ref: "menu",
                                              refInFor: true,
                                              attrs: {
                                                "close-on-content-click": false,
                                                transition: "scale-transition",
                                                "offset-y": "",
                                                "min-width": "auto"
                                              },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "activator",
                                                    fn: function(ref) {
                                                      var on = ref.on
                                                      var attrs = ref.attrs
                                                      return [
                                                        _c(
                                                          "v-text-field",
                                                          _vm._g(
                                                            _vm._b(
                                                              {
                                                                attrs: {
                                                                  "prepend-inner-icon":
                                                                    "mdi-calendar",
                                                                  readonly: "",
                                                                  filled: "",
                                                                  dense: "",
                                                                  "hide-details":
                                                                    "auto",
                                                                  disabled:
                                                                    condition.operator ===
                                                                      "null" ||
                                                                    condition.operator ===
                                                                      "not_null",
                                                                  value: _vm.$filters.formatDate(
                                                                    condition.value
                                                                  )
                                                                },
                                                                scopedSlots: _vm._u(
                                                                  [
                                                                    {
                                                                      key:
                                                                        "append-outer",
                                                                      fn: function() {
                                                                        return [
                                                                          _c(
                                                                            "v-btn",
                                                                            {
                                                                              staticClass:
                                                                                "mr-2",
                                                                              attrs: {
                                                                                "x-small":
                                                                                  "",
                                                                                color:
                                                                                  "primary",
                                                                                "min-width":
                                                                                  "20px",
                                                                                "max-width":
                                                                                  "20px"
                                                                              },
                                                                              on: {
                                                                                click: function(
                                                                                  $event
                                                                                ) {
                                                                                  return _vm.addCondition(
                                                                                    index,
                                                                                    "all"
                                                                                  )
                                                                                }
                                                                              }
                                                                            },
                                                                            [
                                                                              _c(
                                                                                "v-icon",
                                                                                [
                                                                                  _vm._v(
                                                                                    "mdi-plus"
                                                                                  )
                                                                                ]
                                                                              )
                                                                            ],
                                                                            1
                                                                          ),
                                                                          _vm._v(
                                                                            " "
                                                                          ),
                                                                          _c(
                                                                            "v-btn",
                                                                            {
                                                                              attrs: {
                                                                                "x-small":
                                                                                  "",
                                                                                color:
                                                                                  "error",
                                                                                "min-width":
                                                                                  "20px",
                                                                                "max-width":
                                                                                  "20px"
                                                                              },
                                                                              on: {
                                                                                click: function(
                                                                                  $event
                                                                                ) {
                                                                                  return _vm.removeCondition(
                                                                                    index,
                                                                                    "all"
                                                                                  )
                                                                                }
                                                                              }
                                                                            },
                                                                            [
                                                                              _c(
                                                                                "v-icon",
                                                                                [
                                                                                  _vm._v(
                                                                                    "mdi-close"
                                                                                  )
                                                                                ]
                                                                              )
                                                                            ],
                                                                            1
                                                                          )
                                                                        ]
                                                                      },
                                                                      proxy: true
                                                                    }
                                                                  ],
                                                                  null,
                                                                  true
                                                                )
                                                              },
                                                              "v-text-field",
                                                              attrs,
                                                              false
                                                            ),
                                                            on
                                                          )
                                                        )
                                                      ]
                                                    }
                                                  }
                                                ],
                                                null,
                                                true
                                              ),
                                              model: {
                                                value: condition.menu,
                                                callback: function($$v) {
                                                  _vm.$set(
                                                    condition,
                                                    "menu",
                                                    $$v
                                                  )
                                                },
                                                expression: "condition.menu"
                                              }
                                            },
                                            [
                                              _vm._v(" "),
                                              _c(
                                                "v-card",
                                                { attrs: { flat: "" } },
                                                [
                                                  _c(
                                                    "v-card-text",
                                                    [
                                                      _c("v-date-picker", {
                                                        attrs: {
                                                          color: "secondary",
                                                          "first-day-of-week":
                                                            "1",
                                                          landscape: "",
                                                          locale: "de-DE"
                                                        },
                                                        model: {
                                                          value:
                                                            condition.value,
                                                          callback: function(
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              condition,
                                                              "value",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "condition.value"
                                                        }
                                                      })
                                                    ],
                                                    1
                                                  )
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    : _c(
                                        "v-col",
                                        [
                                          _c("v-text-field", {
                                            attrs: {
                                              dense: "",
                                              filled: "",
                                              "hide-details": "auto",
                                              disabled:
                                                condition.operator === "null" ||
                                                condition.operator ===
                                                  "not_null",
                                              required: ""
                                            },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "append-outer",
                                                  fn: function() {
                                                    return [
                                                      _c(
                                                        "v-btn",
                                                        {
                                                          staticClass: "mr-2",
                                                          attrs: {
                                                            "x-small": "",
                                                            color: "primary",
                                                            "min-width": "20px",
                                                            "max-width": "20px"
                                                          },
                                                          on: {
                                                            click: function(
                                                              $event
                                                            ) {
                                                              return _vm.addCondition(
                                                                index,
                                                                "all"
                                                              )
                                                            }
                                                          }
                                                        },
                                                        [
                                                          _c("v-icon", [
                                                            _vm._v("mdi-plus")
                                                          ])
                                                        ],
                                                        1
                                                      ),
                                                      _vm._v(" "),
                                                      _c(
                                                        "v-btn",
                                                        {
                                                          attrs: {
                                                            "x-small": "",
                                                            color: "error",
                                                            "min-width": "20px",
                                                            "max-width": "20px"
                                                          },
                                                          on: {
                                                            click: function(
                                                              $event
                                                            ) {
                                                              return _vm.removeCondition(
                                                                index,
                                                                "all"
                                                              )
                                                            }
                                                          }
                                                        },
                                                        [
                                                          _c("v-icon", [
                                                            _vm._v("mdi-close")
                                                          ])
                                                        ],
                                                        1
                                                      )
                                                    ]
                                                  },
                                                  proxy: true
                                                }
                                              ],
                                              null,
                                              true
                                            ),
                                            model: {
                                              value: condition.value,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  condition,
                                                  "value",
                                                  $$v
                                                )
                                              },
                                              expression: "condition.value"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                ]
                              ],
                              2
                            )
                          })
                        ],
                        2
                      ),
                      _vm._v(" "),
                      _c(
                        "v-col",
                        { attrs: { cols: "12", md: "6" } },
                        [
                          _c("h3", { staticClass: "primary--text mb-4" }, [
                            _vm._v(
                              "\n              mindestens eine Bedingung trifft zu:\n            "
                            )
                          ]),
                          _vm._v(" "),
                          _vm.conditionOne.length == 0
                            ? _c(
                                "v-row",
                                {
                                  staticClass: "py-4",
                                  attrs: { justify: "center", align: "center" }
                                },
                                [
                                  _c(
                                    "v-btn",
                                    {
                                      staticClass: "mr-2",
                                      attrs: {
                                        "x-small": "",
                                        color: "primary",
                                        "min-width": "20px",
                                        "max-width": "20px"
                                      },
                                      on: {
                                        click: function($event) {
                                          return _vm.addCondition(0, "one")
                                        }
                                      }
                                    },
                                    [_c("v-icon", [_vm._v("mdi-plus")])],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-btn",
                                    {
                                      attrs: {
                                        "x-small": "",
                                        color: "error",
                                        "min-width": "20px",
                                        "max-width": "20px"
                                      },
                                      on: {
                                        click: function($event) {
                                          return _vm.removeCondition(0, "one")
                                        }
                                      }
                                    },
                                    [_c("v-icon", [_vm._v("mdi-close")])],
                                    1
                                  )
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm._l(_vm.conditionOne, function(condition, index) {
                            return _c(
                              "v-row",
                              { key: index },
                              [
                                _c(
                                  "v-col",
                                  [
                                    _c("v-autocomplete", {
                                      attrs: {
                                        dense: "",
                                        filled: "",
                                        clearable: "",
                                        "hide-details": "auto",
                                        rules: _vm.inputRules,
                                        items: _vm.availableFields,
                                        "item-value": "id",
                                        "item-text": function(item) {
                                          return (
                                            item.portal_field_category.name +
                                            ": " +
                                            item.field_name
                                          )
                                        },
                                        required: ""
                                      },
                                      on: {
                                        change: function($event) {
                                          return _vm.setDataType(condition)
                                        }
                                      },
                                      model: {
                                        value: condition.field,
                                        callback: function($$v) {
                                          _vm.$set(condition, "field", $$v)
                                        },
                                        expression: "condition.field"
                                      }
                                    })
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                condition.data_type !== "text"
                                  ? _c(
                                      "v-col",
                                      { attrs: { cols: "2" } },
                                      [
                                        _c("v-select", {
                                          staticClass: "condition-select mt-0",
                                          attrs: {
                                            "hide-details": "auto",
                                            dense: "",
                                            height: "100%",
                                            "full-width": "",
                                            value: "e",
                                            "menu-props": {
                                              "content-class":
                                                "condition-select-menu",
                                              bottom: true,
                                              "offset-y": true
                                            },
                                            "hide-selected": "",
                                            items: _vm.operators.number
                                          },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "selection",
                                                fn: function(ref) {
                                                  var item = ref.item
                                                  return [
                                                    item.hasOwnProperty("icon")
                                                      ? _c(
                                                          "v-icon",
                                                          {
                                                            attrs: {
                                                              color: "white"
                                                            }
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(item.icon)
                                                            )
                                                          ]
                                                        )
                                                      : _c(
                                                          "span",
                                                          {
                                                            staticClass:
                                                              "white--text body-2 text-center"
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(item.text)
                                                            )
                                                          ]
                                                        )
                                                  ]
                                                }
                                              },
                                              {
                                                key: "item",
                                                fn: function(ref) {
                                                  var item = ref.item
                                                  return [
                                                    item.hasOwnProperty("icon")
                                                      ? _c("v-icon", [
                                                          _vm._v(
                                                            _vm._s(item.icon)
                                                          )
                                                        ])
                                                      : _c(
                                                          "span",
                                                          {
                                                            staticClass:
                                                              "body-2"
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(item.text)
                                                            )
                                                          ]
                                                        )
                                                  ]
                                                }
                                              }
                                            ],
                                            null,
                                            true
                                          ),
                                          model: {
                                            value: condition.operator,
                                            callback: function($$v) {
                                              _vm.$set(
                                                condition,
                                                "operator",
                                                $$v
                                              )
                                            },
                                            expression: "condition.operator"
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  : _c(
                                      "v-col",
                                      { attrs: { cols: "2" } },
                                      [
                                        _c("v-select", {
                                          staticClass: "condition-select mt-0",
                                          attrs: {
                                            "hide-details": "auto",
                                            dense: "",
                                            height: "100%",
                                            "full-width": "",
                                            value: "e",
                                            "menu-props": {
                                              "content-class":
                                                "condition-select-menu",
                                              bottom: true,
                                              "offset-y": true
                                            },
                                            "hide-selected": "",
                                            items: _vm.operators.text
                                          },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "selection",
                                                fn: function(ref) {
                                                  var item = ref.item
                                                  return [
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "white--text body-2 text-center"
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(item.text)
                                                        )
                                                      ]
                                                    )
                                                  ]
                                                }
                                              },
                                              {
                                                key: "item",
                                                fn: function(ref) {
                                                  var item = ref.item
                                                  return [
                                                    _c(
                                                      "span",
                                                      { staticClass: "body-2" },
                                                      [
                                                        _vm._v(
                                                          _vm._s(item.text)
                                                        )
                                                      ]
                                                    )
                                                  ]
                                                }
                                              },
                                              {
                                                key: "append-outer",
                                                fn: function() {
                                                  return [
                                                    _c(
                                                      "v-tooltip",
                                                      {
                                                        attrs: { bottom: "" },
                                                        scopedSlots: _vm._u(
                                                          [
                                                            {
                                                              key: "activator",
                                                              fn: function(
                                                                ref
                                                              ) {
                                                                var on = ref.on
                                                                var attrs =
                                                                  ref.attrs
                                                                return [
                                                                  _c(
                                                                    "v-btn",
                                                                    _vm._g(
                                                                      _vm._b(
                                                                        {
                                                                          staticClass:
                                                                            "mt-n1",
                                                                          attrs: {
                                                                            small:
                                                                              "",
                                                                            color:
                                                                              "info",
                                                                            icon:
                                                                              ""
                                                                          }
                                                                        },
                                                                        "v-btn",
                                                                        attrs,
                                                                        false
                                                                      ),
                                                                      on
                                                                    ),
                                                                    [
                                                                      _c(
                                                                        "v-icon",
                                                                        [
                                                                          _vm._v(
                                                                            " mdi-help-circle-outline "
                                                                          )
                                                                        ]
                                                                      )
                                                                    ],
                                                                    1
                                                                  )
                                                                ]
                                                              }
                                                            }
                                                          ],
                                                          null,
                                                          true
                                                        )
                                                      },
                                                      [
                                                        _vm._v(" "),
                                                        _c("span", [
                                                          _vm._v(
                                                            "% als Platzhalter verwenden"
                                                          )
                                                        ])
                                                      ]
                                                    )
                                                  ]
                                                },
                                                proxy: true
                                              }
                                            ],
                                            null,
                                            true
                                          ),
                                          model: {
                                            value: condition.operator,
                                            callback: function($$v) {
                                              _vm.$set(
                                                condition,
                                                "operator",
                                                $$v
                                              )
                                            },
                                            expression: "condition.operator"
                                          }
                                        })
                                      ],
                                      1
                                    ),
                                _vm._v(" "),
                                [
                                  condition.data_type == "date"
                                    ? _c(
                                        "v-col",
                                        [
                                          _c(
                                            "v-menu",
                                            {
                                              ref: "menu",
                                              refInFor: true,
                                              attrs: {
                                                "close-on-content-click": false,
                                                transition: "scale-transition",
                                                "offset-y": "",
                                                "min-width": "auto"
                                              },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "activator",
                                                    fn: function(ref) {
                                                      var on = ref.on
                                                      var attrs = ref.attrs
                                                      return [
                                                        _c(
                                                          "v-text-field",
                                                          _vm._g(
                                                            _vm._b(
                                                              {
                                                                attrs: {
                                                                  "prepend-inner-icon":
                                                                    "mdi-calendar",
                                                                  readonly: "",
                                                                  filled: "",
                                                                  dense: "",
                                                                  disabled:
                                                                    condition.operator ===
                                                                      "null" ||
                                                                    condition.operator ===
                                                                      "not_null",
                                                                  "hide-details":
                                                                    "auto",
                                                                  value: _vm.$filters.formatDate(
                                                                    condition.value
                                                                  )
                                                                },
                                                                scopedSlots: _vm._u(
                                                                  [
                                                                    {
                                                                      key:
                                                                        "append-outer",
                                                                      fn: function() {
                                                                        return [
                                                                          _c(
                                                                            "v-btn",
                                                                            {
                                                                              staticClass:
                                                                                "mr-2",
                                                                              attrs: {
                                                                                "x-small":
                                                                                  "",
                                                                                color:
                                                                                  "primary",
                                                                                "min-width":
                                                                                  "20px",
                                                                                "max-width":
                                                                                  "20px"
                                                                              },
                                                                              on: {
                                                                                click: function(
                                                                                  $event
                                                                                ) {
                                                                                  return _vm.addCondition(
                                                                                    index,
                                                                                    "one"
                                                                                  )
                                                                                }
                                                                              }
                                                                            },
                                                                            [
                                                                              _c(
                                                                                "v-icon",
                                                                                [
                                                                                  _vm._v(
                                                                                    "mdi-plus"
                                                                                  )
                                                                                ]
                                                                              )
                                                                            ],
                                                                            1
                                                                          ),
                                                                          _vm._v(
                                                                            " "
                                                                          ),
                                                                          _c(
                                                                            "v-btn",
                                                                            {
                                                                              attrs: {
                                                                                "x-small":
                                                                                  "",
                                                                                color:
                                                                                  "error",
                                                                                "min-width":
                                                                                  "20px",
                                                                                "max-width":
                                                                                  "20px"
                                                                              },
                                                                              on: {
                                                                                click: function(
                                                                                  $event
                                                                                ) {
                                                                                  return _vm.removeCondition(
                                                                                    index,
                                                                                    "one"
                                                                                  )
                                                                                }
                                                                              }
                                                                            },
                                                                            [
                                                                              _c(
                                                                                "v-icon",
                                                                                [
                                                                                  _vm._v(
                                                                                    "mdi-close"
                                                                                  )
                                                                                ]
                                                                              )
                                                                            ],
                                                                            1
                                                                          )
                                                                        ]
                                                                      },
                                                                      proxy: true
                                                                    }
                                                                  ],
                                                                  null,
                                                                  true
                                                                )
                                                              },
                                                              "v-text-field",
                                                              attrs,
                                                              false
                                                            ),
                                                            on
                                                          )
                                                        )
                                                      ]
                                                    }
                                                  }
                                                ],
                                                null,
                                                true
                                              ),
                                              model: {
                                                value: condition.menu,
                                                callback: function($$v) {
                                                  _vm.$set(
                                                    condition,
                                                    "menu",
                                                    $$v
                                                  )
                                                },
                                                expression: "condition.menu"
                                              }
                                            },
                                            [
                                              _vm._v(" "),
                                              _c(
                                                "v-card",
                                                { attrs: { flat: "" } },
                                                [
                                                  _c(
                                                    "v-card-text",
                                                    [
                                                      _c("v-date-picker", {
                                                        attrs: {
                                                          color: "secondary",
                                                          "first-day-of-week":
                                                            "1",
                                                          landscape: "",
                                                          locale: "de-DE"
                                                        },
                                                        model: {
                                                          value:
                                                            condition.value,
                                                          callback: function(
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              condition,
                                                              "value",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "condition.value"
                                                        }
                                                      })
                                                    ],
                                                    1
                                                  )
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    : _c(
                                        "v-col",
                                        [
                                          _c("v-text-field", {
                                            attrs: {
                                              dense: "",
                                              filled: "",
                                              "hide-details": "auto",
                                              disabled:
                                                condition.operator === "null" ||
                                                condition.operator ===
                                                  "not_null",
                                              required: ""
                                            },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "append-outer",
                                                  fn: function() {
                                                    return [
                                                      _c(
                                                        "v-btn",
                                                        {
                                                          staticClass: "mr-2",
                                                          attrs: {
                                                            "x-small": "",
                                                            color: "primary",
                                                            "min-width": "20px",
                                                            "max-width": "20px"
                                                          },
                                                          on: {
                                                            click: function(
                                                              $event
                                                            ) {
                                                              return _vm.addCondition(
                                                                index,
                                                                "one"
                                                              )
                                                            }
                                                          }
                                                        },
                                                        [
                                                          _c("v-icon", [
                                                            _vm._v("mdi-plus")
                                                          ])
                                                        ],
                                                        1
                                                      ),
                                                      _vm._v(" "),
                                                      _c(
                                                        "v-btn",
                                                        {
                                                          attrs: {
                                                            "x-small": "",
                                                            color: "error",
                                                            "min-width": "20px",
                                                            "max-width": "20px"
                                                          },
                                                          on: {
                                                            click: function(
                                                              $event
                                                            ) {
                                                              return _vm.removeCondition(
                                                                index,
                                                                "one"
                                                              )
                                                            }
                                                          }
                                                        },
                                                        [
                                                          _c("v-icon", [
                                                            _vm._v("mdi-close")
                                                          ])
                                                        ],
                                                        1
                                                      )
                                                    ]
                                                  },
                                                  proxy: true
                                                }
                                              ],
                                              null,
                                              true
                                            ),
                                            model: {
                                              value: condition.value,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  condition,
                                                  "value",
                                                  $$v
                                                )
                                              },
                                              expression: "condition.value"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                ]
                              ],
                              2
                            )
                          })
                        ],
                        2
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-row",
                { staticClass: "ma-0 mt-4" },
                [
                  _c(
                    "v-btn",
                    {
                      attrs: { text: "" },
                      on: {
                        click: function($event) {
                          _vm.step = 2
                        }
                      }
                    },
                    [_vm._v(" Zurück ")]
                  ),
                  _vm._v(" "),
                  _c("v-spacer"),
                  _vm._v(" "),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "primary" },
                      on: { click: _vm.checkBedingungen }
                    },
                    [_vm._v("\n          Bericht erstellen\n        ")]
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-stepper-content",
            { attrs: { step: "4" } },
            [
              _vm.step === 4
                ? _c("bericht-table", {
                    attrs: {
                      headers: _vm.sortedFields,
                      items: _vm.items,
                      "primary-module": _vm.primaryModule,
                      "group-by": _vm.groupBy,
                      calculations: _vm.calculations
                    }
                  })
                : _vm._e(),
              _vm._v(" "),
              _c(
                "v-row",
                { staticClass: "ma-0 mt-4" },
                [
                  _c(
                    "v-btn",
                    {
                      attrs: { text: "" },
                      on: {
                        click: function($event) {
                          _vm.step = 3
                        }
                      }
                    },
                    [_vm._v(" Zurück ")]
                  ),
                  _vm._v(" "),
                  _c("v-spacer")
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-overlay",
        {
          attrs: { absolute: "" },
          model: {
            value: _vm.loading,
            callback: function($$v) {
              _vm.loading = $$v
            },
            expression: "loading"
          }
        },
        [
          _c("v-progress-circular", {
            attrs: { indeterminate: "", size: "64" }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }