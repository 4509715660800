var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-row",
    [
      _c(
        "v-col",
        { attrs: { cols: "12" } },
        [
          _c(
            "v-row",
            [
              _c(
                "v-col",
                { attrs: { cols: "6" } },
                [
                  _c("v-text-field", {
                    attrs: {
                      filled: "",
                      dense: "",
                      "hide-details": "auto",
                      label: "Berechtigung suchen"
                    },
                    model: {
                      value: _vm.search,
                      callback: function($$v) {
                        _vm.search = $$v
                      },
                      expression: "search"
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-col",
        { attrs: { cols: "12", lg: "6" } },
        [
          _c("h4", { staticClass: "mb-4" }, [
            _vm._v("Verfügbare Berechtigungen")
          ]),
          _vm._v(" "),
          _c(
            "v-list",
            { attrs: { dense: "", elevation: "2" } },
            [
              _c(
                "v-list-item-group",
                [
                  _c(
                    "draggable",
                    {
                      staticClass: "draggable-wrapper",
                      attrs: { list: _vm.filteredItems, group: "fields" }
                    },
                    _vm._l(_vm.filteredItems, function(element, index) {
                      return _c(
                        "v-list-item",
                        { key: element.id },
                        [
                          _c(
                            "v-list-item-content",
                            [
                              _c("v-list-item-title", {
                                domProps: {
                                  textContent: _vm._s("" + element.name)
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    }),
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-col",
        { attrs: { cols: "4", lg: "6" } },
        [
          _c("h4", { staticClass: "mb-4" }, [
            _vm._v("Ausgewählte Berechtigungen")
          ]),
          _vm._v(" "),
          _c(
            "v-list",
            { attrs: { dense: "", elevation: "2" } },
            [
              _c(
                "v-list-item-group",
                [
                  _c(
                    "draggable",
                    {
                      staticClass: "draggable-wrapper",
                      attrs: { list: _vm.sortedSelected, group: "fields" }
                    },
                    _vm._l(_vm.sortedSelected, function(element) {
                      return _c(
                        "v-list-item",
                        { key: element.id },
                        [
                          _c(
                            "v-list-item-content",
                            [
                              _c("v-list-item-title", {
                                domProps: {
                                  textContent: _vm._s("" + element.name)
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    }),
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }