var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-form",
    {
      ref: "fieldForm",
      on: {
        submit: function($event) {
          $event.preventDefault()
          return _vm.savePermission()
        }
      },
      model: {
        value: _vm.valid,
        callback: function($$v) {
          _vm.valid = $$v
        },
        expression: "valid"
      }
    },
    [
      _c("h2", { staticClass: "primary--text :hover" }, [
        _vm._v("Berechtigungs-Daten")
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "profile-grid" },
        [
          _c("div", { staticClass: "profile-item my-1" }, [
            _c("div", { staticClass: "profile-title pa-2" }, [
              _vm._v("Berechtigungs-Option")
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "profile-divider" }),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "profile-value py-2" },
              [
                _c("v-select", {
                  attrs: {
                    filled: "",
                    dense: "",
                    "hide-details": "auto",
                    rules: _vm.inputRules,
                    items: _vm.permissionOptions
                  },
                  model: {
                    value: _vm.newPermission.option,
                    callback: function($$v) {
                      _vm.$set(_vm.newPermission, "option", $$v)
                    },
                    expression: "newPermission.option"
                  }
                })
              ],
              1
            )
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "profile-item my-1" }, [
            _c("div", { staticClass: "profile-title pa-2" }, [
              _vm._v("Berechtigungs-Typ")
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "profile-divider" }),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "profile-value py-2" },
              [
                _c("v-select", {
                  attrs: {
                    filled: "",
                    dense: "",
                    "hide-details": "auto",
                    rules: _vm.inputRules,
                    items: _vm.permissionTypes
                  },
                  model: {
                    value: _vm.newPermission.type,
                    callback: function($$v) {
                      _vm.$set(_vm.newPermission, "type", $$v)
                    },
                    expression: "newPermission.type"
                  }
                })
              ],
              1
            )
          ]),
          _vm._v(" "),
          _vm.newPermission.type === "module"
            ? _c("div", { staticClass: "profile-item my-1" }, [
                _c("div", { staticClass: "profile-title pa-2" }, [
                  _vm._v("Modul")
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "profile-divider" }),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "profile-value py-2" },
                  [
                    _c("v-autocomplete", {
                      attrs: {
                        filled: "",
                        dense: "",
                        "hide-details": "auto",
                        rules: _vm.inputRules,
                        items: _vm.modules,
                        "item-value": "field_name",
                        "item-text": "field_name"
                      },
                      model: {
                        value: _vm.newPermission.field,
                        callback: function($$v) {
                          _vm.$set(_vm.newPermission, "field", $$v)
                        },
                        expression: "newPermission.field"
                      }
                    })
                  ],
                  1
                )
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.newPermission.type === "field"
            ? [
                _c("div", { staticClass: "profile-item my-1" }, [
                  _c("div", { staticClass: "profile-title pa-2" }, [
                    _vm._v("Modul")
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "profile-divider" }),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "profile-value py-2" },
                    [
                      _c("v-autocomplete", {
                        attrs: {
                          filled: "",
                          dense: "",
                          "hide-details": "auto",
                          items: _vm.modules,
                          "item-value": "id",
                          "item-text": "field_name"
                        },
                        model: {
                          value: _vm.newPermission.module,
                          callback: function($$v) {
                            _vm.$set(_vm.newPermission, "module", $$v)
                          },
                          expression: "newPermission.module"
                        }
                      })
                    ],
                    1
                  )
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "profile-item my-1" }, [
                  _c("div", { staticClass: "profile-title pa-2" }, [
                    _vm._v("Feld")
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "profile-divider" }),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "profile-value py-2" },
                    [
                      _c("v-autocomplete", {
                        attrs: {
                          filled: "",
                          dense: "",
                          "hide-details": "auto",
                          rules: _vm.inputRules,
                          items: _vm.computedFields,
                          "item-value": "field_name",
                          "item-text": "field_name"
                        },
                        model: {
                          value: _vm.newPermission.field,
                          callback: function($$v) {
                            _vm.$set(_vm.newPermission, "field", $$v)
                          },
                          expression: "newPermission.field"
                        }
                      })
                    ],
                    1
                  )
                ])
              ]
            : _vm._e(),
          _vm._v(" "),
          _c("div", { staticClass: "profile-item my-1" }, [
            _c("div", { staticClass: "profile-title pa-2" }, [
              _vm._v("Berechtigung zu Rollen zuweisen")
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "profile-divider" }),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "profile-value py-2" },
              [
                _c("v-autocomplete", {
                  attrs: {
                    filled: "",
                    dense: "",
                    multiple: "",
                    items: _vm.roles,
                    "hide-details": "auto",
                    "item-value": "id",
                    "item-text": "name"
                  },
                  model: {
                    value: _vm.selectedRoles,
                    callback: function($$v) {
                      _vm.selectedRoles = $$v
                    },
                    expression: "selectedRoles"
                  }
                })
              ],
              1
            )
          ])
        ],
        2
      ),
      _vm._v(" "),
      _c(
        "v-row",
        { staticClass: "mx-0 mt-4" },
        [
          _c("v-btn", { attrs: { color: "primary", type: "submit" } }, [
            _vm._v("Feld speichern")
          ])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }