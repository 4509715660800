var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "px-0" },
    [
      _c(
        "v-form",
        {
          ref: "filterForm",
          model: {
            value: _vm.valid,
            callback: function($$v) {
              _vm.valid = $$v
            },
            expression: "valid"
          }
        },
        [
          _c(
            "v-row",
            [
              _c(
                "v-col",
                { attrs: { cols: "12", md: "6" } },
                [
                  _c(
                    "v-row",
                    { staticClass: "ma-0" },
                    [
                      _c("v-autocomplete", {
                        attrs: {
                          dense: "",
                          filled: "",
                          clearable: "",
                          "hide-details": "auto",
                          label: "Modul",
                          items: _vm.modules,
                          "item-value": "name",
                          "item-text": "name"
                        },
                        model: {
                          value: _vm.module,
                          callback: function($$v) {
                            _vm.module = $$v
                          },
                          expression: "module"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c("v-spacer"),
              _vm._v(" "),
              _c(
                "v-col",
                { attrs: { cols: "12", md: "6" } },
                [
                  _c(
                    "v-row",
                    { staticClass: "ma-0" },
                    [
                      _c("v-autocomplete", {
                        attrs: {
                          dense: "",
                          filled: "",
                          clearable: "",
                          "hide-details": "auto",
                          label: "Einstellungen laden von:",
                          items: _vm.filters,
                          "item-value": "id"
                        },
                        model: {
                          value: _vm.currentFilter,
                          callback: function($$v) {
                            _vm.currentFilter = $$v
                          },
                          expression: "currentFilter"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-row",
            [
              _c(
                "v-col",
                { attrs: { cols: "12", md: "6" } },
                [
                  _c("h3", { staticClass: "primary--text mb-4" }, [
                    _vm._v("alle Bedingungen treffen zu:")
                  ]),
                  _vm._v(" "),
                  _vm.conditionAll.length == 0
                    ? _c(
                        "v-row",
                        {
                          staticClass: "py-4",
                          attrs: { justify: "center", align: "center" }
                        },
                        [
                          _c(
                            "v-btn",
                            {
                              staticClass: "mr-2",
                              attrs: {
                                "x-small": "",
                                color: "primary",
                                "min-width": "20px",
                                "max-width": "20px"
                              },
                              on: {
                                click: function($event) {
                                  return _vm.addCondition(0, "all")
                                }
                              }
                            },
                            [_c("v-icon", [_vm._v("mdi-plus")])],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-btn",
                            {
                              attrs: {
                                "x-small": "",
                                color: "error",
                                "min-width": "20px",
                                "max-width": "20px"
                              },
                              on: {
                                click: function($event) {
                                  return _vm.removeCondition(0, "all")
                                }
                              }
                            },
                            [_c("v-icon", [_vm._v("mdi-close")])],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm._l(_vm.conditionAll, function(condition, index) {
                    return _c(
                      "v-row",
                      { key: index },
                      [
                        _c(
                          "v-col",
                          [
                            _c("v-autocomplete", {
                              attrs: {
                                dense: "",
                                filled: "",
                                clearable: "",
                                "hide-details": "auto",
                                rules: _vm.validationRules,
                                items: _vm.fields,
                                "item-value": "id",
                                "item-text": function(item) {
                                  return (
                                    item.portal_field_category.name +
                                    ": " +
                                    item.field_name
                                  )
                                },
                                required: ""
                              },
                              on: {
                                change: function($event) {
                                  return _vm.setDataType(condition)
                                }
                              },
                              model: {
                                value: condition.field,
                                callback: function($$v) {
                                  _vm.$set(condition, "field", $$v)
                                },
                                expression: "condition.field"
                              }
                            })
                          ],
                          1
                        ),
                        _vm._v(" "),
                        condition.data_type !== "text"
                          ? _c(
                              "v-col",
                              { attrs: { cols: "2" } },
                              [
                                _c("v-select", {
                                  staticClass: "condition-select mt-0",
                                  attrs: {
                                    "hide-details": "auto",
                                    dense: "",
                                    height: "100%",
                                    "full-width": "",
                                    value: "e",
                                    "menu-props": {
                                      "content-class": "condition-select-menu",
                                      bottom: true,
                                      "offset-y": true
                                    },
                                    "hide-selected": "",
                                    items: _vm.operators.number
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "selection",
                                        fn: function(ref) {
                                          var item = ref.item
                                          return [
                                            item.hasOwnProperty("icon")
                                              ? _c(
                                                  "v-icon",
                                                  { attrs: { color: "white" } },
                                                  [_vm._v(_vm._s(item.icon))]
                                                )
                                              : _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "white--text body-2 text-center"
                                                  },
                                                  [_vm._v(_vm._s(item.text))]
                                                )
                                          ]
                                        }
                                      },
                                      {
                                        key: "item",
                                        fn: function(ref) {
                                          var item = ref.item
                                          return [
                                            item.hasOwnProperty("icon")
                                              ? _c("v-icon", [
                                                  _vm._v(_vm._s(item.icon))
                                                ])
                                              : _c(
                                                  "span",
                                                  { staticClass: "body-2" },
                                                  [_vm._v(_vm._s(item.text))]
                                                )
                                          ]
                                        }
                                      }
                                    ],
                                    null,
                                    true
                                  ),
                                  model: {
                                    value: condition.operator,
                                    callback: function($$v) {
                                      _vm.$set(condition, "operator", $$v)
                                    },
                                    expression: "condition.operator"
                                  }
                                })
                              ],
                              1
                            )
                          : _c(
                              "v-col",
                              { attrs: { cols: "2" } },
                              [
                                _c("v-select", {
                                  staticClass: "condition-select mt-0",
                                  attrs: {
                                    "hide-details": "auto",
                                    dense: "",
                                    height: "100%",
                                    "full-width": "",
                                    value: "e",
                                    "menu-props": {
                                      "content-class": "condition-select-menu",
                                      bottom: true,
                                      "offset-y": true
                                    },
                                    "hide-selected": "",
                                    items: _vm.operators.text
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "selection",
                                        fn: function(ref) {
                                          var item = ref.item
                                          return [
                                            _c(
                                              "span",
                                              {
                                                staticClass:
                                                  "white--text body-2 text-center"
                                              },
                                              [_vm._v(_vm._s(item.text))]
                                            )
                                          ]
                                        }
                                      },
                                      {
                                        key: "item",
                                        fn: function(ref) {
                                          var item = ref.item
                                          return [
                                            _c(
                                              "span",
                                              { staticClass: "body-2" },
                                              [_vm._v(_vm._s(item.text))]
                                            )
                                          ]
                                        }
                                      },
                                      {
                                        key: "append-outer",
                                        fn: function() {
                                          return [
                                            _c(
                                              "v-tooltip",
                                              {
                                                attrs: { bottom: "" },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "activator",
                                                      fn: function(ref) {
                                                        var on = ref.on
                                                        var attrs = ref.attrs
                                                        return [
                                                          _c(
                                                            "v-btn",
                                                            _vm._g(
                                                              _vm._b(
                                                                {
                                                                  staticClass:
                                                                    "mt-n1",
                                                                  attrs: {
                                                                    small: "",
                                                                    color:
                                                                      "info",
                                                                    icon: ""
                                                                  }
                                                                },
                                                                "v-btn",
                                                                attrs,
                                                                false
                                                              ),
                                                              on
                                                            ),
                                                            [
                                                              _c("v-icon", [
                                                                _vm._v(
                                                                  " mdi-help-circle-outline "
                                                                )
                                                              ])
                                                            ],
                                                            1
                                                          )
                                                        ]
                                                      }
                                                    }
                                                  ],
                                                  null,
                                                  true
                                                )
                                              },
                                              [
                                                _vm._v(" "),
                                                _c("span", [
                                                  _vm._v(
                                                    "% als Platzhalter verwenden"
                                                  )
                                                ])
                                              ]
                                            )
                                          ]
                                        },
                                        proxy: true
                                      }
                                    ],
                                    null,
                                    true
                                  ),
                                  model: {
                                    value: condition.operator,
                                    callback: function($$v) {
                                      _vm.$set(condition, "operator", $$v)
                                    },
                                    expression: "condition.operator"
                                  }
                                })
                              ],
                              1
                            ),
                        _vm._v(" "),
                        [
                          condition.data_type == "date"
                            ? _c(
                                "v-col",
                                [
                                  _c(
                                    "v-menu",
                                    {
                                      ref: "menu",
                                      refInFor: true,
                                      attrs: {
                                        "close-on-content-click": false,
                                        transition: "scale-transition",
                                        "offset-y": "",
                                        "min-width": "auto"
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "activator",
                                            fn: function(ref) {
                                              var on = ref.on
                                              var attrs = ref.attrs
                                              return [
                                                _c(
                                                  "v-text-field",
                                                  _vm._g(
                                                    _vm._b(
                                                      {
                                                        attrs: {
                                                          "prepend-inner-icon":
                                                            "mdi-calendar",
                                                          readonly: "",
                                                          filled: "",
                                                          dense: "",
                                                          "hide-details":
                                                            "auto",
                                                          disabled:
                                                            condition.operator ===
                                                              "null" ||
                                                            condition.operator ===
                                                              "not_null",
                                                          value: _vm.$filters.formatDate(
                                                            condition.value
                                                          )
                                                        },
                                                        scopedSlots: _vm._u(
                                                          [
                                                            {
                                                              key:
                                                                "append-outer",
                                                              fn: function() {
                                                                return [
                                                                  _c(
                                                                    "v-btn",
                                                                    {
                                                                      staticClass:
                                                                        "mr-2",
                                                                      attrs: {
                                                                        "x-small":
                                                                          "",
                                                                        color:
                                                                          "primary",
                                                                        "min-width":
                                                                          "20px",
                                                                        "max-width":
                                                                          "20px"
                                                                      },
                                                                      on: {
                                                                        click: function(
                                                                          $event
                                                                        ) {
                                                                          return _vm.addCondition(
                                                                            index,
                                                                            "all"
                                                                          )
                                                                        }
                                                                      }
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "v-icon",
                                                                        [
                                                                          _vm._v(
                                                                            "mdi-plus"
                                                                          )
                                                                        ]
                                                                      )
                                                                    ],
                                                                    1
                                                                  ),
                                                                  _vm._v(" "),
                                                                  _c(
                                                                    "v-btn",
                                                                    {
                                                                      attrs: {
                                                                        "x-small":
                                                                          "",
                                                                        color:
                                                                          "error",
                                                                        "min-width":
                                                                          "20px",
                                                                        "max-width":
                                                                          "20px"
                                                                      },
                                                                      on: {
                                                                        click: function(
                                                                          $event
                                                                        ) {
                                                                          return _vm.removeCondition(
                                                                            index,
                                                                            "all"
                                                                          )
                                                                        }
                                                                      }
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "v-icon",
                                                                        [
                                                                          _vm._v(
                                                                            "mdi-close"
                                                                          )
                                                                        ]
                                                                      )
                                                                    ],
                                                                    1
                                                                  )
                                                                ]
                                                              },
                                                              proxy: true
                                                            }
                                                          ],
                                                          null,
                                                          true
                                                        )
                                                      },
                                                      "v-text-field",
                                                      attrs,
                                                      false
                                                    ),
                                                    on
                                                  )
                                                )
                                              ]
                                            }
                                          }
                                        ],
                                        null,
                                        true
                                      ),
                                      model: {
                                        value: condition.menu,
                                        callback: function($$v) {
                                          _vm.$set(condition, "menu", $$v)
                                        },
                                        expression: "condition.menu"
                                      }
                                    },
                                    [
                                      _vm._v(" "),
                                      _c(
                                        "v-card",
                                        { attrs: { flat: "" } },
                                        [
                                          _c(
                                            "v-card-text",
                                            [
                                              _c("v-date-picker", {
                                                attrs: {
                                                  color: "secondary",
                                                  "first-day-of-week": "1",
                                                  landscape: "",
                                                  locale: "de-DE"
                                                },
                                                model: {
                                                  value: condition.value,
                                                  callback: function($$v) {
                                                    _vm.$set(
                                                      condition,
                                                      "value",
                                                      $$v
                                                    )
                                                  },
                                                  expression: "condition.value"
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            : _c(
                                "v-col",
                                [
                                  _c("v-text-field", {
                                    attrs: {
                                      dense: "",
                                      filled: "",
                                      "hide-details": "auto",
                                      disabled:
                                        condition.operator === "null" ||
                                        condition.operator === "not_null",
                                      required: ""
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "append-outer",
                                          fn: function() {
                                            return [
                                              _c(
                                                "v-btn",
                                                {
                                                  staticClass: "mr-2",
                                                  attrs: {
                                                    "x-small": "",
                                                    color: "primary",
                                                    "min-width": "20px",
                                                    "max-width": "20px"
                                                  },
                                                  on: {
                                                    click: function($event) {
                                                      return _vm.addCondition(
                                                        index,
                                                        "all"
                                                      )
                                                    }
                                                  }
                                                },
                                                [
                                                  _c("v-icon", [
                                                    _vm._v("mdi-plus")
                                                  ])
                                                ],
                                                1
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "v-btn",
                                                {
                                                  attrs: {
                                                    "x-small": "",
                                                    color: "error",
                                                    "min-width": "20px",
                                                    "max-width": "20px"
                                                  },
                                                  on: {
                                                    click: function($event) {
                                                      return _vm.removeCondition(
                                                        index,
                                                        "all"
                                                      )
                                                    }
                                                  }
                                                },
                                                [
                                                  _c("v-icon", [
                                                    _vm._v("mdi-close")
                                                  ])
                                                ],
                                                1
                                              )
                                            ]
                                          },
                                          proxy: true
                                        }
                                      ],
                                      null,
                                      true
                                    ),
                                    model: {
                                      value: condition.value,
                                      callback: function($$v) {
                                        _vm.$set(condition, "value", $$v)
                                      },
                                      expression: "condition.value"
                                    }
                                  })
                                ],
                                1
                              )
                        ]
                      ],
                      2
                    )
                  })
                ],
                2
              ),
              _vm._v(" "),
              _c(
                "v-col",
                { attrs: { cols: "12", md: "6" } },
                [
                  _c("h3", { staticClass: "primary--text mb-4" }, [
                    _vm._v(
                      "\n          mindestens eine Bedingung trifft zu:\n        "
                    )
                  ]),
                  _vm._v(" "),
                  _vm.conditionOne.length == 0
                    ? _c(
                        "v-row",
                        {
                          staticClass: "py-4",
                          attrs: { justify: "center", align: "center" }
                        },
                        [
                          _c(
                            "v-btn",
                            {
                              staticClass: "mr-2",
                              attrs: {
                                "x-small": "",
                                color: "primary",
                                "min-width": "20px",
                                "max-width": "20px"
                              },
                              on: {
                                click: function($event) {
                                  return _vm.addCondition(0, "one")
                                }
                              }
                            },
                            [_c("v-icon", [_vm._v("mdi-plus")])],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-btn",
                            {
                              attrs: {
                                "x-small": "",
                                color: "error",
                                "min-width": "20px",
                                "max-width": "20px"
                              },
                              on: {
                                click: function($event) {
                                  return _vm.removeCondition(0, "one")
                                }
                              }
                            },
                            [_c("v-icon", [_vm._v("mdi-close")])],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm._l(_vm.conditionOne, function(condition, index) {
                    return _c(
                      "v-row",
                      { key: index },
                      [
                        _c(
                          "v-col",
                          [
                            _c("v-autocomplete", {
                              attrs: {
                                dense: "",
                                filled: "",
                                clearable: "",
                                "hide-details": "auto",
                                rules: _vm.validationRules,
                                items: _vm.fields,
                                "item-value": "id",
                                "item-text": function(item) {
                                  return (
                                    item.portal_field_category.name +
                                    ": " +
                                    item.field_name
                                  )
                                },
                                required: ""
                              },
                              on: {
                                change: function($event) {
                                  return _vm.setDataType(condition)
                                }
                              },
                              model: {
                                value: condition.field,
                                callback: function($$v) {
                                  _vm.$set(condition, "field", $$v)
                                },
                                expression: "condition.field"
                              }
                            })
                          ],
                          1
                        ),
                        _vm._v(" "),
                        condition.data_type !== "text"
                          ? _c(
                              "v-col",
                              { attrs: { cols: "2" } },
                              [
                                _c("v-select", {
                                  staticClass: "condition-select mt-0",
                                  attrs: {
                                    "hide-details": "auto",
                                    dense: "",
                                    height: "100%",
                                    "full-width": "",
                                    value: "e",
                                    "menu-props": {
                                      "content-class": "condition-select-menu",
                                      bottom: true,
                                      "offset-y": true
                                    },
                                    "hide-selected": "",
                                    items: _vm.operators.number
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "selection",
                                        fn: function(ref) {
                                          var item = ref.item
                                          return [
                                            item.hasOwnProperty("icon")
                                              ? _c(
                                                  "v-icon",
                                                  { attrs: { color: "white" } },
                                                  [_vm._v(_vm._s(item.icon))]
                                                )
                                              : _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "white--text body-2 text-center"
                                                  },
                                                  [_vm._v(_vm._s(item.text))]
                                                )
                                          ]
                                        }
                                      },
                                      {
                                        key: "item",
                                        fn: function(ref) {
                                          var item = ref.item
                                          return [
                                            item.hasOwnProperty("icon")
                                              ? _c("v-icon", [
                                                  _vm._v(_vm._s(item.icon))
                                                ])
                                              : _c(
                                                  "span",
                                                  { staticClass: "body-2" },
                                                  [_vm._v(_vm._s(item.text))]
                                                )
                                          ]
                                        }
                                      }
                                    ],
                                    null,
                                    true
                                  ),
                                  model: {
                                    value: condition.operator,
                                    callback: function($$v) {
                                      _vm.$set(condition, "operator", $$v)
                                    },
                                    expression: "condition.operator"
                                  }
                                })
                              ],
                              1
                            )
                          : _c(
                              "v-col",
                              { attrs: { cols: "2" } },
                              [
                                _c("v-select", {
                                  staticClass: "condition-select mt-0",
                                  attrs: {
                                    "hide-details": "auto",
                                    dense: "",
                                    height: "100%",
                                    "full-width": "",
                                    value: "e",
                                    "menu-props": {
                                      "content-class": "condition-select-menu",
                                      bottom: true,
                                      "offset-y": true
                                    },
                                    "hide-selected": "",
                                    items: _vm.operators.text
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "selection",
                                        fn: function(ref) {
                                          var item = ref.item
                                          return [
                                            _c(
                                              "span",
                                              {
                                                staticClass:
                                                  "white--text body-2 text-center"
                                              },
                                              [_vm._v(_vm._s(item.text))]
                                            )
                                          ]
                                        }
                                      },
                                      {
                                        key: "item",
                                        fn: function(ref) {
                                          var item = ref.item
                                          return [
                                            _c(
                                              "span",
                                              { staticClass: "body-2" },
                                              [_vm._v(_vm._s(item.text))]
                                            )
                                          ]
                                        }
                                      },
                                      {
                                        key: "append-outer",
                                        fn: function() {
                                          return [
                                            _c(
                                              "v-tooltip",
                                              {
                                                attrs: { bottom: "" },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "activator",
                                                      fn: function(ref) {
                                                        var on = ref.on
                                                        var attrs = ref.attrs
                                                        return [
                                                          _c(
                                                            "v-btn",
                                                            _vm._g(
                                                              _vm._b(
                                                                {
                                                                  staticClass:
                                                                    "mt-n1",
                                                                  attrs: {
                                                                    small: "",
                                                                    color:
                                                                      "info",
                                                                    icon: ""
                                                                  }
                                                                },
                                                                "v-btn",
                                                                attrs,
                                                                false
                                                              ),
                                                              on
                                                            ),
                                                            [
                                                              _c("v-icon", [
                                                                _vm._v(
                                                                  " mdi-help-circle-outline "
                                                                )
                                                              ])
                                                            ],
                                                            1
                                                          )
                                                        ]
                                                      }
                                                    }
                                                  ],
                                                  null,
                                                  true
                                                )
                                              },
                                              [
                                                _vm._v(" "),
                                                _c("span", [
                                                  _vm._v(
                                                    "% als Platzhalter verwenden"
                                                  )
                                                ])
                                              ]
                                            )
                                          ]
                                        },
                                        proxy: true
                                      }
                                    ],
                                    null,
                                    true
                                  ),
                                  model: {
                                    value: condition.operator,
                                    callback: function($$v) {
                                      _vm.$set(condition, "operator", $$v)
                                    },
                                    expression: "condition.operator"
                                  }
                                })
                              ],
                              1
                            ),
                        _vm._v(" "),
                        [
                          condition.data_type == "date"
                            ? _c(
                                "v-col",
                                [
                                  _c(
                                    "v-menu",
                                    {
                                      ref: "menu",
                                      refInFor: true,
                                      attrs: {
                                        "close-on-content-click": false,
                                        transition: "scale-transition",
                                        "offset-y": "",
                                        "min-width": "auto"
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "activator",
                                            fn: function(ref) {
                                              var on = ref.on
                                              var attrs = ref.attrs
                                              return [
                                                _c(
                                                  "v-text-field",
                                                  _vm._g(
                                                    _vm._b(
                                                      {
                                                        attrs: {
                                                          "prepend-inner-icon":
                                                            "mdi-calendar",
                                                          readonly: "",
                                                          filled: "",
                                                          dense: "",
                                                          disabled:
                                                            condition.operator ===
                                                              "null" ||
                                                            condition.operator ===
                                                              "not_null",
                                                          "hide-details":
                                                            "auto",
                                                          value: _vm.$filters.formatDate(
                                                            condition.value
                                                          )
                                                        },
                                                        scopedSlots: _vm._u(
                                                          [
                                                            {
                                                              key:
                                                                "append-outer",
                                                              fn: function() {
                                                                return [
                                                                  _c(
                                                                    "v-btn",
                                                                    {
                                                                      staticClass:
                                                                        "mr-2",
                                                                      attrs: {
                                                                        "x-small":
                                                                          "",
                                                                        color:
                                                                          "primary",
                                                                        "min-width":
                                                                          "20px",
                                                                        "max-width":
                                                                          "20px"
                                                                      },
                                                                      on: {
                                                                        click: function(
                                                                          $event
                                                                        ) {
                                                                          return _vm.addCondition(
                                                                            index,
                                                                            "one"
                                                                          )
                                                                        }
                                                                      }
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "v-icon",
                                                                        [
                                                                          _vm._v(
                                                                            "mdi-plus"
                                                                          )
                                                                        ]
                                                                      )
                                                                    ],
                                                                    1
                                                                  ),
                                                                  _vm._v(" "),
                                                                  _c(
                                                                    "v-btn",
                                                                    {
                                                                      attrs: {
                                                                        "x-small":
                                                                          "",
                                                                        color:
                                                                          "error",
                                                                        "min-width":
                                                                          "20px",
                                                                        "max-width":
                                                                          "20px"
                                                                      },
                                                                      on: {
                                                                        click: function(
                                                                          $event
                                                                        ) {
                                                                          return _vm.removeCondition(
                                                                            index,
                                                                            "one"
                                                                          )
                                                                        }
                                                                      }
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "v-icon",
                                                                        [
                                                                          _vm._v(
                                                                            "mdi-close"
                                                                          )
                                                                        ]
                                                                      )
                                                                    ],
                                                                    1
                                                                  )
                                                                ]
                                                              },
                                                              proxy: true
                                                            }
                                                          ],
                                                          null,
                                                          true
                                                        )
                                                      },
                                                      "v-text-field",
                                                      attrs,
                                                      false
                                                    ),
                                                    on
                                                  )
                                                )
                                              ]
                                            }
                                          }
                                        ],
                                        null,
                                        true
                                      ),
                                      model: {
                                        value: condition.menu,
                                        callback: function($$v) {
                                          _vm.$set(condition, "menu", $$v)
                                        },
                                        expression: "condition.menu"
                                      }
                                    },
                                    [
                                      _vm._v(" "),
                                      _c(
                                        "v-card",
                                        { attrs: { flat: "" } },
                                        [
                                          _c(
                                            "v-card-text",
                                            [
                                              _c("v-date-picker", {
                                                attrs: {
                                                  color: "secondary",
                                                  "first-day-of-week": "1",
                                                  landscape: "",
                                                  locale: "de-DE"
                                                },
                                                model: {
                                                  value: condition.value,
                                                  callback: function($$v) {
                                                    _vm.$set(
                                                      condition,
                                                      "value",
                                                      $$v
                                                    )
                                                  },
                                                  expression: "condition.value"
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            : _c(
                                "v-col",
                                [
                                  _c("v-text-field", {
                                    attrs: {
                                      dense: "",
                                      filled: "",
                                      "hide-details": "auto",
                                      disabled:
                                        condition.operator === "null" ||
                                        condition.operator === "not_null",
                                      required: ""
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "append-outer",
                                          fn: function() {
                                            return [
                                              _c(
                                                "v-btn",
                                                {
                                                  staticClass: "mr-2",
                                                  attrs: {
                                                    "x-small": "",
                                                    color: "primary",
                                                    "min-width": "20px",
                                                    "max-width": "20px"
                                                  },
                                                  on: {
                                                    click: function($event) {
                                                      return _vm.addCondition(
                                                        index,
                                                        "one"
                                                      )
                                                    }
                                                  }
                                                },
                                                [
                                                  _c("v-icon", [
                                                    _vm._v("mdi-plus")
                                                  ])
                                                ],
                                                1
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "v-btn",
                                                {
                                                  attrs: {
                                                    "x-small": "",
                                                    color: "error",
                                                    "min-width": "20px",
                                                    "max-width": "20px"
                                                  },
                                                  on: {
                                                    click: function($event) {
                                                      return _vm.removeCondition(
                                                        index,
                                                        "one"
                                                      )
                                                    }
                                                  }
                                                },
                                                [
                                                  _c("v-icon", [
                                                    _vm._v("mdi-close")
                                                  ])
                                                ],
                                                1
                                              )
                                            ]
                                          },
                                          proxy: true
                                        }
                                      ],
                                      null,
                                      true
                                    ),
                                    model: {
                                      value: condition.value,
                                      callback: function($$v) {
                                        _vm.$set(condition, "value", $$v)
                                      },
                                      expression: "condition.value"
                                    }
                                  })
                                ],
                                1
                              )
                        ]
                      ],
                      2
                    )
                  })
                ],
                2
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c("table-field-draggable", { attrs: { module: _vm.module } }),
      _vm._v(" "),
      _c(
        "v-row",
        [
          _c("v-spacer"),
          _vm._v(" "),
          _c(
            "v-btn",
            { attrs: { color: "primary" }, on: { click: _vm.saveFilter } },
            [_vm._v("Filter speichern")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }