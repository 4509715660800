var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-row",
    [
      _c(
        "v-col",
        [
          _c("h3", { staticClass: "primary--text my-4" }, [
            _vm._v("verfügbare Tabellenfelder")
          ]),
          _vm._v(" "),
          _c(
            "v-expansion-panels",
            {
              attrs: { flat: "", accordion: "" },
              model: {
                value: _vm.active,
                callback: function($$v) {
                  _vm.active = $$v
                },
                expression: "active"
              }
            },
            _vm._l(_vm.categories, function(category) {
              return _c(
                "v-expansion-panel",
                {
                  key: category.id,
                  attrs: {
                    "active-class": "active",
                    disabled: category.name !== _vm.module && _vm.disabled
                  }
                },
                [
                  _c(
                    "v-expansion-panel-header",
                    {
                      staticClass:
                        "px-0 text-subtitle-2 font-weight-bold secondary--text"
                    },
                    [_vm._v(_vm._s(category.name))]
                  ),
                  _vm._v(" "),
                  _c(
                    "v-expansion-panel-content",
                    [
                      _c("v-divider", { staticClass: "mb-4" }),
                      _vm._v(" "),
                      _c("field-draggable", {
                        attrs: { fields: _vm.getCategoryFields(category.id) }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            }),
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-col",
        [
          _c("h3", { staticClass: "primary--text my-4" }, [
            _vm._v("angezeigte Tabellenfelder")
          ]),
          _vm._v(" "),
          _c(
            "v-chip-group",
            {
              staticStyle: { "min-height": "150px", "min-width": "50%" },
              attrs: { column: "" }
            },
            [
              _c(
                "draggable",
                _vm._b(
                  {
                    on: {
                      start: function($event) {
                        _vm.drag = true
                      },
                      end: function($event) {
                        _vm.drag = false
                      }
                    },
                    model: {
                      value: _vm.selectedPortalFields,
                      callback: function($$v) {
                        _vm.selectedPortalFields = $$v
                      },
                      expression: "selectedPortalFields"
                    }
                  },
                  "draggable",
                  _vm.dragOptions,
                  false
                ),
                _vm._l(_vm.selectedPortalFields, function(element, index) {
                  return _c(
                    "v-chip",
                    {
                      key: index,
                      attrs: { close: "", small: "", color: element.color },
                      on: {
                        "click:close": function($event) {
                          return _vm.removeSelected(index, element)
                        }
                      }
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(element.field_name) +
                          "\n        "
                      )
                    ]
                  )
                }),
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }