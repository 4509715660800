import { render, staticRenderFns } from "./NewsComponent.vue?vue&type=template&id=468d0b08&scoped=true&"
import script from "./NewsComponent.vue?vue&type=script&lang=js&"
export * from "./NewsComponent.vue?vue&type=script&lang=js&"
import style0 from "./NewsComponent.vue?vue&type=style&index=0&id=468d0b08&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "468d0b08",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/var/www/html/game-portal/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('468d0b08')) {
      api.createRecord('468d0b08', component.options)
    } else {
      api.reload('468d0b08', component.options)
    }
    module.hot.accept("./NewsComponent.vue?vue&type=template&id=468d0b08&scoped=true&", function () {
      api.rerender('468d0b08', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "resources/js/admin/NewsComponent.vue"
export default component.exports