var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-row",
    [
      _c(
        "v-col",
        { attrs: { cols: "12", lg: "3" } },
        [
          _c("h3", { staticClass: "mb-4" }, [_vm._v("Verfügbare Felder")]),
          _vm._v(" "),
          _c(
            "v-list",
            { attrs: { dense: "", elevation: "2" } },
            [
              _c(
                "v-list-item-group",
                [
                  _c(
                    "draggable",
                    {
                      staticClass: "draggable-wrapper",
                      attrs: { list: _vm.fields, group: "fields" }
                    },
                    _vm._l(_vm.fields, function(element, index) {
                      return _c(
                        "v-list-item",
                        { key: element.id },
                        [
                          _c(
                            "v-list-item-content",
                            [
                              _c("v-list-item-title", {
                                domProps: {
                                  textContent: _vm._s(element.field_name)
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    }),
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _vm._l(3, function(i, index) {
        return _c(
          "v-col",
          { key: i, attrs: { cols: "4", lg: "3" } },
          [
            _c("h3", { staticClass: "mb-4" }, [_vm._v("Spalte " + _vm._s(i))]),
            _vm._v(" "),
            _c(
              "v-list",
              { attrs: { dense: "", elevation: "2" } },
              [
                _c(
                  "v-list-item-group",
                  [
                    _c(
                      "draggable",
                      {
                        staticClass: "draggable-wrapper",
                        attrs: {
                          list: _vm.cloneColumns[index].portal_fields,
                          group: "fields"
                        }
                      },
                      _vm._l(_vm.cloneColumns[index].portal_fields, function(
                        element
                      ) {
                        return _c(
                          "v-list-item",
                          { key: element.id },
                          [
                            _c(
                              "v-list-item-content",
                              [
                                _c("v-list-item-title", {
                                  domProps: {
                                    textContent: _vm._s(element.field_name)
                                  }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        )
                      }),
                      1
                    )
                  ],
                  1
                )
              ],
              1
            )
          ],
          1
        )
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }