var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-row",
    { staticClass: "mx-0 mt-4", attrs: { align: "center" } },
    [
      _c("v-chip", { attrs: { label: "" } }, [
        _vm._v(
          "Zeige " +
            _vm._s(_vm.pagination.pageStart + 1) +
            "-" +
            _vm._s(_vm.pagination.pageStop) +
            " von\n    " +
            _vm._s(_vm.pagination.itemsLength)
        )
      ]),
      _vm._v(" "),
      _c("v-spacer"),
      _vm._v(" "),
      _c("div", [_vm._v("\n    " + _vm._s(_vm.itemsPerPageText) + "\n  ")]),
      _vm._v(" "),
      _c(
        "v-col",
        { attrs: { cols: "2", lg: "1" } },
        [
          _c("v-select", {
            attrs: {
              filled: "",
              dense: "",
              "hide-details": "",
              value: _vm.tableOptions.itemsPerPage,
              items: _vm.itemsPerPageOptions
            },
            on: {
              input: function($event) {
                _vm.tableOptions.itemsPerPage = parseInt($event, 10)
              }
            },
            scopedSlots: _vm._u([
              {
                key: "item",
                fn: function(ref) {
                  var item = ref.item
                  return [
                    _vm._v(
                      "\n        " +
                        _vm._s(item === -1 ? "Alle" : item) +
                        "\n      "
                    )
                  ]
                }
              },
              {
                key: "selection",
                fn: function(ref) {
                  var item = ref.item
                  return [
                    _vm._v(
                      "\n        " +
                        _vm._s(item === -1 ? "Alle" : item) +
                        "\n      "
                    )
                  ]
                }
              }
            ])
          })
        ],
        1
      ),
      _vm._v(" "),
      _c("v-pagination", {
        staticClass: "flex-grow-1 flex-lg-grow-0",
        attrs: {
          "total-visible": _vm.$vuetify.breakpoint.mdAndDown ? 12 : 9,
          length: _vm.pageCount
        },
        model: {
          value: _vm.tableOptions.page,
          callback: function($$v) {
            _vm.$set(_vm.tableOptions, "page", $$v)
          },
          expression: "tableOptions.page"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }