var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.locked
    ? _c(
        "v-form",
        {
          attrs: { method: "POST" },
          on: {
            submit: function($event) {
              $event.preventDefault()
              return _vm.unlockUser.apply(null, arguments)
            }
          }
        },
        [
          _c("v-btn", { attrs: { type: "submit", color: "primary" } }, [
            _vm._v("Benutzer entsperren")
          ])
        ],
        1
      )
    : _c(
        "v-form",
        {
          attrs: { method: "POST" },
          on: {
            submit: function($event) {
              $event.preventDefault()
              return _vm.lockUser.apply(null, arguments)
            }
          }
        },
        [
          _c("v-btn", { attrs: { type: "submit", color: "primary" } }, [
            _vm._v("Benutzer sperren")
          ])
        ],
        1
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }