var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-form",
    {
      ref: "fieldForm",
      attrs: { action: _vm.route("admin.fields.save") },
      on: {
        submit: function($event) {
          $event.preventDefault()
          return _vm.saveField()
        }
      },
      model: {
        value: _vm.valid,
        callback: function($$v) {
          _vm.valid = $$v
        },
        expression: "valid"
      }
    },
    [
      _c("h2", { staticClass: "primary--text :hover" }, [_vm._v("Feld-Daten")]),
      _vm._v(" "),
      _c("div", { staticClass: "profile-grid" }, [
        _c("div", { staticClass: "profile-item my-1" }, [
          _c("div", { staticClass: "profile-title pa-2" }, [
            _vm._v("Feld-Name")
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "profile-divider" }),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "profile-value py-2" },
            [
              _c("v-text-field", {
                attrs: {
                  filled: "",
                  dense: "",
                  "hide-details": "auto",
                  rules: _vm.inputRules
                },
                model: {
                  value: _vm.field.field_name,
                  callback: function($$v) {
                    _vm.$set(_vm.field, "field_name", $$v)
                  },
                  expression: "field.field_name"
                }
              })
            ],
            1
          )
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "profile-item my-1" }, [
          _c("div", { staticClass: "profile-title pa-2" }, [
            _vm._v("Feld-Beschreibung")
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "profile-divider" }),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "profile-value py-2" },
            [
              _c("v-text-field", {
                attrs: { filled: "", dense: "", "hide-details": "auto" },
                model: {
                  value: _vm.field.description,
                  callback: function($$v) {
                    _vm.$set(_vm.field, "description", $$v)
                  },
                  expression: "field.description"
                }
              })
            ],
            1
          )
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "profile-item my-1" }, [
          _c("div", { staticClass: "profile-title pa-2" }, [
            _vm._v("Datentyp")
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "profile-divider" }),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "profile-value py-2" },
            [
              _c("v-select", {
                attrs: {
                  filled: "",
                  dense: "",
                  "hide-details": "auto",
                  rules: _vm.inputRules,
                  items: _vm.dataType
                },
                model: {
                  value: _vm.field.data_type,
                  callback: function($$v) {
                    _vm.$set(_vm.field, "data_type", $$v)
                  },
                  expression: "field.data_type"
                }
              })
            ],
            1
          )
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "profile-item my-1" }, [
          _c("div", { staticClass: "profile-title pa-2" }, [
            _vm._v("Verlinkung Ziel:")
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "profile-divider" }),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "profile-value py-2" },
            [
              _c("v-select", {
                attrs: {
                  filled: "",
                  dense: "",
                  "hide-details": "auto",
                  clearable: "",
                  items: _vm.computedLinkCategories,
                  "item-value": "name",
                  "item-text": "name"
                },
                on: {
                  change: function($event) {
                    _vm.linkField = 0
                  }
                },
                model: {
                  value: _vm.module,
                  callback: function($$v) {
                    _vm.module = $$v
                  },
                  expression: "module"
                }
              })
            ],
            1
          )
        ]),
        _vm._v(" "),
        _vm.module
          ? _c("div", { staticClass: "profile-item my-1" }, [
              _c("div", { staticClass: "profile-title pa-2" }, [
                _vm._v("Verlinkung Suchfeld:")
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "profile-divider" }),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "profile-value py-2" },
                [
                  _c("v-autocomplete", {
                    attrs: {
                      filled: "",
                      dense: "",
                      "hide-details": "auto",
                      clearable: "",
                      items: _vm.computedPortalFields,
                      "item-value": "id",
                      "item-text": "field_name",
                      rules: _vm.inputRules
                    },
                    model: {
                      value: _vm.linkField,
                      callback: function($$v) {
                        _vm.linkField = $$v
                      },
                      expression: "linkField"
                    }
                  })
                ],
                1
              )
            ])
          : _vm._e(),
        _vm._v(" "),
        _c("div", { staticClass: "profile-item my-1" }, [
          _c("div", { staticClass: "profile-title pa-2" }, [
            _vm._v("Kategorie")
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "profile-divider" }),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "profile-value py-2" },
            [
              _c("v-select", {
                attrs: {
                  filled: "",
                  dense: "",
                  "hide-details": "auto",
                  "item-value": "id",
                  "item-text": "name",
                  items: _vm.categories
                },
                on: {
                  change: function($event) {
                    _vm.categoryId = $event
                  }
                },
                model: {
                  value: _vm.field.portal_field_category_id,
                  callback: function($$v) {
                    _vm.$set(_vm.field, "portal_field_category_id", $$v)
                  },
                  expression: "field.portal_field_category_id"
                }
              })
            ],
            1
          )
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "profile-item my-1" }, [
          _c("div", { staticClass: "profile-title pa-2" }, [
            _vm._v("API-Felder")
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "profile-divider" }),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "profile-value py-2" },
            [
              _c("v-autocomplete", {
                attrs: {
                  filled: "",
                  dense: "",
                  multiple: "",
                  items: _vm.filteredApiFields,
                  "hide-details": "auto",
                  "item-value": "id",
                  "item-text": "field_name"
                },
                model: {
                  value: _vm.selectedFields,
                  callback: function($$v) {
                    _vm.selectedFields = $$v
                  },
                  expression: "selectedFields"
                }
              })
            ],
            1
          )
        ])
      ]),
      _vm._v(" "),
      _c(
        "v-row",
        { staticClass: "mx-0 mt-4" },
        [
          _c("v-btn", { attrs: { color: "primary", type: "submit" } }, [
            _vm._v("Feld speichern")
          ])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }