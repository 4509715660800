var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.isBookmark
    ? _c(
        "v-btn",
        {
          attrs: { icon: "", color: "warning" },
          on: {
            click: function($event) {
              $event.stopPropagation()
              return _vm.removeBookmark()
            }
          }
        },
        [_c("v-icon", [_vm._v("mdi-bookmark-remove")])],
        1
      )
    : _c(
        "v-btn",
        {
          attrs: { icon: "" },
          on: {
            click: function($event) {
              $event.stopPropagation()
              return _vm.addBookmark()
            }
          }
        },
        [_c("v-icon", [_vm._v("mdi-bookmark-plus-outline")])],
        1
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }