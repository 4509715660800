var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.prev !== false
        ? _c(
            "v-btn",
            {
              attrs: {
                icon: "",
                href: _vm.route(
                  _vm.module.toLowerCase() + ".details",
                  _vm.$store.state.detailsPagination.items[_vm.prev]
                )
              },
              on: {
                click: function($event) {
                  $event.preventDefault()
                  return _vm.moveToPrev.apply(null, arguments)
                }
              }
            },
            [
              _c("v-icon", { attrs: { size: "40" } }, [
                _vm._v("mdi-chevron-left")
              ])
            ],
            1
          )
        : _c(
            "v-btn",
            { attrs: { icon: "", disabled: "" } },
            [
              _c("v-icon", { attrs: { size: "40" } }, [
                _vm._v("mdi-chevron-left")
              ])
            ],
            1
          ),
      _vm._v(" "),
      _vm.next !== false
        ? _c(
            "v-btn",
            {
              attrs: {
                icon: "",
                href: _vm.route(
                  _vm.module.toLowerCase() + ".details",
                  _vm.$store.state.detailsPagination.items[_vm.next]
                )
              },
              on: {
                click: function($event) {
                  $event.preventDefault()
                  return _vm.moveToNext.apply(null, arguments)
                }
              }
            },
            [
              _c("v-icon", { attrs: { size: "40" } }, [
                _vm._v("mdi-chevron-right")
              ])
            ],
            1
          )
        : _c(
            "v-btn",
            { attrs: { icon: "", disabled: "" } },
            [
              _c("v-icon", { attrs: { size: "40" } }, [
                _vm._v("mdi-chevron-right")
              ])
            ],
            1
          )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }