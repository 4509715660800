var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("v-autocomplete", {
    attrs: {
      dense: "",
      filled: "",
      clearable: "",
      multiple: "",
      "hide-details": "auto",
      label: "Rollen auswählen:",
      name: "roles",
      items: _vm.sortedRoles,
      "item-value": "name",
      "item-text": "name"
    },
    model: {
      value: _vm.selectedRoles,
      callback: function($$v) {
        _vm.selectedRoles = $$v
      },
      expression: "selectedRoles"
    }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }