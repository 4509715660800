var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-row",
    { staticClass: "mx-0" },
    [
      _c(
        "v-col",
        { staticStyle: { position: "relative" } },
        [
          _c(
            "v-form",
            {
              ref: "profileForm",
              attrs: { action: _vm.route("user.save") },
              on: {
                submit: function($event) {
                  $event.preventDefault()
                  return _vm.saveProfile()
                }
              },
              model: {
                value: _vm.valid,
                callback: function($$v) {
                  _vm.valid = $$v
                },
                expression: "valid"
              }
            },
            [
              _c("h2", { staticClass: "primary--text" }, [
                _vm._v("Benutzerdaten")
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "profile-grid" }, [
                _c("div", { staticClass: "profile-item my-1" }, [
                  _c("div", { staticClass: "profile-title pa-2" }, [
                    _vm._v("Name")
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "profile-divider" }),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "profile-value py-2" },
                    [
                      _c("v-text-field", {
                        attrs: {
                          filled: "",
                          dense: "",
                          "hide-details": "auto",
                          rules: _vm.inputRules
                        },
                        model: {
                          value: _vm.user.name,
                          callback: function($$v) {
                            _vm.$set(_vm.user, "name", $$v)
                          },
                          expression: "user.name"
                        }
                      })
                    ],
                    1
                  )
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "profile-item my-1" }, [
                  _c("div", { staticClass: "profile-title pa-2" }, [
                    _vm._v("E-Mail-Adresse")
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "profile-divider" }),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "profile-value py-2 d-flex align-center" },
                    [
                      _c("v-text-field", {
                        attrs: {
                          filled: "",
                          dense: "",
                          "hide-details": "auto",
                          disabled: ""
                        },
                        model: {
                          value: _vm.user.email,
                          callback: function($$v) {
                            _vm.$set(_vm.user, "email", $$v)
                          },
                          expression: "user.email"
                        }
                      }),
                      _vm._v(" "),
                      _c(
                        "v-btn",
                        {
                          staticClass: "ml-4",
                          attrs: { href: _vm.route("user.email") }
                        },
                        [_vm._v("E-Mail-Adresse ändern")]
                      )
                    ],
                    1
                  )
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "profile-item my-1" }, [
                  _c("div", { staticClass: "profile-title pa-2" }, [
                    _vm._v("Telefon")
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "profile-divider" }),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "profile-value py-2" },
                    [
                      _c("v-text-field", {
                        attrs: {
                          filled: "",
                          dense: "",
                          "hide-details": "auto",
                          rules: _vm.inputRules
                        },
                        model: {
                          value: _vm.user.phone,
                          callback: function($$v) {
                            _vm.$set(_vm.user, "phone", $$v)
                          },
                          expression: "user.phone"
                        }
                      })
                    ],
                    1
                  )
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "profile-item my-1" }, [
                  _c("div", { staticClass: "profile-title pa-2" }, [
                    _vm._v("Avatar")
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "profile-divider" }),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "profile-value py-2",
                      staticStyle: { "min-width": "50%" }
                    },
                    [
                      _c("v-file-input", {
                        staticStyle: { width: "100%" },
                        attrs: {
                          accept: "image/png, image/jpeg, image/webp",
                          rules: _vm.fileRules,
                          "show-size": "",
                          dense: "",
                          filled: "",
                          label: "Neuer Avatar",
                          "hide-details": "auto",
                          "prepend-inner-icon": "mdi-camera",
                          "prepend-icon": ""
                        },
                        model: {
                          value: _vm.newAvatar,
                          callback: function($$v) {
                            _vm.newAvatar = $$v
                          },
                          expression: "newAvatar"
                        }
                      })
                    ],
                    1
                  )
                ])
              ]),
              _vm._v(" "),
              _c(
                "v-row",
                { staticClass: "mx-0 mt-4" },
                [
                  _c("v-btn", { attrs: { color: "primary", type: "submit" } }, [
                    _vm._v("Profil speichern")
                  ])
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-col",
        { staticClass: "d-flex align-center" },
        [
          _c(
            "v-avatar",
            { staticClass: "mx-0", attrs: { size: "200" } },
            [_c("v-img", { attrs: { src: _vm.previewImage } })],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }