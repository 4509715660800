var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "transition",
        { attrs: { name: "slide" } },
        [
          _vm.show
            ? _c(
                "v-card",
                {
                  staticClass: "mr-8 pr-16 mb-8 filter-card",
                  attrs: { elevation: "2" }
                },
                [
                  _c(
                    "v-btn",
                    {
                      staticClass: "filter-card-fab",
                      attrs: {
                        fab: "",
                        absolute: "",
                        "x-large": "",
                        color: "primary"
                      }
                    },
                    [
                      _c("v-icon", { attrs: { size: "40" } }, [
                        _vm._v("mdi-filter-plus")
                      ])
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-card-text",
                    [
                      _c(
                        "v-form",
                        {
                          ref: "filterForm",
                          model: {
                            value: _vm.valid,
                            callback: function($$v) {
                              _vm.valid = $$v
                            },
                            expression: "valid"
                          }
                        },
                        [
                          _c(
                            "v-row",
                            [
                              _c(
                                "v-col",
                                { attrs: { cols: "12", md: "6" } },
                                [
                                  _c(
                                    "v-row",
                                    { staticClass: "ma-0" },
                                    [
                                      _c("v-text-field", {
                                        attrs: {
                                          loading: _vm.loadingName,
                                          filled: "",
                                          dense: "",
                                          rules: _vm.validationRules,
                                          "hide-details": "auto",
                                          label: "Filter Name",
                                          required: ""
                                        },
                                        on: {
                                          input: function($event) {
                                            _vm.isPublicEdit = false
                                          }
                                        },
                                        model: {
                                          value: _vm.filterName,
                                          callback: function($$v) {
                                            _vm.filterName = $$v
                                          },
                                          expression: "filterName"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-row",
                                    [
                                      _c(
                                        "div",
                                        { class: { "px-4": _vm.isAdmin } },
                                        [
                                          _vm.isAdmin
                                            ? _c("v-switch", {
                                                staticClass: "my-2 inputSwitch",
                                                attrs: {
                                                  dense: "",
                                                  inset: "",
                                                  label:
                                                    "Filter veröffentlichen? " +
                                                    (_vm.switchPublic
                                                      ? "Ja"
                                                      : "Nein"),
                                                  "hide-details": "auto"
                                                },
                                                model: {
                                                  value: _vm.switchPublic,
                                                  callback: function($$v) {
                                                    _vm.switchPublic = $$v
                                                  },
                                                  expression: "switchPublic"
                                                }
                                              })
                                            : _vm._e()
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        { staticClass: "px-4" },
                                        [
                                          _c("v-switch", {
                                            staticClass: "my-2 inputSwitch",
                                            attrs: {
                                              dense: "",
                                              inset: "",
                                              label:
                                                "Als Standard setzen? " +
                                                (_vm.switchDefault
                                                  ? "Ja"
                                                  : "Nein"),
                                              "hide-details": "auto"
                                            },
                                            model: {
                                              value: _vm.switchDefault,
                                              callback: function($$v) {
                                                _vm.switchDefault = $$v
                                              },
                                              expression: "switchDefault"
                                            }
                                          })
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c("v-spacer"),
                                      _vm._v(" "),
                                      _vm.isEdit
                                        ? _c(
                                            "div",
                                            { staticClass: "px-4" },
                                            [
                                              _c(
                                                "v-tooltip",
                                                {
                                                  attrs: { bottom: "" },
                                                  scopedSlots: _vm._u(
                                                    [
                                                      {
                                                        key: "activator",
                                                        fn: function(ref) {
                                                          var on = ref.on
                                                          var attrs = ref.attrs
                                                          return [
                                                            _c("v-hover", {
                                                              scopedSlots: _vm._u(
                                                                [
                                                                  {
                                                                    key:
                                                                      "default",
                                                                    fn: function(
                                                                      ref
                                                                    ) {
                                                                      var hover =
                                                                        ref.hover
                                                                      return [
                                                                        _c(
                                                                          "v-btn",
                                                                          _vm._g(
                                                                            _vm._b(
                                                                              {
                                                                                staticClass:
                                                                                  "mt-1",
                                                                                attrs: {
                                                                                  icon:
                                                                                    "",
                                                                                  color: hover
                                                                                    ? "error"
                                                                                    : "secondary"
                                                                                },
                                                                                on: {
                                                                                  click: function(
                                                                                    $event
                                                                                  ) {
                                                                                    _vm.showDeleteDialog = true
                                                                                  }
                                                                                }
                                                                              },
                                                                              "v-btn",
                                                                              attrs,
                                                                              false
                                                                            ),
                                                                            on
                                                                          ),
                                                                          [
                                                                            _c(
                                                                              "v-icon",
                                                                              [
                                                                                _vm._v(
                                                                                  " mdi-delete "
                                                                                )
                                                                              ]
                                                                            )
                                                                          ],
                                                                          1
                                                                        )
                                                                      ]
                                                                    }
                                                                  }
                                                                ],
                                                                null,
                                                                true
                                                              )
                                                            })
                                                          ]
                                                        }
                                                      }
                                                    ],
                                                    null,
                                                    false,
                                                    3687354332
                                                  )
                                                },
                                                [
                                                  _vm._v(" "),
                                                  _c("span", [
                                                    _vm._v(
                                                      "Geladenen Filter löschen"
                                                    )
                                                  ])
                                                ]
                                              )
                                            ],
                                            1
                                          )
                                        : _vm._e()
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c("v-spacer"),
                              _vm._v(" "),
                              _c(
                                "v-col",
                                { attrs: { cols: "12", md: "6" } },
                                [
                                  _c(
                                    "v-row",
                                    { staticClass: "ma-0" },
                                    [
                                      _c("v-autocomplete", {
                                        attrs: {
                                          dense: "",
                                          filled: "",
                                          clearable: "",
                                          "hide-details": "auto",
                                          label: "Einstellungen laden von:",
                                          items: _vm.filters,
                                          "item-value": "id"
                                        },
                                        on: { change: _vm.loadFilter },
                                        model: {
                                          value: _vm.currentFilter,
                                          callback: function($$v) {
                                            _vm.currentFilter = $$v
                                          },
                                          expression: "currentFilter"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-row",
                            [
                              _c(
                                "v-col",
                                { attrs: { cols: "12", md: "6" } },
                                [
                                  _c(
                                    "h3",
                                    { staticClass: "primary--text mb-4" },
                                    [_vm._v("alle Bedingungen treffen zu:")]
                                  ),
                                  _vm._v(" "),
                                  _vm.conditionAll.length == 0
                                    ? _c(
                                        "v-row",
                                        {
                                          staticClass: "py-4",
                                          attrs: {
                                            justify: "center",
                                            align: "center"
                                          }
                                        },
                                        [
                                          _c(
                                            "v-btn",
                                            {
                                              staticClass: "mr-2",
                                              attrs: {
                                                "x-small": "",
                                                color: "primary",
                                                "min-width": "20px",
                                                "max-width": "20px"
                                              },
                                              on: {
                                                click: function($event) {
                                                  return _vm.addCondition(
                                                    0,
                                                    "all"
                                                  )
                                                }
                                              }
                                            },
                                            [
                                              _c("v-icon", [_vm._v("mdi-plus")])
                                            ],
                                            1
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "v-btn",
                                            {
                                              attrs: {
                                                "x-small": "",
                                                color: "error",
                                                "min-width": "20px",
                                                "max-width": "20px"
                                              },
                                              on: {
                                                click: function($event) {
                                                  return _vm.removeCondition(
                                                    0,
                                                    "all"
                                                  )
                                                }
                                              }
                                            },
                                            [
                                              _c("v-icon", [
                                                _vm._v("mdi-close")
                                              ])
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _vm._l(_vm.conditionAll, function(
                                    condition,
                                    index
                                  ) {
                                    return _c(
                                      "v-row",
                                      { key: index },
                                      [
                                        _c(
                                          "v-col",
                                          [
                                            _c("v-autocomplete", {
                                              attrs: {
                                                dense: "",
                                                filled: "",
                                                clearable: "",
                                                "hide-details": "auto",
                                                rules: _vm.validationRules,
                                                items: _vm.fields,
                                                "item-value": "id",
                                                "item-text": function(item) {
                                                  return (
                                                    item.portal_field_category
                                                      .name +
                                                    ": " +
                                                    item.field_name
                                                  )
                                                },
                                                required: ""
                                              },
                                              on: {
                                                change: function($event) {
                                                  return _vm.setDataType(
                                                    condition
                                                  )
                                                }
                                              },
                                              model: {
                                                value: condition.field,
                                                callback: function($$v) {
                                                  _vm.$set(
                                                    condition,
                                                    "field",
                                                    $$v
                                                  )
                                                },
                                                expression: "condition.field"
                                              }
                                            })
                                          ],
                                          1
                                        ),
                                        _vm._v(" "),
                                        condition.data_type !== "text"
                                          ? _c(
                                              "v-col",
                                              { attrs: { cols: "2" } },
                                              [
                                                _c("v-select", {
                                                  staticClass:
                                                    "condition-select mt-0",
                                                  attrs: {
                                                    "hide-details": "auto",
                                                    dense: "",
                                                    height: "100%",
                                                    "full-width": "",
                                                    value: "e",
                                                    "menu-props": {
                                                      "content-class":
                                                        "condition-select-menu",
                                                      bottom: true,
                                                      "offset-y": true
                                                    },
                                                    "hide-selected": "",
                                                    items: _vm.operators.number
                                                  },
                                                  scopedSlots: _vm._u(
                                                    [
                                                      {
                                                        key: "selection",
                                                        fn: function(ref) {
                                                          var item = ref.item
                                                          return [
                                                            item.hasOwnProperty(
                                                              "icon"
                                                            )
                                                              ? _c(
                                                                  "v-icon",
                                                                  {
                                                                    attrs: {
                                                                      color:
                                                                        "white"
                                                                    }
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        item.icon
                                                                      )
                                                                    )
                                                                  ]
                                                                )
                                                              : _c(
                                                                  "span",
                                                                  {
                                                                    staticClass:
                                                                      "white--text body-2 text-center"
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        item.text
                                                                      )
                                                                    )
                                                                  ]
                                                                )
                                                          ]
                                                        }
                                                      },
                                                      {
                                                        key: "item",
                                                        fn: function(ref) {
                                                          var item = ref.item
                                                          return [
                                                            item.hasOwnProperty(
                                                              "icon"
                                                            )
                                                              ? _c("v-icon", [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      item.icon
                                                                    )
                                                                  )
                                                                ])
                                                              : _c(
                                                                  "span",
                                                                  {
                                                                    staticClass:
                                                                      "body-2"
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        item.text
                                                                      )
                                                                    )
                                                                  ]
                                                                )
                                                          ]
                                                        }
                                                      }
                                                    ],
                                                    null,
                                                    true
                                                  ),
                                                  model: {
                                                    value: condition.operator,
                                                    callback: function($$v) {
                                                      _vm.$set(
                                                        condition,
                                                        "operator",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "condition.operator"
                                                  }
                                                })
                                              ],
                                              1
                                            )
                                          : _c(
                                              "v-col",
                                              { attrs: { cols: "2" } },
                                              [
                                                _c("v-select", {
                                                  staticClass:
                                                    "condition-select mt-0",
                                                  attrs: {
                                                    "hide-details": "auto",
                                                    dense: "",
                                                    height: "100%",
                                                    "full-width": "",
                                                    value: "e",
                                                    "menu-props": {
                                                      "content-class":
                                                        "condition-select-menu",
                                                      bottom: true,
                                                      "offset-y": true
                                                    },
                                                    "hide-selected": "",
                                                    items: _vm.operators.text
                                                  },
                                                  scopedSlots: _vm._u(
                                                    [
                                                      {
                                                        key: "selection",
                                                        fn: function(ref) {
                                                          var item = ref.item
                                                          return [
                                                            _c(
                                                              "span",
                                                              {
                                                                staticClass:
                                                                  "white--text body-2 text-center"
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    item.text
                                                                  )
                                                                )
                                                              ]
                                                            )
                                                          ]
                                                        }
                                                      },
                                                      {
                                                        key: "item",
                                                        fn: function(ref) {
                                                          var item = ref.item
                                                          return [
                                                            _c(
                                                              "span",
                                                              {
                                                                staticClass:
                                                                  "body-2"
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    item.text
                                                                  )
                                                                )
                                                              ]
                                                            )
                                                          ]
                                                        }
                                                      },
                                                      {
                                                        key: "append-outer",
                                                        fn: function() {
                                                          return [
                                                            _c(
                                                              "v-tooltip",
                                                              {
                                                                attrs: {
                                                                  bottom: ""
                                                                },
                                                                scopedSlots: _vm._u(
                                                                  [
                                                                    {
                                                                      key:
                                                                        "activator",
                                                                      fn: function(
                                                                        ref
                                                                      ) {
                                                                        var on =
                                                                          ref.on
                                                                        var attrs =
                                                                          ref.attrs
                                                                        return [
                                                                          _c(
                                                                            "v-btn",
                                                                            _vm._g(
                                                                              _vm._b(
                                                                                {
                                                                                  staticClass:
                                                                                    "mt-n1",
                                                                                  attrs: {
                                                                                    small:
                                                                                      "",
                                                                                    color:
                                                                                      "info",
                                                                                    icon:
                                                                                      ""
                                                                                  }
                                                                                },
                                                                                "v-btn",
                                                                                attrs,
                                                                                false
                                                                              ),
                                                                              on
                                                                            ),
                                                                            [
                                                                              _c(
                                                                                "v-icon",
                                                                                [
                                                                                  _vm._v(
                                                                                    " mdi-help-circle-outline "
                                                                                  )
                                                                                ]
                                                                              )
                                                                            ],
                                                                            1
                                                                          )
                                                                        ]
                                                                      }
                                                                    }
                                                                  ],
                                                                  null,
                                                                  true
                                                                )
                                                              },
                                                              [
                                                                _vm._v(" "),
                                                                _c("span", [
                                                                  _vm._v(
                                                                    "% als Platzhalter verwenden"
                                                                  )
                                                                ])
                                                              ]
                                                            )
                                                          ]
                                                        },
                                                        proxy: true
                                                      }
                                                    ],
                                                    null,
                                                    true
                                                  ),
                                                  model: {
                                                    value: condition.operator,
                                                    callback: function($$v) {
                                                      _vm.$set(
                                                        condition,
                                                        "operator",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "condition.operator"
                                                  }
                                                })
                                              ],
                                              1
                                            ),
                                        _vm._v(" "),
                                        [
                                          condition.data_type == "date"
                                            ? _c(
                                                "v-col",
                                                [
                                                  _c(
                                                    "v-menu",
                                                    {
                                                      ref: "menu",
                                                      refInFor: true,
                                                      attrs: {
                                                        "close-on-content-click": false,
                                                        transition:
                                                          "scale-transition",
                                                        "offset-y": "",
                                                        "min-width": "auto"
                                                      },
                                                      scopedSlots: _vm._u(
                                                        [
                                                          {
                                                            key: "activator",
                                                            fn: function(ref) {
                                                              var on = ref.on
                                                              var attrs =
                                                                ref.attrs
                                                              return [
                                                                _c(
                                                                  "v-text-field",
                                                                  _vm._g(
                                                                    _vm._b(
                                                                      {
                                                                        attrs: {
                                                                          "prepend-inner-icon":
                                                                            "mdi-calendar",
                                                                          readonly:
                                                                            "",
                                                                          filled:
                                                                            "",
                                                                          dense:
                                                                            "",
                                                                          "hide-details":
                                                                            "auto",
                                                                          disabled:
                                                                            condition.operator ===
                                                                              "null" ||
                                                                            condition.operator ===
                                                                              "not_null",
                                                                          value: _vm.$filters.formatDate(
                                                                            condition.value
                                                                          )
                                                                        },
                                                                        scopedSlots: _vm._u(
                                                                          [
                                                                            {
                                                                              key:
                                                                                "append-outer",
                                                                              fn: function() {
                                                                                return [
                                                                                  _c(
                                                                                    "v-btn",
                                                                                    {
                                                                                      staticClass:
                                                                                        "mr-2",
                                                                                      attrs: {
                                                                                        "x-small":
                                                                                          "",
                                                                                        color:
                                                                                          "primary",
                                                                                        "min-width":
                                                                                          "20px",
                                                                                        "max-width":
                                                                                          "20px"
                                                                                      },
                                                                                      on: {
                                                                                        click: function(
                                                                                          $event
                                                                                        ) {
                                                                                          return _vm.addCondition(
                                                                                            index,
                                                                                            "all"
                                                                                          )
                                                                                        }
                                                                                      }
                                                                                    },
                                                                                    [
                                                                                      _c(
                                                                                        "v-icon",
                                                                                        [
                                                                                          _vm._v(
                                                                                            "mdi-plus"
                                                                                          )
                                                                                        ]
                                                                                      )
                                                                                    ],
                                                                                    1
                                                                                  ),
                                                                                  _vm._v(
                                                                                    " "
                                                                                  ),
                                                                                  _c(
                                                                                    "v-btn",
                                                                                    {
                                                                                      attrs: {
                                                                                        "x-small":
                                                                                          "",
                                                                                        color:
                                                                                          "error",
                                                                                        "min-width":
                                                                                          "20px",
                                                                                        "max-width":
                                                                                          "20px"
                                                                                      },
                                                                                      on: {
                                                                                        click: function(
                                                                                          $event
                                                                                        ) {
                                                                                          return _vm.removeCondition(
                                                                                            index,
                                                                                            "all"
                                                                                          )
                                                                                        }
                                                                                      }
                                                                                    },
                                                                                    [
                                                                                      _c(
                                                                                        "v-icon",
                                                                                        [
                                                                                          _vm._v(
                                                                                            "mdi-close"
                                                                                          )
                                                                                        ]
                                                                                      )
                                                                                    ],
                                                                                    1
                                                                                  )
                                                                                ]
                                                                              },
                                                                              proxy: true
                                                                            }
                                                                          ],
                                                                          null,
                                                                          true
                                                                        )
                                                                      },
                                                                      "v-text-field",
                                                                      attrs,
                                                                      false
                                                                    ),
                                                                    on
                                                                  )
                                                                )
                                                              ]
                                                            }
                                                          }
                                                        ],
                                                        null,
                                                        true
                                                      ),
                                                      model: {
                                                        value: condition.menu,
                                                        callback: function(
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            condition,
                                                            "menu",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "condition.menu"
                                                      }
                                                    },
                                                    [
                                                      _vm._v(" "),
                                                      _c(
                                                        "v-card",
                                                        { attrs: { flat: "" } },
                                                        [
                                                          _c(
                                                            "v-card-text",
                                                            [
                                                              _c(
                                                                "v-date-picker",
                                                                {
                                                                  attrs: {
                                                                    color:
                                                                      "secondary",
                                                                    "first-day-of-week":
                                                                      "1",
                                                                    landscape:
                                                                      "",
                                                                    locale:
                                                                      "de-DE"
                                                                  },
                                                                  model: {
                                                                    value:
                                                                      condition.value,
                                                                    callback: function(
                                                                      $$v
                                                                    ) {
                                                                      _vm.$set(
                                                                        condition,
                                                                        "value",
                                                                        $$v
                                                                      )
                                                                    },
                                                                    expression:
                                                                      "condition.value"
                                                                  }
                                                                }
                                                              )
                                                            ],
                                                            1
                                                          )
                                                        ],
                                                        1
                                                      )
                                                    ],
                                                    1
                                                  )
                                                ],
                                                1
                                              )
                                            : _c(
                                                "v-col",
                                                [
                                                  _c("v-text-field", {
                                                    attrs: {
                                                      dense: "",
                                                      filled: "",
                                                      "hide-details": "auto",
                                                      disabled:
                                                        condition.operator ===
                                                          "null" ||
                                                        condition.operator ===
                                                          "not_null",
                                                      required: ""
                                                    },
                                                    scopedSlots: _vm._u(
                                                      [
                                                        {
                                                          key: "append-outer",
                                                          fn: function() {
                                                            return [
                                                              _c(
                                                                "v-btn",
                                                                {
                                                                  staticClass:
                                                                    "mr-2",
                                                                  attrs: {
                                                                    "x-small":
                                                                      "",
                                                                    color:
                                                                      "primary",
                                                                    "min-width":
                                                                      "20px",
                                                                    "max-width":
                                                                      "20px"
                                                                  },
                                                                  on: {
                                                                    click: function(
                                                                      $event
                                                                    ) {
                                                                      return _vm.addCondition(
                                                                        index,
                                                                        "all"
                                                                      )
                                                                    }
                                                                  }
                                                                },
                                                                [
                                                                  _c("v-icon", [
                                                                    _vm._v(
                                                                      "mdi-plus"
                                                                    )
                                                                  ])
                                                                ],
                                                                1
                                                              ),
                                                              _vm._v(" "),
                                                              _c(
                                                                "v-btn",
                                                                {
                                                                  attrs: {
                                                                    "x-small":
                                                                      "",
                                                                    color:
                                                                      "error",
                                                                    "min-width":
                                                                      "20px",
                                                                    "max-width":
                                                                      "20px"
                                                                  },
                                                                  on: {
                                                                    click: function(
                                                                      $event
                                                                    ) {
                                                                      return _vm.removeCondition(
                                                                        index,
                                                                        "all"
                                                                      )
                                                                    }
                                                                  }
                                                                },
                                                                [
                                                                  _c("v-icon", [
                                                                    _vm._v(
                                                                      "mdi-close"
                                                                    )
                                                                  ])
                                                                ],
                                                                1
                                                              )
                                                            ]
                                                          },
                                                          proxy: true
                                                        }
                                                      ],
                                                      null,
                                                      true
                                                    ),
                                                    model: {
                                                      value: condition.value,
                                                      callback: function($$v) {
                                                        _vm.$set(
                                                          condition,
                                                          "value",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "condition.value"
                                                    }
                                                  })
                                                ],
                                                1
                                              )
                                        ]
                                      ],
                                      2
                                    )
                                  })
                                ],
                                2
                              ),
                              _vm._v(" "),
                              _c(
                                "v-col",
                                { attrs: { cols: "12", md: "6" } },
                                [
                                  _c(
                                    "h3",
                                    { staticClass: "primary--text mb-4" },
                                    [
                                      _vm._v(
                                        "\n                mindestens eine Bedingung trifft zu:\n              "
                                      )
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _vm.conditionOne.length == 0
                                    ? _c(
                                        "v-row",
                                        {
                                          staticClass: "py-4",
                                          attrs: {
                                            justify: "center",
                                            align: "center"
                                          }
                                        },
                                        [
                                          _c(
                                            "v-btn",
                                            {
                                              staticClass: "mr-2",
                                              attrs: {
                                                "x-small": "",
                                                color: "primary",
                                                "min-width": "20px",
                                                "max-width": "20px"
                                              },
                                              on: {
                                                click: function($event) {
                                                  return _vm.addCondition(
                                                    0,
                                                    "one"
                                                  )
                                                }
                                              }
                                            },
                                            [
                                              _c("v-icon", [_vm._v("mdi-plus")])
                                            ],
                                            1
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "v-btn",
                                            {
                                              attrs: {
                                                "x-small": "",
                                                color: "error",
                                                "min-width": "20px",
                                                "max-width": "20px"
                                              },
                                              on: {
                                                click: function($event) {
                                                  return _vm.removeCondition(
                                                    0,
                                                    "one"
                                                  )
                                                }
                                              }
                                            },
                                            [
                                              _c("v-icon", [
                                                _vm._v("mdi-close")
                                              ])
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _vm._l(_vm.conditionOne, function(
                                    condition,
                                    index
                                  ) {
                                    return _c(
                                      "v-row",
                                      { key: index },
                                      [
                                        _c(
                                          "v-col",
                                          [
                                            _c("v-autocomplete", {
                                              attrs: {
                                                dense: "",
                                                filled: "",
                                                clearable: "",
                                                "hide-details": "auto",
                                                rules: _vm.validationRules,
                                                items: _vm.fields,
                                                "item-value": "id",
                                                "item-text": function(item) {
                                                  return (
                                                    item.portal_field_category
                                                      .name +
                                                    ": " +
                                                    item.field_name
                                                  )
                                                },
                                                required: ""
                                              },
                                              on: {
                                                change: function($event) {
                                                  return _vm.setDataType(
                                                    condition
                                                  )
                                                }
                                              },
                                              model: {
                                                value: condition.field,
                                                callback: function($$v) {
                                                  _vm.$set(
                                                    condition,
                                                    "field",
                                                    $$v
                                                  )
                                                },
                                                expression: "condition.field"
                                              }
                                            })
                                          ],
                                          1
                                        ),
                                        _vm._v(" "),
                                        condition.data_type !== "text"
                                          ? _c(
                                              "v-col",
                                              { attrs: { cols: "2" } },
                                              [
                                                _c("v-select", {
                                                  staticClass:
                                                    "condition-select mt-0",
                                                  attrs: {
                                                    "hide-details": "auto",
                                                    dense: "",
                                                    height: "100%",
                                                    "full-width": "",
                                                    value: "e",
                                                    "menu-props": {
                                                      "content-class":
                                                        "condition-select-menu",
                                                      bottom: true,
                                                      "offset-y": true
                                                    },
                                                    "hide-selected": "",
                                                    items: _vm.operators.number
                                                  },
                                                  scopedSlots: _vm._u(
                                                    [
                                                      {
                                                        key: "selection",
                                                        fn: function(ref) {
                                                          var item = ref.item
                                                          return [
                                                            item.hasOwnProperty(
                                                              "icon"
                                                            )
                                                              ? _c(
                                                                  "v-icon",
                                                                  {
                                                                    attrs: {
                                                                      color:
                                                                        "white"
                                                                    }
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        item.icon
                                                                      )
                                                                    )
                                                                  ]
                                                                )
                                                              : _c(
                                                                  "span",
                                                                  {
                                                                    staticClass:
                                                                      "white--text body-2 text-center"
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        item.text
                                                                      )
                                                                    )
                                                                  ]
                                                                )
                                                          ]
                                                        }
                                                      },
                                                      {
                                                        key: "item",
                                                        fn: function(ref) {
                                                          var item = ref.item
                                                          return [
                                                            item.hasOwnProperty(
                                                              "icon"
                                                            )
                                                              ? _c("v-icon", [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      item.icon
                                                                    )
                                                                  )
                                                                ])
                                                              : _c(
                                                                  "span",
                                                                  {
                                                                    staticClass:
                                                                      "body-2"
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        item.text
                                                                      )
                                                                    )
                                                                  ]
                                                                )
                                                          ]
                                                        }
                                                      }
                                                    ],
                                                    null,
                                                    true
                                                  ),
                                                  model: {
                                                    value: condition.operator,
                                                    callback: function($$v) {
                                                      _vm.$set(
                                                        condition,
                                                        "operator",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "condition.operator"
                                                  }
                                                })
                                              ],
                                              1
                                            )
                                          : _c(
                                              "v-col",
                                              { attrs: { cols: "2" } },
                                              [
                                                _c("v-select", {
                                                  staticClass:
                                                    "condition-select mt-0",
                                                  attrs: {
                                                    "hide-details": "auto",
                                                    dense: "",
                                                    height: "100%",
                                                    "full-width": "",
                                                    value: "e",
                                                    "menu-props": {
                                                      "content-class":
                                                        "condition-select-menu",
                                                      bottom: true,
                                                      "offset-y": true
                                                    },
                                                    "hide-selected": "",
                                                    items: _vm.operators.text
                                                  },
                                                  scopedSlots: _vm._u(
                                                    [
                                                      {
                                                        key: "selection",
                                                        fn: function(ref) {
                                                          var item = ref.item
                                                          return [
                                                            _c(
                                                              "span",
                                                              {
                                                                staticClass:
                                                                  "white--text body-2 text-center"
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    item.text
                                                                  )
                                                                )
                                                              ]
                                                            )
                                                          ]
                                                        }
                                                      },
                                                      {
                                                        key: "item",
                                                        fn: function(ref) {
                                                          var item = ref.item
                                                          return [
                                                            _c(
                                                              "span",
                                                              {
                                                                staticClass:
                                                                  "body-2"
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    item.text
                                                                  )
                                                                )
                                                              ]
                                                            )
                                                          ]
                                                        }
                                                      },
                                                      {
                                                        key: "append-outer",
                                                        fn: function() {
                                                          return [
                                                            _c(
                                                              "v-tooltip",
                                                              {
                                                                attrs: {
                                                                  bottom: ""
                                                                },
                                                                scopedSlots: _vm._u(
                                                                  [
                                                                    {
                                                                      key:
                                                                        "activator",
                                                                      fn: function(
                                                                        ref
                                                                      ) {
                                                                        var on =
                                                                          ref.on
                                                                        var attrs =
                                                                          ref.attrs
                                                                        return [
                                                                          _c(
                                                                            "v-btn",
                                                                            _vm._g(
                                                                              _vm._b(
                                                                                {
                                                                                  staticClass:
                                                                                    "mt-n1",
                                                                                  attrs: {
                                                                                    small:
                                                                                      "",
                                                                                    color:
                                                                                      "info",
                                                                                    icon:
                                                                                      ""
                                                                                  }
                                                                                },
                                                                                "v-btn",
                                                                                attrs,
                                                                                false
                                                                              ),
                                                                              on
                                                                            ),
                                                                            [
                                                                              _c(
                                                                                "v-icon",
                                                                                [
                                                                                  _vm._v(
                                                                                    " mdi-help-circle-outline "
                                                                                  )
                                                                                ]
                                                                              )
                                                                            ],
                                                                            1
                                                                          )
                                                                        ]
                                                                      }
                                                                    }
                                                                  ],
                                                                  null,
                                                                  true
                                                                )
                                                              },
                                                              [
                                                                _vm._v(" "),
                                                                _c("span", [
                                                                  _vm._v(
                                                                    "% als Platzhalter verwenden"
                                                                  )
                                                                ])
                                                              ]
                                                            )
                                                          ]
                                                        },
                                                        proxy: true
                                                      }
                                                    ],
                                                    null,
                                                    true
                                                  ),
                                                  model: {
                                                    value: condition.operator,
                                                    callback: function($$v) {
                                                      _vm.$set(
                                                        condition,
                                                        "operator",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "condition.operator"
                                                  }
                                                })
                                              ],
                                              1
                                            ),
                                        _vm._v(" "),
                                        [
                                          condition.data_type == "date"
                                            ? _c(
                                                "v-col",
                                                [
                                                  _c(
                                                    "v-menu",
                                                    {
                                                      ref: "menu",
                                                      refInFor: true,
                                                      attrs: {
                                                        "close-on-content-click": false,
                                                        transition:
                                                          "scale-transition",
                                                        "offset-y": "",
                                                        "min-width": "auto"
                                                      },
                                                      scopedSlots: _vm._u(
                                                        [
                                                          {
                                                            key: "activator",
                                                            fn: function(ref) {
                                                              var on = ref.on
                                                              var attrs =
                                                                ref.attrs
                                                              return [
                                                                _c(
                                                                  "v-text-field",
                                                                  _vm._g(
                                                                    _vm._b(
                                                                      {
                                                                        attrs: {
                                                                          "prepend-inner-icon":
                                                                            "mdi-calendar",
                                                                          readonly:
                                                                            "",
                                                                          filled:
                                                                            "",
                                                                          dense:
                                                                            "",
                                                                          disabled:
                                                                            condition.operator ===
                                                                              "null" ||
                                                                            condition.operator ===
                                                                              "not_null",
                                                                          "hide-details":
                                                                            "auto",
                                                                          value: _vm.$filters.formatDate(
                                                                            condition.value
                                                                          )
                                                                        },
                                                                        scopedSlots: _vm._u(
                                                                          [
                                                                            {
                                                                              key:
                                                                                "append-outer",
                                                                              fn: function() {
                                                                                return [
                                                                                  _c(
                                                                                    "v-btn",
                                                                                    {
                                                                                      staticClass:
                                                                                        "mr-2",
                                                                                      attrs: {
                                                                                        "x-small":
                                                                                          "",
                                                                                        color:
                                                                                          "primary",
                                                                                        "min-width":
                                                                                          "20px",
                                                                                        "max-width":
                                                                                          "20px"
                                                                                      },
                                                                                      on: {
                                                                                        click: function(
                                                                                          $event
                                                                                        ) {
                                                                                          return _vm.addCondition(
                                                                                            index,
                                                                                            "one"
                                                                                          )
                                                                                        }
                                                                                      }
                                                                                    },
                                                                                    [
                                                                                      _c(
                                                                                        "v-icon",
                                                                                        [
                                                                                          _vm._v(
                                                                                            "mdi-plus"
                                                                                          )
                                                                                        ]
                                                                                      )
                                                                                    ],
                                                                                    1
                                                                                  ),
                                                                                  _vm._v(
                                                                                    " "
                                                                                  ),
                                                                                  _c(
                                                                                    "v-btn",
                                                                                    {
                                                                                      attrs: {
                                                                                        "x-small":
                                                                                          "",
                                                                                        color:
                                                                                          "error",
                                                                                        "min-width":
                                                                                          "20px",
                                                                                        "max-width":
                                                                                          "20px"
                                                                                      },
                                                                                      on: {
                                                                                        click: function(
                                                                                          $event
                                                                                        ) {
                                                                                          return _vm.removeCondition(
                                                                                            index,
                                                                                            "one"
                                                                                          )
                                                                                        }
                                                                                      }
                                                                                    },
                                                                                    [
                                                                                      _c(
                                                                                        "v-icon",
                                                                                        [
                                                                                          _vm._v(
                                                                                            "mdi-close"
                                                                                          )
                                                                                        ]
                                                                                      )
                                                                                    ],
                                                                                    1
                                                                                  )
                                                                                ]
                                                                              },
                                                                              proxy: true
                                                                            }
                                                                          ],
                                                                          null,
                                                                          true
                                                                        )
                                                                      },
                                                                      "v-text-field",
                                                                      attrs,
                                                                      false
                                                                    ),
                                                                    on
                                                                  )
                                                                )
                                                              ]
                                                            }
                                                          }
                                                        ],
                                                        null,
                                                        true
                                                      ),
                                                      model: {
                                                        value: condition.menu,
                                                        callback: function(
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            condition,
                                                            "menu",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "condition.menu"
                                                      }
                                                    },
                                                    [
                                                      _vm._v(" "),
                                                      _c(
                                                        "v-card",
                                                        { attrs: { flat: "" } },
                                                        [
                                                          _c(
                                                            "v-card-text",
                                                            [
                                                              _c(
                                                                "v-date-picker",
                                                                {
                                                                  attrs: {
                                                                    color:
                                                                      "secondary",
                                                                    "first-day-of-week":
                                                                      "1",
                                                                    landscape:
                                                                      "",
                                                                    locale:
                                                                      "de-DE"
                                                                  },
                                                                  model: {
                                                                    value:
                                                                      condition.value,
                                                                    callback: function(
                                                                      $$v
                                                                    ) {
                                                                      _vm.$set(
                                                                        condition,
                                                                        "value",
                                                                        $$v
                                                                      )
                                                                    },
                                                                    expression:
                                                                      "condition.value"
                                                                  }
                                                                }
                                                              )
                                                            ],
                                                            1
                                                          )
                                                        ],
                                                        1
                                                      )
                                                    ],
                                                    1
                                                  )
                                                ],
                                                1
                                              )
                                            : _c(
                                                "v-col",
                                                [
                                                  _c("v-text-field", {
                                                    attrs: {
                                                      dense: "",
                                                      filled: "",
                                                      "hide-details": "auto",
                                                      disabled:
                                                        condition.operator ===
                                                          "null" ||
                                                        condition.operator ===
                                                          "not_null",
                                                      required: ""
                                                    },
                                                    scopedSlots: _vm._u(
                                                      [
                                                        {
                                                          key: "append-outer",
                                                          fn: function() {
                                                            return [
                                                              _c(
                                                                "v-btn",
                                                                {
                                                                  staticClass:
                                                                    "mr-2",
                                                                  attrs: {
                                                                    "x-small":
                                                                      "",
                                                                    color:
                                                                      "primary",
                                                                    "min-width":
                                                                      "20px",
                                                                    "max-width":
                                                                      "20px"
                                                                  },
                                                                  on: {
                                                                    click: function(
                                                                      $event
                                                                    ) {
                                                                      return _vm.addCondition(
                                                                        index,
                                                                        "one"
                                                                      )
                                                                    }
                                                                  }
                                                                },
                                                                [
                                                                  _c("v-icon", [
                                                                    _vm._v(
                                                                      "mdi-plus"
                                                                    )
                                                                  ])
                                                                ],
                                                                1
                                                              ),
                                                              _vm._v(" "),
                                                              _c(
                                                                "v-btn",
                                                                {
                                                                  attrs: {
                                                                    "x-small":
                                                                      "",
                                                                    color:
                                                                      "error",
                                                                    "min-width":
                                                                      "20px",
                                                                    "max-width":
                                                                      "20px"
                                                                  },
                                                                  on: {
                                                                    click: function(
                                                                      $event
                                                                    ) {
                                                                      return _vm.removeCondition(
                                                                        index,
                                                                        "one"
                                                                      )
                                                                    }
                                                                  }
                                                                },
                                                                [
                                                                  _c("v-icon", [
                                                                    _vm._v(
                                                                      "mdi-close"
                                                                    )
                                                                  ])
                                                                ],
                                                                1
                                                              )
                                                            ]
                                                          },
                                                          proxy: true
                                                        }
                                                      ],
                                                      null,
                                                      true
                                                    ),
                                                    model: {
                                                      value: condition.value,
                                                      callback: function($$v) {
                                                        _vm.$set(
                                                          condition,
                                                          "value",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "condition.value"
                                                    }
                                                  })
                                                ],
                                                1
                                              )
                                        ]
                                      ],
                                      2
                                    )
                                  })
                                ],
                                2
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("table-field-draggable", {
                        attrs: {
                          disabled: _vm.tablesDisabled,
                          module: _vm.module
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-card-actions",
                    { staticClass: "px-4 pb-4" },
                    [
                      _c(
                        "v-btn",
                        {
                          attrs: { small: "" },
                          on: { click: _vm.closeFilterCard }
                        },
                        [_vm._v("abbrechen")]
                      ),
                      _vm._v(" "),
                      _c(
                        "v-btn",
                        {
                          attrs: { small: "", color: "warning" },
                          on: { click: _vm.resetForm }
                        },
                        [_vm._v("Formular zurücksetzen")]
                      ),
                      _vm._v(" "),
                      _c("v-spacer"),
                      _vm._v(" "),
                      _c(
                        "v-btn",
                        {
                          attrs: { color: "primary" },
                          on: { click: _vm.saveFilter }
                        },
                        [_vm._v("Speichern + Anzeigen")]
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-overlay",
                    {
                      attrs: { absolute: "" },
                      model: {
                        value: _vm.loading,
                        callback: function($$v) {
                          _vm.loading = $$v
                        },
                        expression: "loading"
                      }
                    },
                    [
                      _c("v-progress-circular", {
                        attrs: { indeterminate: "", size: "64" }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e()
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-dialog",
        {
          attrs: { "max-width": "400" },
          model: {
            value: _vm.showDialog,
            callback: function($$v) {
              _vm.showDialog = $$v
            },
            expression: "showDialog"
          }
        },
        [
          _vm.currentFilter !== null && !_vm.isPublicEdit
            ? _c(
                "v-card",
                [
                  _c(
                    "v-toolbar",
                    { attrs: { color: "info", dark: "", flat: "" } },
                    [_c("v-toolbar-title", [_vm._v("Hinweis")])],
                    1
                  ),
                  _vm._v(" "),
                  _c("v-card-text", { staticClass: "pt-4 px-4" }, [
                    _vm._v(
                      "\n        Ihre Einstellungen für diesen Filter werden mit den neuen\n        Einstellungen überschrieben. Sind Sie sicher?\n      "
                    )
                  ]),
                  _vm._v(" "),
                  _c(
                    "v-card-actions",
                    [
                      _c(
                        "v-btn",
                        {
                          attrs: { color: "error", "x-small": "" },
                          on: {
                            click: function($event) {
                              $event.stopPropagation()
                              _vm.showDialog = false
                            }
                          }
                        },
                        [_vm._v("Nein")]
                      ),
                      _vm._v(" "),
                      _c("v-spacer"),
                      _vm._v(" "),
                      _c(
                        "v-btn",
                        {
                          attrs: { color: "primary", type: "btn", small: "" },
                          on: {
                            click: function($event) {
                              return _vm.saveFilterFromDialog(false)
                            }
                          }
                        },
                        [_vm._v("Ja")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            : _vm.currentFilter !== null && _vm.isPublicEdit
            ? _c(
                "v-card",
                [
                  _c(
                    "v-toolbar",
                    { attrs: { color: "info", dark: "", flat: "" } },
                    [_c("v-toolbar-title", [_vm._v("Hinweis")])],
                    1
                  ),
                  _vm._v(" "),
                  _c("v-card-text", { staticClass: "pt-4 px-4" }, [
                    _vm._v(
                      "\n        Öffentliche Filter können nur mit Administrator-Rechten bearbeitet\n        werden. Es wird ein neuer, privater Filter mit den gleichen\n        Einstellungen erstellt.\n      "
                    )
                  ]),
                  _vm._v(" "),
                  _c(
                    "v-card-actions",
                    [
                      _c(
                        "v-btn",
                        {
                          attrs: { color: "error", "x-small": "" },
                          on: {
                            click: function($event) {
                              $event.stopPropagation()
                              _vm.showDialog = false
                            }
                          }
                        },
                        [_vm._v("Abbrechen")]
                      ),
                      _vm._v(" "),
                      _c("v-spacer"),
                      _vm._v(" "),
                      _c(
                        "v-btn",
                        {
                          attrs: { color: "primary", type: "btn", small: "" },
                          on: {
                            click: function($event) {
                              return _vm.saveFilterFromDialog(true)
                            }
                          }
                        },
                        [_vm._v("Ok")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            : _c(
                "v-card",
                [
                  _c(
                    "v-toolbar",
                    { attrs: { color: "info", dark: "", flat: "" } },
                    [_c("v-toolbar-title", [_vm._v("Hinweis")])],
                    1
                  ),
                  _vm._v(" "),
                  _c("v-card-text", { staticClass: "pt-4 px-4" }, [
                    _vm._v(
                      '\n        Sie haben bereits einen Filter "' +
                        _vm._s(_vm.filterName) +
                        '".'
                    ),
                    _c("br"),
                    _vm._v(
                      "Um einen neuen\n        Filter zu erstellen müssen Sie den Namen ändern oder den Filter laden\n        und bearbeiten.\n      "
                    )
                  ]),
                  _vm._v(" "),
                  _c(
                    "v-card-actions",
                    [
                      _c("v-spacer"),
                      _vm._v(" "),
                      _c(
                        "v-btn",
                        {
                          attrs: { color: "primary", small: "" },
                          on: {
                            click: function($event) {
                              $event.stopPropagation()
                              _vm.showDialog = false
                            }
                          }
                        },
                        [_vm._v("Ok")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-dialog",
        {
          attrs: { "max-width": "400" },
          model: {
            value: _vm.showDeleteDialog,
            callback: function($$v) {
              _vm.showDeleteDialog = $$v
            },
            expression: "showDeleteDialog"
          }
        },
        [
          _c(
            "v-card",
            [
              _c(
                "v-toolbar",
                { attrs: { color: "error", dark: "", flat: "" } },
                [_c("v-toolbar-title", [_vm._v("Hinweis")])],
                1
              ),
              _vm._v(" "),
              _c("v-card-text", { staticClass: "pt-4 px-4" }, [
                _vm._v("\n        Geladenen Filter wirklich löschen?\n      ")
              ]),
              _vm._v(" "),
              _c(
                "v-card-actions",
                [
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "error", "x-small": "" },
                      on: {
                        click: function($event) {
                          $event.stopPropagation()
                          _vm.showDeleteDialog = false
                        }
                      }
                    },
                    [_vm._v("Nein")]
                  ),
                  _vm._v(" "),
                  _c("v-spacer"),
                  _vm._v(" "),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "primary", type: "btn", small: "" },
                      on: { click: _vm.deleteFilter }
                    },
                    [_vm._v("Ja")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }