var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("v-autocomplete", {
    attrs: {
      dense: "",
      filled: "",
      clearable: "",
      "hide-details": "",
      "item-value": "id",
      items: _vm.filters,
      placeholder: "Filter anwenden"
    },
    scopedSlots: _vm._u([
      {
        key: "append-outer",
        fn: function() {
          return [
            _c(
              "div",
              { staticClass: "v-input__icon v-input__icon--append-outer" },
              [
                _c(
                  "v-btn",
                  {
                    attrs: { icon: "" },
                    on: {
                      click: function($event) {
                        return _vm.$emit("toggle")
                      }
                    }
                  },
                  [_c("v-icon", [_vm._v("mdi-filter")])],
                  1
                )
              ],
              1
            )
          ]
        },
        proxy: true
      }
    ]),
    model: {
      value: _vm.$root.currentFilter,
      callback: function($$v) {
        _vm.$set(_vm.$root, "currentFilter", $$v)
      },
      expression: "$root.currentFilter"
    }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }