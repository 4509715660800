var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.notifications.length > 0
        ? _vm._l(_vm.notifications, function(item) {
            return _c(
              "v-row",
              { key: item.id, staticStyle: { position: "relative" } },
              [
                item.type.includes("NewsCreated")
                  ? [
                      _c(
                        "v-col",
                        { attrs: { cols: "8" } },
                        [
                          _c("h4", [_vm._v(_vm._s(item.data.title))]),
                          _vm._v(" "),
                          _c("p", [_vm._v(_vm._s(item.data.excerpt))]),
                          _vm._v(" "),
                          _c(
                            "v-row",
                            { staticClass: "mx-0", attrs: { justify: "end" } },
                            [
                              _c(
                                "v-btn",
                                {
                                  attrs: {
                                    link: "",
                                    href: _vm.route(
                                      "news.show",
                                      item.data.slug
                                    ),
                                    text: "",
                                    color: "primary"
                                  }
                                },
                                [_vm._v("weiterlesen")]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-col",
                        { attrs: { cols: "4" } },
                        [
                          _c("v-img", {
                            attrs: {
                              "max-width": "200",
                              contain: "",
                              src: item.data.featuredImage + "_thumbnail.webp"
                            }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-tooltip",
                        {
                          attrs: { bottom: "" },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "activator",
                                fn: function(ref) {
                                  var on = ref.on
                                  var attrs = ref.attrs
                                  return [
                                    _c(
                                      "v-btn",
                                      _vm._g(
                                        _vm._b(
                                          {
                                            staticClass: "mt-n2 mr-n2 white",
                                            attrs: {
                                              absolute: "",
                                              rounded: "",
                                              top: "",
                                              "x-small": "",
                                              icon: "",
                                              elevation: "2",
                                              right: "",
                                              color: "error"
                                            },
                                            on: {
                                              click: function($event) {
                                                $event.stopPropagation()
                                                return _vm.markAsRead(item.id)
                                              }
                                            }
                                          },
                                          "v-btn",
                                          attrs,
                                          false
                                        ),
                                        on
                                      ),
                                      [_c("v-icon", [_vm._v("mdi-close")])],
                                      1
                                    )
                                  ]
                                }
                              }
                            ],
                            null,
                            true
                          )
                        },
                        [
                          _vm._v(" "),
                          _c("span", [_vm._v("Als gelesen markieren")])
                        ]
                      )
                    ]
                  : [
                      _c("v-col", { attrs: { cols: "12" } }, [
                        _vm._v(" Keine ungelesenen Nachrichten ")
                      ])
                    ]
              ],
              2
            )
          })
        : [
            _c(
              "v-row",
              [
                _c("v-col", { attrs: { cols: "12" } }, [
                  _vm._v(" Keine ungelesenen Nachrichten ")
                ])
              ],
              1
            )
          ]
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }