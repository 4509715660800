var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-expansion-panels",
        {
          attrs: { multiple: "" },
          model: {
            value: _vm.openPanels,
            callback: function($$v) {
              _vm.openPanels = $$v
            },
            expression: "openPanels"
          }
        },
        [
          _vm._l(this.configData.panel_blocks, function(block) {
            return _c(
              "v-expansion-panel",
              { key: block.id, attrs: { "active-class": "active" } },
              [
                _c(
                  "v-expansion-panel-header",
                  { staticClass: "text-h6" },
                  [
                    _c("v-col", { attrs: { cols: "6" } }, [
                      _vm._v(
                        "\n          " + _vm._s(block.title) + "\n        "
                      )
                    ]),
                    _vm._v(" "),
                    _c(
                      "v-row",
                      {
                        staticClass: "flex-nowrap mr-8",
                        staticStyle: { "column-gap": "30px" },
                        attrs: { align: "center", justify: "end" }
                      },
                      [
                        _c("v-col", { staticClass: "text-right" }, [
                          _vm._v(_vm._s(block.subtitle))
                        ]),
                        _vm._v(" "),
                        block.featured_image
                          ? [
                              _c(
                                "v-col",
                                { staticClass: "py-0", attrs: { cols: "3" } },
                                [
                                  _c("v-img", {
                                    attrs: {
                                      height: "100",
                                      width: "150",
                                      contain: "",
                                      src: block.featured_image
                                    }
                                  })
                                ],
                                1
                              )
                            ]
                          : _vm._e()
                      ],
                      2
                    )
                  ],
                  1
                ),
                _vm._v(" "),
                block.type === "table"
                  ? _c(
                      "v-expansion-panel-content",
                      [
                        _c("v-divider", { staticClass: "mb-4" }),
                        _vm._v(" "),
                        block.module === _vm.module
                          ? _c(
                              "v-row",
                              {
                                class: {
                                  "flex-nowrap": _vm.$vuetify.breakpoint.lgAndUp
                                }
                              },
                              [
                                _vm._l(block.panel_columns, function(
                                  column,
                                  index
                                ) {
                                  return [
                                    column.portal_fields.length > 0
                                      ? _c(
                                          "v-col",
                                          {
                                            key: index,
                                            attrs: {
                                              cols: "12",
                                              lg:
                                                12 / block.panel_columns.length
                                            }
                                          },
                                          [
                                            _c("v-data-iterator", {
                                              attrs: {
                                                "disable-filtering": "",
                                                "disable-pagination": "",
                                                "disable-sort": "",
                                                "hide-default-footer": "",
                                                items: column.portal_fields
                                              },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "default",
                                                    fn: function(ref) {
                                                      var items = ref.items
                                                      return _vm._l(
                                                        items,
                                                        function(item) {
                                                          return _c(
                                                            "div",
                                                            {
                                                              key: item.id,
                                                              staticClass:
                                                                "fields-item px-4 my-1"
                                                            },
                                                            [
                                                              _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "field-title py-2"
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "\n                      " +
                                                                      _vm._s(
                                                                        item.field_name
                                                                      ) +
                                                                      "\n                      "
                                                                  ),
                                                                  item.description
                                                                    ? [
                                                                        _c(
                                                                          "v-tooltip",
                                                                          {
                                                                            attrs: {
                                                                              bottom:
                                                                                ""
                                                                            },
                                                                            scopedSlots: _vm._u(
                                                                              [
                                                                                {
                                                                                  key:
                                                                                    "activator",
                                                                                  fn: function(
                                                                                    ref
                                                                                  ) {
                                                                                    var on =
                                                                                      ref.on
                                                                                    var attrs =
                                                                                      ref.attrs
                                                                                    return [
                                                                                      _c(
                                                                                        "v-btn",
                                                                                        _vm._g(
                                                                                          _vm._b(
                                                                                            {
                                                                                              attrs: {
                                                                                                color:
                                                                                                  "info",
                                                                                                icon:
                                                                                                  ""
                                                                                              }
                                                                                            },
                                                                                            "v-btn",
                                                                                            attrs,
                                                                                            false
                                                                                          ),
                                                                                          on
                                                                                        ),
                                                                                        [
                                                                                          _c(
                                                                                            "v-icon",
                                                                                            [
                                                                                              _vm._v(
                                                                                                "mdi-information-outline"
                                                                                              )
                                                                                            ]
                                                                                          )
                                                                                        ],
                                                                                        1
                                                                                      )
                                                                                    ]
                                                                                  }
                                                                                }
                                                                              ],
                                                                              null,
                                                                              true
                                                                            )
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              " "
                                                                            ),
                                                                            _c(
                                                                              "span",
                                                                              [
                                                                                _vm._v(
                                                                                  _vm._s(
                                                                                    item.description
                                                                                  )
                                                                                )
                                                                              ]
                                                                            )
                                                                          ]
                                                                        )
                                                                      ]
                                                                    : _vm._e()
                                                                ],
                                                                2
                                                              ),
                                                              _vm._v(" "),
                                                              _c("div", {
                                                                staticClass:
                                                                  "field-divider"
                                                              }),
                                                              _vm._v(" "),
                                                              block.module ==
                                                              _vm.module
                                                                ? _c(
                                                                    "div",
                                                                    {
                                                                      staticClass:
                                                                        "field-value py-2"
                                                                    },
                                                                    [
                                                                      !(
                                                                        item.field_name in
                                                                        _vm.data
                                                                      ) ||
                                                                      !_vm.data[
                                                                        item
                                                                          .field_name
                                                                      ]
                                                                        ? [
                                                                            _vm._v(
                                                                              "\n                        -\n                      "
                                                                            )
                                                                          ]
                                                                        : item.data_type ==
                                                                          "date"
                                                                        ? [
                                                                            _vm._v(
                                                                              "\n                        " +
                                                                                _vm._s(
                                                                                  _vm._f(
                                                                                    "formatDate"
                                                                                  )(
                                                                                    _vm
                                                                                      .data[
                                                                                      item
                                                                                        .field_name
                                                                                    ]
                                                                                  )
                                                                                ) +
                                                                                "\n                        "
                                                                            ),
                                                                            item.portal_field_route
                                                                              ? _c(
                                                                                  "v-btn",
                                                                                  {
                                                                                    attrs: {
                                                                                      color:
                                                                                        "info",
                                                                                      icon:
                                                                                        "",
                                                                                      href: _vm.route(
                                                                                        item.portal_field_route.module.toLowerCase() +
                                                                                          ".linked",
                                                                                        {
                                                                                          field:
                                                                                            item
                                                                                              .portal_field_route
                                                                                              .link_field_id,
                                                                                          value:
                                                                                            _vm
                                                                                              .data[
                                                                                              item
                                                                                                .field_name
                                                                                            ]
                                                                                        }
                                                                                      )
                                                                                    }
                                                                                  },
                                                                                  [
                                                                                    _c(
                                                                                      "v-icon",
                                                                                      [
                                                                                        _vm._v(
                                                                                          "mdi-link-variant"
                                                                                        )
                                                                                      ]
                                                                                    )
                                                                                  ],
                                                                                  1
                                                                                )
                                                                              : _vm._e()
                                                                          ]
                                                                        : item.data_type ==
                                                                          "number"
                                                                        ? [
                                                                            _vm._v(
                                                                              "\n                        " +
                                                                                _vm._s(
                                                                                  _vm._f(
                                                                                    "formatNumber"
                                                                                  )(
                                                                                    _vm
                                                                                      .data[
                                                                                      item
                                                                                        .field_name
                                                                                    ]
                                                                                  )
                                                                                ) +
                                                                                "\n                        "
                                                                            ),
                                                                            item.portal_field_route
                                                                              ? _c(
                                                                                  "v-btn",
                                                                                  {
                                                                                    attrs: {
                                                                                      color:
                                                                                        "info",
                                                                                      icon:
                                                                                        "",
                                                                                      href: _vm.route(
                                                                                        item.portal_field_route.module.toLowerCase() +
                                                                                          ".linked",
                                                                                        {
                                                                                          field:
                                                                                            item
                                                                                              .portal_field_route
                                                                                              .link_field_id,
                                                                                          value:
                                                                                            _vm
                                                                                              .data[
                                                                                              item
                                                                                                .field_name
                                                                                            ]
                                                                                        }
                                                                                      )
                                                                                    }
                                                                                  },
                                                                                  [
                                                                                    _c(
                                                                                      "v-icon",
                                                                                      [
                                                                                        _vm._v(
                                                                                          "mdi-link-variant"
                                                                                        )
                                                                                      ]
                                                                                    )
                                                                                  ],
                                                                                  1
                                                                                )
                                                                              : _vm._e()
                                                                          ]
                                                                        : item.data_type ==
                                                                          "coordinate"
                                                                        ? [
                                                                            _vm._v(
                                                                              "\n                        " +
                                                                                _vm._s(
                                                                                  _vm._f(
                                                                                    "formatCoordinate"
                                                                                  )(
                                                                                    _vm
                                                                                      .data[
                                                                                      item
                                                                                        .field_name
                                                                                    ]
                                                                                  )
                                                                                ) +
                                                                                "\n                        "
                                                                            ),
                                                                            item.portal_field_route
                                                                              ? _c(
                                                                                  "v-btn",
                                                                                  {
                                                                                    attrs: {
                                                                                      color:
                                                                                        "info",
                                                                                      icon:
                                                                                        "",
                                                                                      href: _vm.route(
                                                                                        item.portal_field_route.module.toLowerCase() +
                                                                                          ".linked",
                                                                                        {
                                                                                          field:
                                                                                            item
                                                                                              .portal_field_route
                                                                                              .link_field_id,
                                                                                          value:
                                                                                            _vm
                                                                                              .data[
                                                                                              item
                                                                                                .field_name
                                                                                            ]
                                                                                        }
                                                                                      )
                                                                                    }
                                                                                  },
                                                                                  [
                                                                                    _c(
                                                                                      "v-icon",
                                                                                      [
                                                                                        _vm._v(
                                                                                          "mdi-link-variant"
                                                                                        )
                                                                                      ]
                                                                                    )
                                                                                  ],
                                                                                  1
                                                                                )
                                                                              : _vm._e()
                                                                          ]
                                                                        : _vm.isURL(
                                                                            _vm
                                                                              .data[
                                                                              item
                                                                                .field_name
                                                                            ]
                                                                          )
                                                                        ? [
                                                                            _c(
                                                                              "a",
                                                                              {
                                                                                attrs: {
                                                                                  href: _vm.isURL(
                                                                                    _vm
                                                                                      .data[
                                                                                      item
                                                                                        .field_name
                                                                                    ]
                                                                                  )
                                                                                }
                                                                              },
                                                                              [
                                                                                _vm._v(
                                                                                  _vm._s(
                                                                                    _vm
                                                                                      .data[
                                                                                      item
                                                                                        .field_name
                                                                                    ]
                                                                                  )
                                                                                )
                                                                              ]
                                                                            )
                                                                          ]
                                                                        : [
                                                                            _vm._v(
                                                                              "\n                        " +
                                                                                _vm._s(
                                                                                  _vm
                                                                                    .data[
                                                                                    item
                                                                                      .field_name
                                                                                  ]
                                                                                ) +
                                                                                "\n                        "
                                                                            ),
                                                                            item.portal_field_route
                                                                              ? _c(
                                                                                  "v-btn",
                                                                                  {
                                                                                    attrs: {
                                                                                      color:
                                                                                        "info",
                                                                                      icon:
                                                                                        "",
                                                                                      href: _vm.route(
                                                                                        item.portal_field_route.module.toLowerCase() +
                                                                                          ".linked",
                                                                                        {
                                                                                          field:
                                                                                            item
                                                                                              .portal_field_route
                                                                                              .link_field_id,
                                                                                          value:
                                                                                            _vm
                                                                                              .data[
                                                                                              item
                                                                                                .field_name
                                                                                            ]
                                                                                        }
                                                                                      )
                                                                                    }
                                                                                  },
                                                                                  [
                                                                                    _c(
                                                                                      "v-icon",
                                                                                      [
                                                                                        _vm._v(
                                                                                          "mdi-link-variant"
                                                                                        )
                                                                                      ]
                                                                                    )
                                                                                  ],
                                                                                  1
                                                                                )
                                                                              : _vm._e()
                                                                          ]
                                                                    ],
                                                                    2
                                                                  )
                                                                : _vm.data[
                                                                    block.module.toLowerCase()
                                                                  ]
                                                                ? _c(
                                                                    "div",
                                                                    {
                                                                      staticClass:
                                                                        "field-value py-2"
                                                                    },
                                                                    [
                                                                      item.data_type ==
                                                                      "date"
                                                                        ? [
                                                                            _vm._v(
                                                                              "\n                        " +
                                                                                _vm._s(
                                                                                  _vm._f(
                                                                                    "formatDate"
                                                                                  )(
                                                                                    _vm
                                                                                      .data[
                                                                                      block.module.toLowerCase()
                                                                                    ][
                                                                                      item
                                                                                        .field_name
                                                                                    ]
                                                                                  )
                                                                                ) +
                                                                                "\n                        "
                                                                            ),
                                                                            item.portal_field_route
                                                                              ? _c(
                                                                                  "v-btn",
                                                                                  {
                                                                                    attrs: {
                                                                                      color:
                                                                                        "info",
                                                                                      icon:
                                                                                        "",
                                                                                      href: _vm.route(
                                                                                        item.portal_field_route.module.toLowerCase() +
                                                                                          ".linked",
                                                                                        {
                                                                                          field:
                                                                                            item
                                                                                              .portal_field_route
                                                                                              .link_field_id,
                                                                                          value:
                                                                                            _vm
                                                                                              .data[
                                                                                              block.module.toLowerCase()
                                                                                            ][
                                                                                              item
                                                                                                .field_name
                                                                                            ]
                                                                                        }
                                                                                      )
                                                                                    }
                                                                                  },
                                                                                  [
                                                                                    _c(
                                                                                      "v-icon",
                                                                                      [
                                                                                        _vm._v(
                                                                                          "mdi-link-variant"
                                                                                        )
                                                                                      ]
                                                                                    )
                                                                                  ],
                                                                                  1
                                                                                )
                                                                              : _vm._e()
                                                                          ]
                                                                        : item.data_type ==
                                                                          "number"
                                                                        ? [
                                                                            _vm._v(
                                                                              "\n                        " +
                                                                                _vm._s(
                                                                                  _vm._f(
                                                                                    "formatNumber"
                                                                                  )(
                                                                                    _vm
                                                                                      .data[
                                                                                      block.module.toLowerCase()
                                                                                    ][
                                                                                      item
                                                                                        .field_name
                                                                                    ]
                                                                                  )
                                                                                ) +
                                                                                "\n                        "
                                                                            ),
                                                                            item.portal_field_route
                                                                              ? _c(
                                                                                  "v-btn",
                                                                                  {
                                                                                    attrs: {
                                                                                      color:
                                                                                        "info",
                                                                                      icon:
                                                                                        "",
                                                                                      href: _vm.route(
                                                                                        item.portal_field_route.module.toLowerCase() +
                                                                                          ".linked",
                                                                                        {
                                                                                          field:
                                                                                            item
                                                                                              .portal_field_route
                                                                                              .link_field_id,
                                                                                          value:
                                                                                            _vm
                                                                                              .data[
                                                                                              block.module.toLowerCase()
                                                                                            ][
                                                                                              item
                                                                                                .field_name
                                                                                            ]
                                                                                        }
                                                                                      )
                                                                                    }
                                                                                  },
                                                                                  [
                                                                                    _c(
                                                                                      "v-icon",
                                                                                      [
                                                                                        _vm._v(
                                                                                          "mdi-link-variant"
                                                                                        )
                                                                                      ]
                                                                                    )
                                                                                  ],
                                                                                  1
                                                                                )
                                                                              : _vm._e()
                                                                          ]
                                                                        : item.data_type ==
                                                                          "coordinate"
                                                                        ? [
                                                                            _vm._v(
                                                                              "\n                        " +
                                                                                _vm._s(
                                                                                  _vm._f(
                                                                                    "formatCoordinate"
                                                                                  )(
                                                                                    _vm
                                                                                      .data[
                                                                                      block.module.toLowerCase()
                                                                                    ][
                                                                                      item
                                                                                        .field_name
                                                                                    ]
                                                                                  )
                                                                                ) +
                                                                                "\n                        "
                                                                            ),
                                                                            item.portal_field_route
                                                                              ? _c(
                                                                                  "v-btn",
                                                                                  {
                                                                                    attrs: {
                                                                                      color:
                                                                                        "info",
                                                                                      icon:
                                                                                        "",
                                                                                      href: _vm.route(
                                                                                        item.portal_field_route.module.toLowerCase() +
                                                                                          ".linked",
                                                                                        {
                                                                                          field:
                                                                                            item
                                                                                              .portal_field_route
                                                                                              .link_field_id,
                                                                                          value:
                                                                                            _vm
                                                                                              .data[
                                                                                              block.module.toLowerCase()
                                                                                            ][
                                                                                              item
                                                                                                .field_name
                                                                                            ]
                                                                                        }
                                                                                      )
                                                                                    }
                                                                                  },
                                                                                  [
                                                                                    _c(
                                                                                      "v-icon",
                                                                                      [
                                                                                        _vm._v(
                                                                                          "mdi-link-variant"
                                                                                        )
                                                                                      ]
                                                                                    )
                                                                                  ],
                                                                                  1
                                                                                )
                                                                              : _vm._e()
                                                                          ]
                                                                        : _vm.isURL(
                                                                            _vm
                                                                              .data[
                                                                              block.module.toLowerCase()
                                                                            ][
                                                                              item
                                                                                .field_name
                                                                            ]
                                                                          )
                                                                        ? [
                                                                            _c(
                                                                              "a",
                                                                              {
                                                                                attrs: {
                                                                                  href: _vm.isURL(
                                                                                    _vm
                                                                                      .data[
                                                                                      block.module.toLowerCase()
                                                                                    ][
                                                                                      item
                                                                                        .field_name
                                                                                    ]
                                                                                  )
                                                                                }
                                                                              },
                                                                              [
                                                                                _vm._v(
                                                                                  _vm._s(
                                                                                    _vm
                                                                                      .data[
                                                                                      block.module.toLowerCase()
                                                                                    ][
                                                                                      item
                                                                                        .field_name
                                                                                    ]
                                                                                  )
                                                                                )
                                                                              ]
                                                                            )
                                                                          ]
                                                                        : [
                                                                            _vm._v(
                                                                              "\n                        " +
                                                                                _vm._s(
                                                                                  _vm
                                                                                    .data[
                                                                                    block.module.toLowerCase()
                                                                                  ][
                                                                                    item
                                                                                      .field_name
                                                                                  ]
                                                                                ) +
                                                                                "\n                        "
                                                                            ),
                                                                            item.portal_field_route
                                                                              ? _c(
                                                                                  "v-btn",
                                                                                  {
                                                                                    attrs: {
                                                                                      color:
                                                                                        "info",
                                                                                      icon:
                                                                                        "",
                                                                                      href: _vm.route(
                                                                                        item.portal_field_route.module.toLowerCase() +
                                                                                          ".linked",
                                                                                        {
                                                                                          field:
                                                                                            item
                                                                                              .portal_field_route
                                                                                              .link_field_id,
                                                                                          value:
                                                                                            _vm
                                                                                              .data[
                                                                                              block.module.toLowerCase()
                                                                                            ][
                                                                                              item
                                                                                                .field_name
                                                                                            ]
                                                                                        }
                                                                                      )
                                                                                    }
                                                                                  },
                                                                                  [
                                                                                    _c(
                                                                                      "v-icon",
                                                                                      [
                                                                                        _vm._v(
                                                                                          "mdi-link-variant"
                                                                                        )
                                                                                      ]
                                                                                    )
                                                                                  ],
                                                                                  1
                                                                                )
                                                                              : _vm._e()
                                                                          ]
                                                                    ],
                                                                    2
                                                                  )
                                                                : _c(
                                                                    "div",
                                                                    {
                                                                      staticClass:
                                                                        "field-value py-2"
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "-"
                                                                      )
                                                                    ]
                                                                  )
                                                            ]
                                                          )
                                                        }
                                                      )
                                                    }
                                                  }
                                                ],
                                                null,
                                                true
                                              )
                                            })
                                          ],
                                          1
                                        )
                                      : _vm._e()
                                  ]
                                })
                              ],
                              2
                            )
                          : block.module === "Ansprechpartner"
                          ? _c(
                              "div",
                              _vm._l(_vm.data["ap"], function(ap, index) {
                                return _c(
                                  "v-row",
                                  { key: index },
                                  [
                                    _c("v-col", { attrs: { cols: "12" } }, [
                                      _c("h3", [
                                        _vm._v(
                                          "Ansprechpartner " + _vm._s(index + 1)
                                        )
                                      ])
                                    ]),
                                    _vm._v(" "),
                                    _vm._l(block.panel_columns, function(
                                      column,
                                      index
                                    ) {
                                      return [
                                        column.portal_fields.length > 0
                                          ? _c(
                                              "v-col",
                                              {
                                                key: index,
                                                attrs: {
                                                  cols: "12",
                                                  lg:
                                                    12 /
                                                    block.panel_columns.length
                                                }
                                              },
                                              [
                                                _c("v-data-iterator", {
                                                  attrs: {
                                                    "disable-filtering": "",
                                                    "disable-pagination": "",
                                                    "disable-sort": "",
                                                    "hide-default-footer": "",
                                                    items: column.portal_fields
                                                  },
                                                  scopedSlots: _vm._u(
                                                    [
                                                      {
                                                        key: "default",
                                                        fn: function(ref) {
                                                          var items = ref.items
                                                          return _vm._l(
                                                            items,
                                                            function(item) {
                                                              return _c(
                                                                "div",
                                                                {
                                                                  key: item.id,
                                                                  staticClass:
                                                                    "fields-item px-4 my-1"
                                                                },
                                                                [
                                                                  _c(
                                                                    "div",
                                                                    {
                                                                      staticClass:
                                                                        "field-title py-2"
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "\n                        " +
                                                                          _vm._s(
                                                                            item.field_name
                                                                          ) +
                                                                          "\n                        "
                                                                      ),
                                                                      item.description
                                                                        ? [
                                                                            _c(
                                                                              "v-tooltip",
                                                                              {
                                                                                attrs: {
                                                                                  bottom:
                                                                                    ""
                                                                                },
                                                                                scopedSlots: _vm._u(
                                                                                  [
                                                                                    {
                                                                                      key:
                                                                                        "activator",
                                                                                      fn: function(
                                                                                        ref
                                                                                      ) {
                                                                                        var on =
                                                                                          ref.on
                                                                                        var attrs =
                                                                                          ref.attrs
                                                                                        return [
                                                                                          _c(
                                                                                            "v-btn",
                                                                                            _vm._g(
                                                                                              _vm._b(
                                                                                                {
                                                                                                  attrs: {
                                                                                                    color:
                                                                                                      "info",
                                                                                                    icon:
                                                                                                      ""
                                                                                                  }
                                                                                                },
                                                                                                "v-btn",
                                                                                                attrs,
                                                                                                false
                                                                                              ),
                                                                                              on
                                                                                            ),
                                                                                            [
                                                                                              _c(
                                                                                                "v-icon",
                                                                                                [
                                                                                                  _vm._v(
                                                                                                    "mdi-information-outline"
                                                                                                  )
                                                                                                ]
                                                                                              )
                                                                                            ],
                                                                                            1
                                                                                          )
                                                                                        ]
                                                                                      }
                                                                                    }
                                                                                  ],
                                                                                  null,
                                                                                  true
                                                                                )
                                                                              },
                                                                              [
                                                                                _vm._v(
                                                                                  " "
                                                                                ),
                                                                                _c(
                                                                                  "span",
                                                                                  [
                                                                                    _vm._v(
                                                                                      _vm._s(
                                                                                        item.description
                                                                                      )
                                                                                    )
                                                                                  ]
                                                                                )
                                                                              ]
                                                                            )
                                                                          ]
                                                                        : _vm._e()
                                                                    ],
                                                                    2
                                                                  ),
                                                                  _vm._v(" "),
                                                                  _c("div", {
                                                                    staticClass:
                                                                      "field-divider"
                                                                  }),
                                                                  _vm._v(" "),
                                                                  _c(
                                                                    "div",
                                                                    {
                                                                      staticClass:
                                                                        "field-value py-2"
                                                                    },
                                                                    [
                                                                      !(
                                                                        item.field_name in
                                                                        ap
                                                                      ) ||
                                                                      !ap[
                                                                        item
                                                                          .field_name
                                                                      ]
                                                                        ? [
                                                                            _vm._v(
                                                                              "\n                          -\n                        "
                                                                            )
                                                                          ]
                                                                        : item.data_type ==
                                                                          "date"
                                                                        ? [
                                                                            _vm._v(
                                                                              "\n                          " +
                                                                                _vm._s(
                                                                                  _vm._f(
                                                                                    "formatDate"
                                                                                  )(
                                                                                    ap[
                                                                                      item
                                                                                        .field_name
                                                                                    ]
                                                                                  )
                                                                                ) +
                                                                                "\n                          "
                                                                            ),
                                                                            item.portal_field_route
                                                                              ? _c(
                                                                                  "v-btn",
                                                                                  {
                                                                                    attrs: {
                                                                                      color:
                                                                                        "info",
                                                                                      icon:
                                                                                        "",
                                                                                      href: _vm.route(
                                                                                        item.portal_field_route.module.toLowerCase() +
                                                                                          ".linked",
                                                                                        {
                                                                                          field:
                                                                                            item
                                                                                              .portal_field_route
                                                                                              .link_field_id,
                                                                                          value:
                                                                                            ap[
                                                                                              item
                                                                                                .field_name
                                                                                            ]
                                                                                        }
                                                                                      )
                                                                                    }
                                                                                  },
                                                                                  [
                                                                                    _c(
                                                                                      "v-icon",
                                                                                      [
                                                                                        _vm._v(
                                                                                          "mdi-link-variant"
                                                                                        )
                                                                                      ]
                                                                                    )
                                                                                  ],
                                                                                  1
                                                                                )
                                                                              : _vm._e()
                                                                          ]
                                                                        : item.data_type ==
                                                                          "number"
                                                                        ? [
                                                                            _vm._v(
                                                                              "\n                          " +
                                                                                _vm._s(
                                                                                  _vm._f(
                                                                                    "formatNumber"
                                                                                  )(
                                                                                    ap[
                                                                                      item
                                                                                        .field_name
                                                                                    ]
                                                                                  )
                                                                                ) +
                                                                                "\n                          "
                                                                            ),
                                                                            item.portal_field_route
                                                                              ? _c(
                                                                                  "v-btn",
                                                                                  {
                                                                                    attrs: {
                                                                                      color:
                                                                                        "info",
                                                                                      icon:
                                                                                        "",
                                                                                      href: _vm.route(
                                                                                        item.portal_field_route.module.toLowerCase() +
                                                                                          ".linked",
                                                                                        {
                                                                                          field:
                                                                                            item
                                                                                              .portal_field_route
                                                                                              .link_field_id,
                                                                                          value:
                                                                                            ap[
                                                                                              item
                                                                                                .field_name
                                                                                            ]
                                                                                        }
                                                                                      )
                                                                                    }
                                                                                  },
                                                                                  [
                                                                                    _c(
                                                                                      "v-icon",
                                                                                      [
                                                                                        _vm._v(
                                                                                          "mdi-link-variant"
                                                                                        )
                                                                                      ]
                                                                                    )
                                                                                  ],
                                                                                  1
                                                                                )
                                                                              : _vm._e()
                                                                          ]
                                                                        : item.data_type ==
                                                                          "coordinate"
                                                                        ? [
                                                                            _vm._v(
                                                                              "\n                          " +
                                                                                _vm._s(
                                                                                  _vm._f(
                                                                                    "formatCoordinate"
                                                                                  )(
                                                                                    ap[
                                                                                      item
                                                                                        .field_name
                                                                                    ]
                                                                                  )
                                                                                ) +
                                                                                "\n                          "
                                                                            ),
                                                                            item.portal_field_route
                                                                              ? _c(
                                                                                  "v-btn",
                                                                                  {
                                                                                    attrs: {
                                                                                      color:
                                                                                        "info",
                                                                                      icon:
                                                                                        "",
                                                                                      href: _vm.route(
                                                                                        item.portal_field_route.module.toLowerCase() +
                                                                                          ".linked",
                                                                                        {
                                                                                          field:
                                                                                            item
                                                                                              .portal_field_route
                                                                                              .link_field_id,
                                                                                          value:
                                                                                            ap[
                                                                                              item
                                                                                                .field_name
                                                                                            ]
                                                                                        }
                                                                                      )
                                                                                    }
                                                                                  },
                                                                                  [
                                                                                    _c(
                                                                                      "v-icon",
                                                                                      [
                                                                                        _vm._v(
                                                                                          "mdi-link-variant"
                                                                                        )
                                                                                      ]
                                                                                    )
                                                                                  ],
                                                                                  1
                                                                                )
                                                                              : _vm._e()
                                                                          ]
                                                                        : _vm.isURL(
                                                                            ap[
                                                                              item
                                                                                .field_name
                                                                            ]
                                                                          )
                                                                        ? [
                                                                            _c(
                                                                              "a",
                                                                              {
                                                                                attrs: {
                                                                                  href: _vm.isURL(
                                                                                    ap[
                                                                                      item
                                                                                        .field_name
                                                                                    ]
                                                                                  )
                                                                                }
                                                                              },
                                                                              [
                                                                                _vm._v(
                                                                                  _vm._s(
                                                                                    ap[
                                                                                      item
                                                                                        .field_name
                                                                                    ]
                                                                                  )
                                                                                )
                                                                              ]
                                                                            )
                                                                          ]
                                                                        : [
                                                                            _vm._v(
                                                                              "\n                          " +
                                                                                _vm._s(
                                                                                  ap[
                                                                                    item
                                                                                      .field_name
                                                                                  ]
                                                                                ) +
                                                                                "\n                          "
                                                                            ),
                                                                            item.portal_field_route
                                                                              ? _c(
                                                                                  "v-btn",
                                                                                  {
                                                                                    attrs: {
                                                                                      color:
                                                                                        "info",
                                                                                      icon:
                                                                                        "",
                                                                                      href: _vm.route(
                                                                                        item.portal_field_route.module.toLowerCase() +
                                                                                          ".linked",
                                                                                        {
                                                                                          field:
                                                                                            item
                                                                                              .portal_field_route
                                                                                              .link_field_id,
                                                                                          value:
                                                                                            ap[
                                                                                              item
                                                                                                .field_name
                                                                                            ]
                                                                                        }
                                                                                      )
                                                                                    }
                                                                                  },
                                                                                  [
                                                                                    _c(
                                                                                      "v-icon",
                                                                                      [
                                                                                        _vm._v(
                                                                                          "mdi-link-variant"
                                                                                        )
                                                                                      ]
                                                                                    )
                                                                                  ],
                                                                                  1
                                                                                )
                                                                              : _vm._e()
                                                                          ]
                                                                    ],
                                                                    2
                                                                  )
                                                                ]
                                                              )
                                                            }
                                                          )
                                                        }
                                                      }
                                                    ],
                                                    null,
                                                    true
                                                  )
                                                })
                                              ],
                                              1
                                            )
                                          : _vm._e()
                                      ]
                                    })
                                  ],
                                  2
                                )
                              }),
                              1
                            )
                          : block.module === "Organisationen"
                          ? _c(
                              "div",
                              [
                                _vm.data["organisation"]
                                  ? [
                                      _c(
                                        "v-row",
                                        [
                                          _vm._l(block.panel_columns, function(
                                            column,
                                            index
                                          ) {
                                            return [
                                              column.portal_fields.length > 0
                                                ? _c(
                                                    "v-col",
                                                    {
                                                      key: index,
                                                      attrs: {
                                                        cols: "12",
                                                        lg:
                                                          12 /
                                                          block.panel_columns
                                                            .length
                                                      }
                                                    },
                                                    [
                                                      _c("v-data-iterator", {
                                                        attrs: {
                                                          "disable-filtering":
                                                            "",
                                                          "disable-pagination":
                                                            "",
                                                          "disable-sort": "",
                                                          "hide-default-footer":
                                                            "",
                                                          items:
                                                            column.portal_fields
                                                        },
                                                        scopedSlots: _vm._u(
                                                          [
                                                            {
                                                              key: "default",
                                                              fn: function(
                                                                ref
                                                              ) {
                                                                var items =
                                                                  ref.items
                                                                return _vm._l(
                                                                  items,
                                                                  function(
                                                                    item
                                                                  ) {
                                                                    return _c(
                                                                      "div",
                                                                      {
                                                                        key:
                                                                          item.id,
                                                                        staticClass:
                                                                          "fields-item px-4 my-1"
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "div",
                                                                          {
                                                                            staticClass:
                                                                              "field-title py-2"
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              "\n                          " +
                                                                                _vm._s(
                                                                                  item.field_name
                                                                                ) +
                                                                                "\n                          "
                                                                            ),
                                                                            item.description
                                                                              ? [
                                                                                  _c(
                                                                                    "v-tooltip",
                                                                                    {
                                                                                      attrs: {
                                                                                        bottom:
                                                                                          ""
                                                                                      },
                                                                                      scopedSlots: _vm._u(
                                                                                        [
                                                                                          {
                                                                                            key:
                                                                                              "activator",
                                                                                            fn: function(
                                                                                              ref
                                                                                            ) {
                                                                                              var on =
                                                                                                ref.on
                                                                                              var attrs =
                                                                                                ref.attrs
                                                                                              return [
                                                                                                _c(
                                                                                                  "v-btn",
                                                                                                  _vm._g(
                                                                                                    _vm._b(
                                                                                                      {
                                                                                                        attrs: {
                                                                                                          color:
                                                                                                            "info",
                                                                                                          icon:
                                                                                                            ""
                                                                                                        }
                                                                                                      },
                                                                                                      "v-btn",
                                                                                                      attrs,
                                                                                                      false
                                                                                                    ),
                                                                                                    on
                                                                                                  ),
                                                                                                  [
                                                                                                    _c(
                                                                                                      "v-icon",
                                                                                                      [
                                                                                                        _vm._v(
                                                                                                          "mdi-information-outline"
                                                                                                        )
                                                                                                      ]
                                                                                                    )
                                                                                                  ],
                                                                                                  1
                                                                                                )
                                                                                              ]
                                                                                            }
                                                                                          }
                                                                                        ],
                                                                                        null,
                                                                                        true
                                                                                      )
                                                                                    },
                                                                                    [
                                                                                      _vm._v(
                                                                                        " "
                                                                                      ),
                                                                                      _c(
                                                                                        "span",
                                                                                        [
                                                                                          _vm._v(
                                                                                            _vm._s(
                                                                                              item.description
                                                                                            )
                                                                                          )
                                                                                        ]
                                                                                      )
                                                                                    ]
                                                                                  )
                                                                                ]
                                                                              : _vm._e()
                                                                          ],
                                                                          2
                                                                        ),
                                                                        _vm._v(
                                                                          " "
                                                                        ),
                                                                        _c(
                                                                          "div",
                                                                          {
                                                                            staticClass:
                                                                              "field-divider"
                                                                          }
                                                                        ),
                                                                        _vm._v(
                                                                          " "
                                                                        ),
                                                                        _c(
                                                                          "div",
                                                                          {
                                                                            staticClass:
                                                                              "field-value py-2"
                                                                          },
                                                                          [
                                                                            !(
                                                                              item.field_name in
                                                                              _vm
                                                                                .data[
                                                                                "organisation"
                                                                              ]
                                                                            ) ||
                                                                            !_vm
                                                                              .data[
                                                                              "organisation"
                                                                            ][
                                                                              item
                                                                                .field_name
                                                                            ]
                                                                              ? [
                                                                                  _vm._v(
                                                                                    "\n                            -\n                          "
                                                                                  )
                                                                                ]
                                                                              : item.data_type ==
                                                                                "date"
                                                                              ? [
                                                                                  _vm._v(
                                                                                    "\n                            " +
                                                                                      _vm._s(
                                                                                        _vm._f(
                                                                                          "formatDate"
                                                                                        )(
                                                                                          _vm
                                                                                            .data[
                                                                                            "organisation"
                                                                                          ][
                                                                                            item
                                                                                              .field_name
                                                                                          ]
                                                                                        )
                                                                                      ) +
                                                                                      "\n                            "
                                                                                  ),
                                                                                  item.portal_field_route
                                                                                    ? _c(
                                                                                        "v-btn",
                                                                                        {
                                                                                          attrs: {
                                                                                            color:
                                                                                              "info",
                                                                                            icon:
                                                                                              "",
                                                                                            href: _vm.route(
                                                                                              item.portal_field_route.module.toLowerCase() +
                                                                                                ".linked",
                                                                                              {
                                                                                                field:
                                                                                                  item
                                                                                                    .portal_field_route
                                                                                                    .link_field_id,
                                                                                                value:
                                                                                                  _vm
                                                                                                    .data[
                                                                                                    "organisation"
                                                                                                  ][
                                                                                                    item
                                                                                                      .field_name
                                                                                                  ]
                                                                                              }
                                                                                            )
                                                                                          }
                                                                                        },
                                                                                        [
                                                                                          _c(
                                                                                            "v-icon",
                                                                                            [
                                                                                              _vm._v(
                                                                                                "mdi-link-variant"
                                                                                              )
                                                                                            ]
                                                                                          )
                                                                                        ],
                                                                                        1
                                                                                      )
                                                                                    : _vm._e()
                                                                                ]
                                                                              : item.data_type ==
                                                                                "number"
                                                                              ? [
                                                                                  _vm._v(
                                                                                    "\n                            " +
                                                                                      _vm._s(
                                                                                        _vm._f(
                                                                                          "formatNumber"
                                                                                        )(
                                                                                          _vm
                                                                                            .data[
                                                                                            "organisation"
                                                                                          ][
                                                                                            item
                                                                                              .field_name
                                                                                          ]
                                                                                        )
                                                                                      ) +
                                                                                      "\n                            "
                                                                                  ),
                                                                                  item.portal_field_route
                                                                                    ? _c(
                                                                                        "v-btn",
                                                                                        {
                                                                                          attrs: {
                                                                                            color:
                                                                                              "info",
                                                                                            icon:
                                                                                              "",
                                                                                            href: _vm.route(
                                                                                              item.portal_field_route.module.toLowerCase() +
                                                                                                ".linked",
                                                                                              {
                                                                                                field:
                                                                                                  item
                                                                                                    .portal_field_route
                                                                                                    .link_field_id,
                                                                                                value:
                                                                                                  _vm
                                                                                                    .data[
                                                                                                    "organisation"
                                                                                                  ][
                                                                                                    item
                                                                                                      .field_name
                                                                                                  ]
                                                                                              }
                                                                                            )
                                                                                          }
                                                                                        },
                                                                                        [
                                                                                          _c(
                                                                                            "v-icon",
                                                                                            [
                                                                                              _vm._v(
                                                                                                "mdi-link-variant"
                                                                                              )
                                                                                            ]
                                                                                          )
                                                                                        ],
                                                                                        1
                                                                                      )
                                                                                    : _vm._e()
                                                                                ]
                                                                              : item.data_type ==
                                                                                "coordinate"
                                                                              ? [
                                                                                  _vm._v(
                                                                                    "\n                            " +
                                                                                      _vm._s(
                                                                                        _vm._f(
                                                                                          "formatCoordinate"
                                                                                        )(
                                                                                          _vm
                                                                                            .data[
                                                                                            "organisation"
                                                                                          ][
                                                                                            item
                                                                                              .field_name
                                                                                          ]
                                                                                        )
                                                                                      ) +
                                                                                      "\n                            "
                                                                                  ),
                                                                                  item.portal_field_route
                                                                                    ? _c(
                                                                                        "v-btn",
                                                                                        {
                                                                                          attrs: {
                                                                                            color:
                                                                                              "info",
                                                                                            icon:
                                                                                              "",
                                                                                            href: _vm.route(
                                                                                              item.portal_field_route.module.toLowerCase() +
                                                                                                ".linked",
                                                                                              {
                                                                                                field:
                                                                                                  item
                                                                                                    .portal_field_route
                                                                                                    .link_field_id,
                                                                                                value:
                                                                                                  _vm
                                                                                                    .data[
                                                                                                    "organisation"
                                                                                                  ][
                                                                                                    item
                                                                                                      .field_name
                                                                                                  ]
                                                                                              }
                                                                                            )
                                                                                          }
                                                                                        },
                                                                                        [
                                                                                          _c(
                                                                                            "v-icon",
                                                                                            [
                                                                                              _vm._v(
                                                                                                "mdi-link-variant"
                                                                                              )
                                                                                            ]
                                                                                          )
                                                                                        ],
                                                                                        1
                                                                                      )
                                                                                    : _vm._e()
                                                                                ]
                                                                              : _vm.isURL(
                                                                                  _vm
                                                                                    .data[
                                                                                    "organisation"
                                                                                  ][
                                                                                    item
                                                                                      .field_name
                                                                                  ]
                                                                                )
                                                                              ? [
                                                                                  _c(
                                                                                    "a",
                                                                                    {
                                                                                      attrs: {
                                                                                        href: _vm.isURL(
                                                                                          _vm
                                                                                            .data[
                                                                                            "organisation"
                                                                                          ][
                                                                                            item
                                                                                              .field_name
                                                                                          ]
                                                                                        )
                                                                                      }
                                                                                    },
                                                                                    [
                                                                                      _vm._v(
                                                                                        _vm._s(
                                                                                          _vm
                                                                                            .data[
                                                                                            "organisation"
                                                                                          ][
                                                                                            item
                                                                                              .field_name
                                                                                          ]
                                                                                        )
                                                                                      )
                                                                                    ]
                                                                                  )
                                                                                ]
                                                                              : [
                                                                                  _vm._v(
                                                                                    "\n                            " +
                                                                                      _vm._s(
                                                                                        _vm
                                                                                          .data[
                                                                                          "organisation"
                                                                                        ][
                                                                                          item
                                                                                            .field_name
                                                                                        ]
                                                                                      ) +
                                                                                      "\n                            "
                                                                                  ),
                                                                                  item.portal_field_route
                                                                                    ? _c(
                                                                                        "v-btn",
                                                                                        {
                                                                                          attrs: {
                                                                                            color:
                                                                                              "info",
                                                                                            icon:
                                                                                              "",
                                                                                            href: _vm.route(
                                                                                              item.portal_field_route.module.toLowerCase() +
                                                                                                ".linked",
                                                                                              {
                                                                                                field:
                                                                                                  item
                                                                                                    .portal_field_route
                                                                                                    .link_field_id,
                                                                                                value:
                                                                                                  _vm
                                                                                                    .data[
                                                                                                    "organisation"
                                                                                                  ][
                                                                                                    item
                                                                                                      .field_name
                                                                                                  ]
                                                                                              }
                                                                                            )
                                                                                          }
                                                                                        },
                                                                                        [
                                                                                          _c(
                                                                                            "v-icon",
                                                                                            [
                                                                                              _vm._v(
                                                                                                "mdi-link-variant"
                                                                                              )
                                                                                            ]
                                                                                          )
                                                                                        ],
                                                                                        1
                                                                                      )
                                                                                    : _vm._e()
                                                                                ]
                                                                          ],
                                                                          2
                                                                        )
                                                                      ]
                                                                    )
                                                                  }
                                                                )
                                                              }
                                                            }
                                                          ],
                                                          null,
                                                          true
                                                        )
                                                      })
                                                    ],
                                                    1
                                                  )
                                                : _vm._e()
                                            ]
                                          })
                                        ],
                                        2
                                      )
                                    ]
                                  : _vm._e()
                              ],
                              2
                            )
                          : block.module.includes("Ansprechpartner")
                          ? _c(
                              "div",
                              _vm._l(
                                _vm.getAnsprechpartnerArray(
                                  block.module
                                    .match(/(?![Ansprechpartner ])[\s\S]*/)[0]
                                    .toLowerCase()
                                ),
                                function(ap, index) {
                                  return _c(
                                    "v-row",
                                    { key: index },
                                    [
                                      _c("v-col", { attrs: { cols: "12" } }, [
                                        _c("h3", [
                                          _vm._v(
                                            "Ansprechpartner " +
                                              _vm._s(index + 1)
                                          )
                                        ])
                                      ]),
                                      _vm._v(" "),
                                      _vm._l(block.panel_columns, function(
                                        column,
                                        index
                                      ) {
                                        return [
                                          column.portal_fields.length > 0
                                            ? _c(
                                                "v-col",
                                                {
                                                  key: index,
                                                  attrs: {
                                                    cols: "12",
                                                    lg:
                                                      12 /
                                                      block.panel_columns.length
                                                  }
                                                },
                                                [
                                                  _c("v-data-iterator", {
                                                    attrs: {
                                                      "disable-filtering": "",
                                                      "disable-pagination": "",
                                                      "disable-sort": "",
                                                      "hide-default-footer": "",
                                                      items:
                                                        column.portal_fields
                                                    },
                                                    scopedSlots: _vm._u(
                                                      [
                                                        {
                                                          key: "default",
                                                          fn: function(ref) {
                                                            var items =
                                                              ref.items
                                                            return _vm._l(
                                                              items,
                                                              function(item) {
                                                                return _c(
                                                                  "div",
                                                                  {
                                                                    key:
                                                                      item.id,
                                                                    staticClass:
                                                                      "fields-item px-4 my-1"
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "div",
                                                                      {
                                                                        staticClass:
                                                                          "field-title py-2"
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          "\n                        " +
                                                                            _vm._s(
                                                                              item.field_name
                                                                            ) +
                                                                            "\n                        "
                                                                        ),
                                                                        item.description
                                                                          ? [
                                                                              _c(
                                                                                "v-tooltip",
                                                                                {
                                                                                  attrs: {
                                                                                    bottom:
                                                                                      ""
                                                                                  },
                                                                                  scopedSlots: _vm._u(
                                                                                    [
                                                                                      {
                                                                                        key:
                                                                                          "activator",
                                                                                        fn: function(
                                                                                          ref
                                                                                        ) {
                                                                                          var on =
                                                                                            ref.on
                                                                                          var attrs =
                                                                                            ref.attrs
                                                                                          return [
                                                                                            _c(
                                                                                              "v-btn",
                                                                                              _vm._g(
                                                                                                _vm._b(
                                                                                                  {
                                                                                                    attrs: {
                                                                                                      color:
                                                                                                        "info",
                                                                                                      icon:
                                                                                                        ""
                                                                                                    }
                                                                                                  },
                                                                                                  "v-btn",
                                                                                                  attrs,
                                                                                                  false
                                                                                                ),
                                                                                                on
                                                                                              ),
                                                                                              [
                                                                                                _c(
                                                                                                  "v-icon",
                                                                                                  [
                                                                                                    _vm._v(
                                                                                                      "mdi-information-outline"
                                                                                                    )
                                                                                                  ]
                                                                                                )
                                                                                              ],
                                                                                              1
                                                                                            )
                                                                                          ]
                                                                                        }
                                                                                      }
                                                                                    ],
                                                                                    null,
                                                                                    true
                                                                                  )
                                                                                },
                                                                                [
                                                                                  _vm._v(
                                                                                    " "
                                                                                  ),
                                                                                  _c(
                                                                                    "span",
                                                                                    [
                                                                                      _vm._v(
                                                                                        _vm._s(
                                                                                          item.description
                                                                                        )
                                                                                      )
                                                                                    ]
                                                                                  )
                                                                                ]
                                                                              )
                                                                            ]
                                                                          : _vm._e()
                                                                      ],
                                                                      2
                                                                    ),
                                                                    _vm._v(" "),
                                                                    _c("div", {
                                                                      staticClass:
                                                                        "field-divider"
                                                                    }),
                                                                    _vm._v(" "),
                                                                    _c(
                                                                      "div",
                                                                      {
                                                                        staticClass:
                                                                          "field-value py-2"
                                                                      },
                                                                      [
                                                                        !(
                                                                          item.field_name in
                                                                          ap
                                                                        ) ||
                                                                        !ap[
                                                                          item
                                                                            .field_name
                                                                        ]
                                                                          ? [
                                                                              _vm._v(
                                                                                "\n                          -\n                        "
                                                                              )
                                                                            ]
                                                                          : item.data_type ==
                                                                            "date"
                                                                          ? [
                                                                              _vm._v(
                                                                                "\n                          " +
                                                                                  _vm._s(
                                                                                    _vm._f(
                                                                                      "formatDate"
                                                                                    )(
                                                                                      ap[
                                                                                        item
                                                                                          .field_name
                                                                                      ]
                                                                                    )
                                                                                  ) +
                                                                                  "\n                          "
                                                                              ),
                                                                              item.portal_field_route
                                                                                ? _c(
                                                                                    "v-btn",
                                                                                    {
                                                                                      attrs: {
                                                                                        color:
                                                                                          "info",
                                                                                        icon:
                                                                                          "",
                                                                                        href: _vm.route(
                                                                                          item.portal_field_route.module.toLowerCase() +
                                                                                            ".linked",
                                                                                          {
                                                                                            field:
                                                                                              item
                                                                                                .portal_field_route
                                                                                                .link_field_id,
                                                                                            value:
                                                                                              ap[
                                                                                                item
                                                                                                  .field_name
                                                                                              ]
                                                                                          }
                                                                                        )
                                                                                      }
                                                                                    },
                                                                                    [
                                                                                      _c(
                                                                                        "v-icon",
                                                                                        [
                                                                                          _vm._v(
                                                                                            "mdi-link-variant"
                                                                                          )
                                                                                        ]
                                                                                      )
                                                                                    ],
                                                                                    1
                                                                                  )
                                                                                : _vm._e()
                                                                            ]
                                                                          : item.data_type ==
                                                                            "number"
                                                                          ? [
                                                                              _vm._v(
                                                                                "\n                          " +
                                                                                  _vm._s(
                                                                                    _vm._f(
                                                                                      "formatNumber"
                                                                                    )(
                                                                                      ap[
                                                                                        item
                                                                                          .field_name
                                                                                      ]
                                                                                    )
                                                                                  ) +
                                                                                  "\n                          "
                                                                              ),
                                                                              item.portal_field_route
                                                                                ? _c(
                                                                                    "v-btn",
                                                                                    {
                                                                                      attrs: {
                                                                                        color:
                                                                                          "info",
                                                                                        icon:
                                                                                          "",
                                                                                        href: _vm.route(
                                                                                          item.portal_field_route.module.toLowerCase() +
                                                                                            ".linked",
                                                                                          {
                                                                                            field:
                                                                                              item
                                                                                                .portal_field_route
                                                                                                .link_field_id,
                                                                                            value:
                                                                                              ap[
                                                                                                item
                                                                                                  .field_name
                                                                                              ]
                                                                                          }
                                                                                        )
                                                                                      }
                                                                                    },
                                                                                    [
                                                                                      _c(
                                                                                        "v-icon",
                                                                                        [
                                                                                          _vm._v(
                                                                                            "mdi-link-variant"
                                                                                          )
                                                                                        ]
                                                                                      )
                                                                                    ],
                                                                                    1
                                                                                  )
                                                                                : _vm._e()
                                                                            ]
                                                                          : item.data_type ==
                                                                            "coordinate"
                                                                          ? [
                                                                              _vm._v(
                                                                                "\n                          " +
                                                                                  _vm._s(
                                                                                    _vm._f(
                                                                                      "formatCoordinate"
                                                                                    )(
                                                                                      ap[
                                                                                        item
                                                                                          .field_name
                                                                                      ]
                                                                                    )
                                                                                  ) +
                                                                                  "\n                          "
                                                                              ),
                                                                              item.portal_field_route
                                                                                ? _c(
                                                                                    "v-btn",
                                                                                    {
                                                                                      attrs: {
                                                                                        color:
                                                                                          "info",
                                                                                        icon:
                                                                                          "",
                                                                                        href: _vm.route(
                                                                                          item.portal_field_route.module.toLowerCase() +
                                                                                            ".linked",
                                                                                          {
                                                                                            field:
                                                                                              item
                                                                                                .portal_field_route
                                                                                                .link_field_id,
                                                                                            value:
                                                                                              ap[
                                                                                                item
                                                                                                  .field_name
                                                                                              ]
                                                                                          }
                                                                                        )
                                                                                      }
                                                                                    },
                                                                                    [
                                                                                      _c(
                                                                                        "v-icon",
                                                                                        [
                                                                                          _vm._v(
                                                                                            "mdi-link-variant"
                                                                                          )
                                                                                        ]
                                                                                      )
                                                                                    ],
                                                                                    1
                                                                                  )
                                                                                : _vm._e()
                                                                            ]
                                                                          : _vm.isURL(
                                                                              ap[
                                                                                item
                                                                                  .field_name
                                                                              ]
                                                                            )
                                                                          ? [
                                                                              _c(
                                                                                "a",
                                                                                {
                                                                                  attrs: {
                                                                                    href: _vm.isURL(
                                                                                      ap[
                                                                                        item
                                                                                          .field_name
                                                                                      ]
                                                                                    )
                                                                                  }
                                                                                },
                                                                                [
                                                                                  _vm._v(
                                                                                    _vm._s(
                                                                                      ap[
                                                                                        item
                                                                                          .field_name
                                                                                      ]
                                                                                    )
                                                                                  )
                                                                                ]
                                                                              )
                                                                            ]
                                                                          : [
                                                                              _vm._v(
                                                                                "\n                          " +
                                                                                  _vm._s(
                                                                                    ap[
                                                                                      item
                                                                                        .field_name
                                                                                    ]
                                                                                  ) +
                                                                                  "\n                          "
                                                                              ),
                                                                              item.portal_field_route
                                                                                ? _c(
                                                                                    "v-btn",
                                                                                    {
                                                                                      attrs: {
                                                                                        color:
                                                                                          "info",
                                                                                        icon:
                                                                                          "",
                                                                                        href: _vm.route(
                                                                                          item.portal_field_route.module.toLowerCase() +
                                                                                            ".linked",
                                                                                          {
                                                                                            field:
                                                                                              item
                                                                                                .portal_field_route
                                                                                                .link_field_id,
                                                                                            value:
                                                                                              ap[
                                                                                                item
                                                                                                  .field_name
                                                                                              ]
                                                                                          }
                                                                                        )
                                                                                      }
                                                                                    },
                                                                                    [
                                                                                      _c(
                                                                                        "v-icon",
                                                                                        [
                                                                                          _vm._v(
                                                                                            "mdi-link-variant"
                                                                                          )
                                                                                        ]
                                                                                      )
                                                                                    ],
                                                                                    1
                                                                                  )
                                                                                : _vm._e()
                                                                            ]
                                                                      ],
                                                                      2
                                                                    )
                                                                  ]
                                                                )
                                                              }
                                                            )
                                                          }
                                                        }
                                                      ],
                                                      null,
                                                      true
                                                    )
                                                  })
                                                ],
                                                1
                                              )
                                            : _vm._e()
                                        ]
                                      })
                                    ],
                                    2
                                  )
                                }
                              ),
                              1
                            )
                          : block.module.includes("Windparks")
                          ? _c(
                              "div",
                              [
                                _vm.data["windparks"]
                                  ? _vm._l(_vm.data["windparks"], function(
                                      wp,
                                      index
                                    ) {
                                      return _c(
                                        "v-row",
                                        { key: index },
                                        [
                                          _c(
                                            "v-col",
                                            { attrs: { cols: "12" } },
                                            [
                                              _c("h3", [
                                                _vm._v(
                                                  "Windpark " +
                                                    _vm._s(index + 1)
                                                )
                                              ])
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _vm._l(block.panel_columns, function(
                                            column,
                                            index
                                          ) {
                                            return [
                                              column.portal_fields.length > 0
                                                ? _c(
                                                    "v-col",
                                                    {
                                                      key: index,
                                                      attrs: {
                                                        cols: "12",
                                                        lg:
                                                          12 /
                                                          block.panel_columns
                                                            .length
                                                      }
                                                    },
                                                    [
                                                      _c("v-data-iterator", {
                                                        attrs: {
                                                          "disable-filtering":
                                                            "",
                                                          "disable-pagination":
                                                            "",
                                                          "disable-sort": "",
                                                          "hide-default-footer":
                                                            "",
                                                          items:
                                                            column.portal_fields
                                                        },
                                                        scopedSlots: _vm._u(
                                                          [
                                                            {
                                                              key: "default",
                                                              fn: function(
                                                                ref
                                                              ) {
                                                                var items =
                                                                  ref.items
                                                                return _vm._l(
                                                                  items,
                                                                  function(
                                                                    item
                                                                  ) {
                                                                    return _c(
                                                                      "div",
                                                                      {
                                                                        key:
                                                                          item.id,
                                                                        staticClass:
                                                                          "fields-item px-4 my-1"
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "div",
                                                                          {
                                                                            staticClass:
                                                                              "field-title py-2"
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              "\n                          " +
                                                                                _vm._s(
                                                                                  item.field_name
                                                                                ) +
                                                                                "\n                          "
                                                                            ),
                                                                            item.description
                                                                              ? [
                                                                                  _c(
                                                                                    "v-tooltip",
                                                                                    {
                                                                                      attrs: {
                                                                                        bottom:
                                                                                          ""
                                                                                      },
                                                                                      scopedSlots: _vm._u(
                                                                                        [
                                                                                          {
                                                                                            key:
                                                                                              "activator",
                                                                                            fn: function(
                                                                                              ref
                                                                                            ) {
                                                                                              var on =
                                                                                                ref.on
                                                                                              var attrs =
                                                                                                ref.attrs
                                                                                              return [
                                                                                                _c(
                                                                                                  "v-btn",
                                                                                                  _vm._g(
                                                                                                    _vm._b(
                                                                                                      {
                                                                                                        attrs: {
                                                                                                          color:
                                                                                                            "info",
                                                                                                          icon:
                                                                                                            ""
                                                                                                        }
                                                                                                      },
                                                                                                      "v-btn",
                                                                                                      attrs,
                                                                                                      false
                                                                                                    ),
                                                                                                    on
                                                                                                  ),
                                                                                                  [
                                                                                                    _c(
                                                                                                      "v-icon",
                                                                                                      [
                                                                                                        _vm._v(
                                                                                                          "mdi-information-outline"
                                                                                                        )
                                                                                                      ]
                                                                                                    )
                                                                                                  ],
                                                                                                  1
                                                                                                )
                                                                                              ]
                                                                                            }
                                                                                          }
                                                                                        ],
                                                                                        null,
                                                                                        true
                                                                                      )
                                                                                    },
                                                                                    [
                                                                                      _vm._v(
                                                                                        " "
                                                                                      ),
                                                                                      _c(
                                                                                        "span",
                                                                                        [
                                                                                          _vm._v(
                                                                                            _vm._s(
                                                                                              item.description
                                                                                            )
                                                                                          )
                                                                                        ]
                                                                                      )
                                                                                    ]
                                                                                  )
                                                                                ]
                                                                              : _vm._e()
                                                                          ],
                                                                          2
                                                                        ),
                                                                        _vm._v(
                                                                          " "
                                                                        ),
                                                                        _c(
                                                                          "div",
                                                                          {
                                                                            staticClass:
                                                                              "field-divider"
                                                                          }
                                                                        ),
                                                                        _vm._v(
                                                                          " "
                                                                        ),
                                                                        _c(
                                                                          "div",
                                                                          {
                                                                            staticClass:
                                                                              "field-value py-2"
                                                                          },
                                                                          [
                                                                            !(
                                                                              item.field_name in
                                                                              wp
                                                                            ) ||
                                                                            !wp[
                                                                              item
                                                                                .field_name
                                                                            ]
                                                                              ? [
                                                                                  _vm._v(
                                                                                    "\n                            -\n                          "
                                                                                  )
                                                                                ]
                                                                              : item.data_type ==
                                                                                "date"
                                                                              ? [
                                                                                  _vm._v(
                                                                                    "\n                            " +
                                                                                      _vm._s(
                                                                                        _vm._f(
                                                                                          "formatDate"
                                                                                        )(
                                                                                          wp[
                                                                                            item
                                                                                              .field_name
                                                                                          ]
                                                                                        )
                                                                                      ) +
                                                                                      "\n                            "
                                                                                  ),
                                                                                  item.portal_field_route
                                                                                    ? _c(
                                                                                        "v-btn",
                                                                                        {
                                                                                          attrs: {
                                                                                            color:
                                                                                              "info",
                                                                                            icon:
                                                                                              "",
                                                                                            href: _vm.route(
                                                                                              item.portal_field_route.module.toLowerCase() +
                                                                                                ".linked",
                                                                                              {
                                                                                                field:
                                                                                                  item
                                                                                                    .portal_field_route
                                                                                                    .link_field_id,
                                                                                                value:
                                                                                                  wp[
                                                                                                    item
                                                                                                      .field_name
                                                                                                  ]
                                                                                              }
                                                                                            )
                                                                                          }
                                                                                        },
                                                                                        [
                                                                                          _c(
                                                                                            "v-icon",
                                                                                            [
                                                                                              _vm._v(
                                                                                                "mdi-link-variant"
                                                                                              )
                                                                                            ]
                                                                                          )
                                                                                        ],
                                                                                        1
                                                                                      )
                                                                                    : _vm._e()
                                                                                ]
                                                                              : item.data_type ==
                                                                                "number"
                                                                              ? [
                                                                                  _vm._v(
                                                                                    "\n                            " +
                                                                                      _vm._s(
                                                                                        _vm._f(
                                                                                          "formatNumber"
                                                                                        )(
                                                                                          wp[
                                                                                            item
                                                                                              .field_name
                                                                                          ]
                                                                                        )
                                                                                      ) +
                                                                                      "\n                            "
                                                                                  ),
                                                                                  item.portal_field_route
                                                                                    ? _c(
                                                                                        "v-btn",
                                                                                        {
                                                                                          attrs: {
                                                                                            color:
                                                                                              "info",
                                                                                            icon:
                                                                                              "",
                                                                                            href: _vm.route(
                                                                                              item.portal_field_route.module.toLowerCase() +
                                                                                                ".linked",
                                                                                              {
                                                                                                field:
                                                                                                  item
                                                                                                    .portal_field_route
                                                                                                    .link_field_id,
                                                                                                value:
                                                                                                  wp[
                                                                                                    item
                                                                                                      .field_name
                                                                                                  ]
                                                                                              }
                                                                                            )
                                                                                          }
                                                                                        },
                                                                                        [
                                                                                          _c(
                                                                                            "v-icon",
                                                                                            [
                                                                                              _vm._v(
                                                                                                "mdi-link-variant"
                                                                                              )
                                                                                            ]
                                                                                          )
                                                                                        ],
                                                                                        1
                                                                                      )
                                                                                    : _vm._e()
                                                                                ]
                                                                              : item.data_type ==
                                                                                "coordinate"
                                                                              ? [
                                                                                  _vm._v(
                                                                                    "\n                            " +
                                                                                      _vm._s(
                                                                                        _vm._f(
                                                                                          "formatCoordinate"
                                                                                        )(
                                                                                          wp[
                                                                                            item
                                                                                              .field_name
                                                                                          ]
                                                                                        )
                                                                                      ) +
                                                                                      "\n                            "
                                                                                  ),
                                                                                  item.portal_field_route
                                                                                    ? _c(
                                                                                        "v-btn",
                                                                                        {
                                                                                          attrs: {
                                                                                            color:
                                                                                              "info",
                                                                                            icon:
                                                                                              "",
                                                                                            href: _vm.route(
                                                                                              item.portal_field_route.module.toLowerCase() +
                                                                                                ".linked",
                                                                                              {
                                                                                                field:
                                                                                                  item
                                                                                                    .portal_field_route
                                                                                                    .link_field_id,
                                                                                                value:
                                                                                                  wp[
                                                                                                    item
                                                                                                      .field_name
                                                                                                  ]
                                                                                              }
                                                                                            )
                                                                                          }
                                                                                        },
                                                                                        [
                                                                                          _c(
                                                                                            "v-icon",
                                                                                            [
                                                                                              _vm._v(
                                                                                                "mdi-link-variant"
                                                                                              )
                                                                                            ]
                                                                                          )
                                                                                        ],
                                                                                        1
                                                                                      )
                                                                                    : _vm._e()
                                                                                ]
                                                                              : _vm.isURL(
                                                                                  wp[
                                                                                    item
                                                                                      .field_name
                                                                                  ]
                                                                                )
                                                                              ? [
                                                                                  _c(
                                                                                    "a",
                                                                                    {
                                                                                      attrs: {
                                                                                        href: _vm.isURL(
                                                                                          wp[
                                                                                            item
                                                                                              .field_name
                                                                                          ]
                                                                                        )
                                                                                      }
                                                                                    },
                                                                                    [
                                                                                      _vm._v(
                                                                                        _vm._s(
                                                                                          wp[
                                                                                            item
                                                                                              .field_name
                                                                                          ]
                                                                                        )
                                                                                      )
                                                                                    ]
                                                                                  )
                                                                                ]
                                                                              : [
                                                                                  _vm._v(
                                                                                    "\n                            " +
                                                                                      _vm._s(
                                                                                        wp[
                                                                                          item
                                                                                            .field_name
                                                                                        ]
                                                                                      ) +
                                                                                      "\n                            "
                                                                                  ),
                                                                                  item.portal_field_route
                                                                                    ? _c(
                                                                                        "v-btn",
                                                                                        {
                                                                                          attrs: {
                                                                                            color:
                                                                                              "info",
                                                                                            icon:
                                                                                              "",
                                                                                            href: _vm.route(
                                                                                              item.portal_field_route.module.toLowerCase() +
                                                                                                ".linked",
                                                                                              {
                                                                                                field:
                                                                                                  item
                                                                                                    .portal_field_route
                                                                                                    .link_field_id,
                                                                                                value:
                                                                                                  wp[
                                                                                                    item
                                                                                                      .field_name
                                                                                                  ]
                                                                                              }
                                                                                            )
                                                                                          }
                                                                                        },
                                                                                        [
                                                                                          _c(
                                                                                            "v-icon",
                                                                                            [
                                                                                              _vm._v(
                                                                                                "mdi-link-variant"
                                                                                              )
                                                                                            ]
                                                                                          )
                                                                                        ],
                                                                                        1
                                                                                      )
                                                                                    : _vm._e()
                                                                                ]
                                                                          ],
                                                                          2
                                                                        )
                                                                      ]
                                                                    )
                                                                  }
                                                                )
                                                              }
                                                            }
                                                          ],
                                                          null,
                                                          true
                                                        )
                                                      })
                                                    ],
                                                    1
                                                  )
                                                : _vm._e()
                                            ]
                                          })
                                        ],
                                        2
                                      )
                                    })
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm.data["windpark"]
                                  ? [
                                      _c(
                                        "v-row",
                                        [
                                          _vm._l(block.panel_columns, function(
                                            column,
                                            index
                                          ) {
                                            return [
                                              column.portal_fields.length > 0
                                                ? _c(
                                                    "v-col",
                                                    {
                                                      key: index,
                                                      attrs: {
                                                        cols: "12",
                                                        lg:
                                                          12 /
                                                          block.panel_columns
                                                            .length
                                                      }
                                                    },
                                                    [
                                                      _c("v-data-iterator", {
                                                        attrs: {
                                                          "disable-filtering":
                                                            "",
                                                          "disable-pagination":
                                                            "",
                                                          "disable-sort": "",
                                                          "hide-default-footer":
                                                            "",
                                                          items:
                                                            column.portal_fields
                                                        },
                                                        scopedSlots: _vm._u(
                                                          [
                                                            {
                                                              key: "default",
                                                              fn: function(
                                                                ref
                                                              ) {
                                                                var items =
                                                                  ref.items
                                                                return _vm._l(
                                                                  items,
                                                                  function(
                                                                    item
                                                                  ) {
                                                                    return _c(
                                                                      "div",
                                                                      {
                                                                        key:
                                                                          item.id,
                                                                        staticClass:
                                                                          "fields-item px-4 my-1"
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "div",
                                                                          {
                                                                            staticClass:
                                                                              "field-title py-2"
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              "\n                          " +
                                                                                _vm._s(
                                                                                  item.field_name
                                                                                ) +
                                                                                "\n                          "
                                                                            ),
                                                                            item.description
                                                                              ? [
                                                                                  _c(
                                                                                    "v-tooltip",
                                                                                    {
                                                                                      attrs: {
                                                                                        bottom:
                                                                                          ""
                                                                                      },
                                                                                      scopedSlots: _vm._u(
                                                                                        [
                                                                                          {
                                                                                            key:
                                                                                              "activator",
                                                                                            fn: function(
                                                                                              ref
                                                                                            ) {
                                                                                              var on =
                                                                                                ref.on
                                                                                              var attrs =
                                                                                                ref.attrs
                                                                                              return [
                                                                                                _c(
                                                                                                  "v-btn",
                                                                                                  _vm._g(
                                                                                                    _vm._b(
                                                                                                      {
                                                                                                        attrs: {
                                                                                                          color:
                                                                                                            "info",
                                                                                                          icon:
                                                                                                            ""
                                                                                                        }
                                                                                                      },
                                                                                                      "v-btn",
                                                                                                      attrs,
                                                                                                      false
                                                                                                    ),
                                                                                                    on
                                                                                                  ),
                                                                                                  [
                                                                                                    _c(
                                                                                                      "v-icon",
                                                                                                      [
                                                                                                        _vm._v(
                                                                                                          "mdi-information-outline"
                                                                                                        )
                                                                                                      ]
                                                                                                    )
                                                                                                  ],
                                                                                                  1
                                                                                                )
                                                                                              ]
                                                                                            }
                                                                                          }
                                                                                        ],
                                                                                        null,
                                                                                        true
                                                                                      )
                                                                                    },
                                                                                    [
                                                                                      _vm._v(
                                                                                        " "
                                                                                      ),
                                                                                      _c(
                                                                                        "span",
                                                                                        [
                                                                                          _vm._v(
                                                                                            _vm._s(
                                                                                              item.description
                                                                                            )
                                                                                          )
                                                                                        ]
                                                                                      )
                                                                                    ]
                                                                                  )
                                                                                ]
                                                                              : _vm._e()
                                                                          ],
                                                                          2
                                                                        ),
                                                                        _vm._v(
                                                                          " "
                                                                        ),
                                                                        _c(
                                                                          "div",
                                                                          {
                                                                            staticClass:
                                                                              "field-divider"
                                                                          }
                                                                        ),
                                                                        _vm._v(
                                                                          " "
                                                                        ),
                                                                        _c(
                                                                          "div",
                                                                          {
                                                                            staticClass:
                                                                              "field-value py-2"
                                                                          },
                                                                          [
                                                                            !(
                                                                              item.field_name in
                                                                              _vm
                                                                                .data[
                                                                                "windpark"
                                                                              ]
                                                                            ) ||
                                                                            !_vm
                                                                              .data[
                                                                              "windpark"
                                                                            ][
                                                                              item
                                                                                .field_name
                                                                            ]
                                                                              ? [
                                                                                  _vm._v(
                                                                                    "\n                            -\n                          "
                                                                                  )
                                                                                ]
                                                                              : item.data_type ==
                                                                                "date"
                                                                              ? [
                                                                                  _vm._v(
                                                                                    "\n                            " +
                                                                                      _vm._s(
                                                                                        _vm._f(
                                                                                          "formatDate"
                                                                                        )(
                                                                                          _vm
                                                                                            .data[
                                                                                            "windpark"
                                                                                          ][
                                                                                            item
                                                                                              .field_name
                                                                                          ]
                                                                                        )
                                                                                      ) +
                                                                                      "\n                            "
                                                                                  ),
                                                                                  item.portal_field_route
                                                                                    ? _c(
                                                                                        "v-btn",
                                                                                        {
                                                                                          attrs: {
                                                                                            color:
                                                                                              "info",
                                                                                            icon:
                                                                                              "",
                                                                                            href: _vm.route(
                                                                                              item.portal_field_route.module.toLowerCase() +
                                                                                                ".linked",
                                                                                              {
                                                                                                field:
                                                                                                  item
                                                                                                    .portal_field_route
                                                                                                    .link_field_id,
                                                                                                value:
                                                                                                  _vm
                                                                                                    .data[
                                                                                                    "windpark"
                                                                                                  ][
                                                                                                    item
                                                                                                      .field_name
                                                                                                  ]
                                                                                              }
                                                                                            )
                                                                                          }
                                                                                        },
                                                                                        [
                                                                                          _c(
                                                                                            "v-icon",
                                                                                            [
                                                                                              _vm._v(
                                                                                                "mdi-link-variant"
                                                                                              )
                                                                                            ]
                                                                                          )
                                                                                        ],
                                                                                        1
                                                                                      )
                                                                                    : _vm._e()
                                                                                ]
                                                                              : item.data_type ==
                                                                                "number"
                                                                              ? [
                                                                                  _vm._v(
                                                                                    "\n                            " +
                                                                                      _vm._s(
                                                                                        _vm._f(
                                                                                          "formatNumber"
                                                                                        )(
                                                                                          _vm
                                                                                            .data[
                                                                                            "windpark"
                                                                                          ][
                                                                                            item
                                                                                              .field_name
                                                                                          ]
                                                                                        )
                                                                                      ) +
                                                                                      "\n                            "
                                                                                  ),
                                                                                  item.portal_field_route
                                                                                    ? _c(
                                                                                        "v-btn",
                                                                                        {
                                                                                          attrs: {
                                                                                            color:
                                                                                              "info",
                                                                                            icon:
                                                                                              "",
                                                                                            href: _vm.route(
                                                                                              item.portal_field_route.module.toLowerCase() +
                                                                                                ".linked",
                                                                                              {
                                                                                                field:
                                                                                                  item
                                                                                                    .portal_field_route
                                                                                                    .link_field_id,
                                                                                                value:
                                                                                                  _vm
                                                                                                    .data[
                                                                                                    "windpark"
                                                                                                  ][
                                                                                                    item
                                                                                                      .field_name
                                                                                                  ]
                                                                                              }
                                                                                            )
                                                                                          }
                                                                                        },
                                                                                        [
                                                                                          _c(
                                                                                            "v-icon",
                                                                                            [
                                                                                              _vm._v(
                                                                                                "mdi-link-variant"
                                                                                              )
                                                                                            ]
                                                                                          )
                                                                                        ],
                                                                                        1
                                                                                      )
                                                                                    : _vm._e()
                                                                                ]
                                                                              : item.data_type ==
                                                                                "coordinate"
                                                                              ? [
                                                                                  _vm._v(
                                                                                    "\n                            " +
                                                                                      _vm._s(
                                                                                        _vm._f(
                                                                                          "formatCoordinate"
                                                                                        )(
                                                                                          _vm
                                                                                            .data[
                                                                                            "windpark"
                                                                                          ][
                                                                                            item
                                                                                              .field_name
                                                                                          ]
                                                                                        )
                                                                                      ) +
                                                                                      "\n                            "
                                                                                  ),
                                                                                  item.portal_field_route
                                                                                    ? _c(
                                                                                        "v-btn",
                                                                                        {
                                                                                          attrs: {
                                                                                            color:
                                                                                              "info",
                                                                                            icon:
                                                                                              "",
                                                                                            href: _vm.route(
                                                                                              item.portal_field_route.module.toLowerCase() +
                                                                                                ".linked",
                                                                                              {
                                                                                                field:
                                                                                                  item
                                                                                                    .portal_field_route
                                                                                                    .link_field_id,
                                                                                                value:
                                                                                                  _vm
                                                                                                    .data[
                                                                                                    "windpark"
                                                                                                  ][
                                                                                                    item
                                                                                                      .field_name
                                                                                                  ]
                                                                                              }
                                                                                            )
                                                                                          }
                                                                                        },
                                                                                        [
                                                                                          _c(
                                                                                            "v-icon",
                                                                                            [
                                                                                              _vm._v(
                                                                                                "mdi-link-variant"
                                                                                              )
                                                                                            ]
                                                                                          )
                                                                                        ],
                                                                                        1
                                                                                      )
                                                                                    : _vm._e()
                                                                                ]
                                                                              : _vm.isURL(
                                                                                  _vm
                                                                                    .data[
                                                                                    "windpark"
                                                                                  ][
                                                                                    item
                                                                                      .field_name
                                                                                  ]
                                                                                )
                                                                              ? [
                                                                                  _c(
                                                                                    "a",
                                                                                    {
                                                                                      attrs: {
                                                                                        href: _vm.isURL(
                                                                                          _vm
                                                                                            .data[
                                                                                            "windpark"
                                                                                          ][
                                                                                            item
                                                                                              .field_name
                                                                                          ]
                                                                                        )
                                                                                      }
                                                                                    },
                                                                                    [
                                                                                      _vm._v(
                                                                                        _vm._s(
                                                                                          _vm
                                                                                            .data[
                                                                                            "windpark"
                                                                                          ][
                                                                                            item
                                                                                              .field_name
                                                                                          ]
                                                                                        )
                                                                                      )
                                                                                    ]
                                                                                  )
                                                                                ]
                                                                              : [
                                                                                  _vm._v(
                                                                                    "\n                            " +
                                                                                      _vm._s(
                                                                                        _vm
                                                                                          .data[
                                                                                          "windpark"
                                                                                        ][
                                                                                          item
                                                                                            .field_name
                                                                                        ]
                                                                                      ) +
                                                                                      "\n                            "
                                                                                  ),
                                                                                  item.portal_field_route
                                                                                    ? _c(
                                                                                        "v-btn",
                                                                                        {
                                                                                          attrs: {
                                                                                            color:
                                                                                              "info",
                                                                                            icon:
                                                                                              "",
                                                                                            href: _vm.route(
                                                                                              item.portal_field_route.module.toLowerCase() +
                                                                                                ".linked",
                                                                                              {
                                                                                                field:
                                                                                                  item
                                                                                                    .portal_field_route
                                                                                                    .link_field_id,
                                                                                                value:
                                                                                                  _vm
                                                                                                    .data[
                                                                                                    "windpark"
                                                                                                  ][
                                                                                                    item
                                                                                                      .field_name
                                                                                                  ]
                                                                                              }
                                                                                            )
                                                                                          }
                                                                                        },
                                                                                        [
                                                                                          _c(
                                                                                            "v-icon",
                                                                                            [
                                                                                              _vm._v(
                                                                                                "mdi-link-variant"
                                                                                              )
                                                                                            ]
                                                                                          )
                                                                                        ],
                                                                                        1
                                                                                      )
                                                                                    : _vm._e()
                                                                                ]
                                                                          ],
                                                                          2
                                                                        )
                                                                      ]
                                                                    )
                                                                  }
                                                                )
                                                              }
                                                            }
                                                          ],
                                                          null,
                                                          true
                                                        )
                                                      })
                                                    ],
                                                    1
                                                  )
                                                : _vm._e()
                                            ]
                                          })
                                        ],
                                        2
                                      )
                                    ]
                                  : _vm._e()
                              ],
                              2
                            )
                          : block.module.includes("Photovoltaikparks")
                          ? _c(
                              "div",
                              [
                                _vm.data["pv_parks"]
                                  ? _vm._l(_vm.data["pv_parks"], function(
                                      pv_park,
                                      index
                                    ) {
                                      return _c(
                                        "v-row",
                                        { key: index },
                                        [
                                          _c(
                                            "v-col",
                                            { attrs: { cols: "12" } },
                                            [
                                              _c("h3", [
                                                _vm._v(
                                                  "Windpark " +
                                                    _vm._s(index + 1)
                                                )
                                              ])
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _vm._l(block.panel_columns, function(
                                            column,
                                            index
                                          ) {
                                            return [
                                              column.portal_fields.length > 0
                                                ? _c(
                                                    "v-col",
                                                    {
                                                      key: index,
                                                      attrs: {
                                                        cols: "12",
                                                        lg:
                                                          12 /
                                                          block.panel_columns
                                                            .length
                                                      }
                                                    },
                                                    [
                                                      _c("v-data-iterator", {
                                                        attrs: {
                                                          "disable-filtering":
                                                            "",
                                                          "disable-pagination":
                                                            "",
                                                          "disable-sort": "",
                                                          "hide-default-footer":
                                                            "",
                                                          items:
                                                            column.portal_fields
                                                        },
                                                        scopedSlots: _vm._u(
                                                          [
                                                            {
                                                              key: "default",
                                                              fn: function(
                                                                ref
                                                              ) {
                                                                var items =
                                                                  ref.items
                                                                return _vm._l(
                                                                  items,
                                                                  function(
                                                                    item
                                                                  ) {
                                                                    return _c(
                                                                      "div",
                                                                      {
                                                                        key:
                                                                          item.id,
                                                                        staticClass:
                                                                          "fields-item px-4 my-1"
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "div",
                                                                          {
                                                                            staticClass:
                                                                              "field-title py-2"
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              "\n                          " +
                                                                                _vm._s(
                                                                                  item.field_name
                                                                                ) +
                                                                                "\n                          "
                                                                            ),
                                                                            item.description
                                                                              ? [
                                                                                  _c(
                                                                                    "v-tooltip",
                                                                                    {
                                                                                      attrs: {
                                                                                        bottom:
                                                                                          ""
                                                                                      },
                                                                                      scopedSlots: _vm._u(
                                                                                        [
                                                                                          {
                                                                                            key:
                                                                                              "activator",
                                                                                            fn: function(
                                                                                              ref
                                                                                            ) {
                                                                                              var on =
                                                                                                ref.on
                                                                                              var attrs =
                                                                                                ref.attrs
                                                                                              return [
                                                                                                _c(
                                                                                                  "v-btn",
                                                                                                  _vm._g(
                                                                                                    _vm._b(
                                                                                                      {
                                                                                                        attrs: {
                                                                                                          color:
                                                                                                            "info",
                                                                                                          icon:
                                                                                                            ""
                                                                                                        }
                                                                                                      },
                                                                                                      "v-btn",
                                                                                                      attrs,
                                                                                                      false
                                                                                                    ),
                                                                                                    on
                                                                                                  ),
                                                                                                  [
                                                                                                    _c(
                                                                                                      "v-icon",
                                                                                                      [
                                                                                                        _vm._v(
                                                                                                          "mdi-information-outline"
                                                                                                        )
                                                                                                      ]
                                                                                                    )
                                                                                                  ],
                                                                                                  1
                                                                                                )
                                                                                              ]
                                                                                            }
                                                                                          }
                                                                                        ],
                                                                                        null,
                                                                                        true
                                                                                      )
                                                                                    },
                                                                                    [
                                                                                      _vm._v(
                                                                                        " "
                                                                                      ),
                                                                                      _c(
                                                                                        "span",
                                                                                        [
                                                                                          _vm._v(
                                                                                            _vm._s(
                                                                                              item.description
                                                                                            )
                                                                                          )
                                                                                        ]
                                                                                      )
                                                                                    ]
                                                                                  )
                                                                                ]
                                                                              : _vm._e()
                                                                          ],
                                                                          2
                                                                        ),
                                                                        _vm._v(
                                                                          " "
                                                                        ),
                                                                        _c(
                                                                          "div",
                                                                          {
                                                                            staticClass:
                                                                              "field-divider"
                                                                          }
                                                                        ),
                                                                        _vm._v(
                                                                          " "
                                                                        ),
                                                                        _c(
                                                                          "div",
                                                                          {
                                                                            staticClass:
                                                                              "field-value py-2"
                                                                          },
                                                                          [
                                                                            !(
                                                                              item.field_name in
                                                                              pv_park
                                                                            ) ||
                                                                            !pv_park[
                                                                              item
                                                                                .field_name
                                                                            ]
                                                                              ? [
                                                                                  _vm._v(
                                                                                    "\n                            -\n                          "
                                                                                  )
                                                                                ]
                                                                              : item.data_type ==
                                                                                "date"
                                                                              ? [
                                                                                  _vm._v(
                                                                                    "\n                            " +
                                                                                      _vm._s(
                                                                                        _vm._f(
                                                                                          "formatDate"
                                                                                        )(
                                                                                          pv_park[
                                                                                            item
                                                                                              .field_name
                                                                                          ]
                                                                                        )
                                                                                      ) +
                                                                                      "\n                            "
                                                                                  ),
                                                                                  item.portal_field_route
                                                                                    ? _c(
                                                                                        "v-btn",
                                                                                        {
                                                                                          attrs: {
                                                                                            color:
                                                                                              "info",
                                                                                            icon:
                                                                                              "",
                                                                                            href: _vm.route(
                                                                                              item.portal_field_route.module.toLowerCase() +
                                                                                                ".linked",
                                                                                              {
                                                                                                field:
                                                                                                  item
                                                                                                    .portal_field_route
                                                                                                    .link_field_id,
                                                                                                value:
                                                                                                  pv_park[
                                                                                                    item
                                                                                                      .field_name
                                                                                                  ]
                                                                                              }
                                                                                            )
                                                                                          }
                                                                                        },
                                                                                        [
                                                                                          _c(
                                                                                            "v-icon",
                                                                                            [
                                                                                              _vm._v(
                                                                                                "mdi-link-variant"
                                                                                              )
                                                                                            ]
                                                                                          )
                                                                                        ],
                                                                                        1
                                                                                      )
                                                                                    : _vm._e()
                                                                                ]
                                                                              : item.data_type ==
                                                                                "number"
                                                                              ? [
                                                                                  _vm._v(
                                                                                    "\n                            " +
                                                                                      _vm._s(
                                                                                        _vm._f(
                                                                                          "formatNumber"
                                                                                        )(
                                                                                          pv_park[
                                                                                            item
                                                                                              .field_name
                                                                                          ]
                                                                                        )
                                                                                      ) +
                                                                                      "\n                            "
                                                                                  ),
                                                                                  item.portal_field_route
                                                                                    ? _c(
                                                                                        "v-btn",
                                                                                        {
                                                                                          attrs: {
                                                                                            color:
                                                                                              "info",
                                                                                            icon:
                                                                                              "",
                                                                                            href: _vm.route(
                                                                                              item.portal_field_route.module.toLowerCase() +
                                                                                                ".linked",
                                                                                              {
                                                                                                field:
                                                                                                  item
                                                                                                    .portal_field_route
                                                                                                    .link_field_id,
                                                                                                value:
                                                                                                  pv_park[
                                                                                                    item
                                                                                                      .field_name
                                                                                                  ]
                                                                                              }
                                                                                            )
                                                                                          }
                                                                                        },
                                                                                        [
                                                                                          _c(
                                                                                            "v-icon",
                                                                                            [
                                                                                              _vm._v(
                                                                                                "mdi-link-variant"
                                                                                              )
                                                                                            ]
                                                                                          )
                                                                                        ],
                                                                                        1
                                                                                      )
                                                                                    : _vm._e()
                                                                                ]
                                                                              : item.data_type ==
                                                                                "coordinate"
                                                                              ? [
                                                                                  _vm._v(
                                                                                    "\n                            " +
                                                                                      _vm._s(
                                                                                        _vm._f(
                                                                                          "formatCoordinate"
                                                                                        )(
                                                                                          pv_park[
                                                                                            item
                                                                                              .field_name
                                                                                          ]
                                                                                        )
                                                                                      ) +
                                                                                      "\n                            "
                                                                                  ),
                                                                                  item.portal_field_route
                                                                                    ? _c(
                                                                                        "v-btn",
                                                                                        {
                                                                                          attrs: {
                                                                                            color:
                                                                                              "info",
                                                                                            icon:
                                                                                              "",
                                                                                            href: _vm.route(
                                                                                              item.portal_field_route.module.toLowerCase() +
                                                                                                ".linked",
                                                                                              {
                                                                                                field:
                                                                                                  item
                                                                                                    .portal_field_route
                                                                                                    .link_field_id,
                                                                                                value:
                                                                                                  pv_park[
                                                                                                    item
                                                                                                      .field_name
                                                                                                  ]
                                                                                              }
                                                                                            )
                                                                                          }
                                                                                        },
                                                                                        [
                                                                                          _c(
                                                                                            "v-icon",
                                                                                            [
                                                                                              _vm._v(
                                                                                                "mdi-link-variant"
                                                                                              )
                                                                                            ]
                                                                                          )
                                                                                        ],
                                                                                        1
                                                                                      )
                                                                                    : _vm._e()
                                                                                ]
                                                                              : _vm.isURL(
                                                                                  pv_park[
                                                                                    item
                                                                                      .field_name
                                                                                  ]
                                                                                )
                                                                              ? [
                                                                                  _c(
                                                                                    "a",
                                                                                    {
                                                                                      attrs: {
                                                                                        href: _vm.isURL(
                                                                                          pv_park[
                                                                                            item
                                                                                              .field_name
                                                                                          ]
                                                                                        )
                                                                                      }
                                                                                    },
                                                                                    [
                                                                                      _vm._v(
                                                                                        _vm._s(
                                                                                          pv_park[
                                                                                            item
                                                                                              .field_name
                                                                                          ]
                                                                                        )
                                                                                      )
                                                                                    ]
                                                                                  )
                                                                                ]
                                                                              : [
                                                                                  _vm._v(
                                                                                    "\n                            " +
                                                                                      _vm._s(
                                                                                        pv_park[
                                                                                          item
                                                                                            .field_name
                                                                                        ]
                                                                                      ) +
                                                                                      "\n                            "
                                                                                  ),
                                                                                  item.portal_field_route
                                                                                    ? _c(
                                                                                        "v-btn",
                                                                                        {
                                                                                          attrs: {
                                                                                            color:
                                                                                              "info",
                                                                                            icon:
                                                                                              "",
                                                                                            href: _vm.route(
                                                                                              item.portal_field_route.module.toLowerCase() +
                                                                                                ".linked",
                                                                                              {
                                                                                                field:
                                                                                                  item
                                                                                                    .portal_field_route
                                                                                                    .link_field_id,
                                                                                                value:
                                                                                                  pv_park[
                                                                                                    item
                                                                                                      .field_name
                                                                                                  ]
                                                                                              }
                                                                                            )
                                                                                          }
                                                                                        },
                                                                                        [
                                                                                          _c(
                                                                                            "v-icon",
                                                                                            [
                                                                                              _vm._v(
                                                                                                "mdi-link-variant"
                                                                                              )
                                                                                            ]
                                                                                          )
                                                                                        ],
                                                                                        1
                                                                                      )
                                                                                    : _vm._e()
                                                                                ]
                                                                          ],
                                                                          2
                                                                        )
                                                                      ]
                                                                    )
                                                                  }
                                                                )
                                                              }
                                                            }
                                                          ],
                                                          null,
                                                          true
                                                        )
                                                      })
                                                    ],
                                                    1
                                                  )
                                                : _vm._e()
                                            ]
                                          })
                                        ],
                                        2
                                      )
                                    })
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm.data["pv_park"]
                                  ? [
                                      _c(
                                        "v-row",
                                        [
                                          _vm._l(block.panel_columns, function(
                                            column,
                                            index
                                          ) {
                                            return [
                                              column.portal_fields.length > 0
                                                ? _c(
                                                    "v-col",
                                                    {
                                                      key: index,
                                                      attrs: {
                                                        cols: "12",
                                                        lg:
                                                          12 /
                                                          block.panel_columns
                                                            .length
                                                      }
                                                    },
                                                    [
                                                      _c("v-data-iterator", {
                                                        attrs: {
                                                          "disable-filtering":
                                                            "",
                                                          "disable-pagination":
                                                            "",
                                                          "disable-sort": "",
                                                          "hide-default-footer":
                                                            "",
                                                          items:
                                                            column.portal_fields
                                                        },
                                                        scopedSlots: _vm._u(
                                                          [
                                                            {
                                                              key: "default",
                                                              fn: function(
                                                                ref
                                                              ) {
                                                                var items =
                                                                  ref.items
                                                                return _vm._l(
                                                                  items,
                                                                  function(
                                                                    item
                                                                  ) {
                                                                    return _c(
                                                                      "div",
                                                                      {
                                                                        key:
                                                                          item.id,
                                                                        staticClass:
                                                                          "fields-item px-4 my-1"
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "div",
                                                                          {
                                                                            staticClass:
                                                                              "field-title py-2"
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              "\n                          " +
                                                                                _vm._s(
                                                                                  item.field_name
                                                                                ) +
                                                                                "\n                          "
                                                                            ),
                                                                            item.description
                                                                              ? [
                                                                                  _c(
                                                                                    "v-tooltip",
                                                                                    {
                                                                                      attrs: {
                                                                                        bottom:
                                                                                          ""
                                                                                      },
                                                                                      scopedSlots: _vm._u(
                                                                                        [
                                                                                          {
                                                                                            key:
                                                                                              "activator",
                                                                                            fn: function(
                                                                                              ref
                                                                                            ) {
                                                                                              var on =
                                                                                                ref.on
                                                                                              var attrs =
                                                                                                ref.attrs
                                                                                              return [
                                                                                                _c(
                                                                                                  "v-btn",
                                                                                                  _vm._g(
                                                                                                    _vm._b(
                                                                                                      {
                                                                                                        attrs: {
                                                                                                          color:
                                                                                                            "info",
                                                                                                          icon:
                                                                                                            ""
                                                                                                        }
                                                                                                      },
                                                                                                      "v-btn",
                                                                                                      attrs,
                                                                                                      false
                                                                                                    ),
                                                                                                    on
                                                                                                  ),
                                                                                                  [
                                                                                                    _c(
                                                                                                      "v-icon",
                                                                                                      [
                                                                                                        _vm._v(
                                                                                                          "mdi-information-outline"
                                                                                                        )
                                                                                                      ]
                                                                                                    )
                                                                                                  ],
                                                                                                  1
                                                                                                )
                                                                                              ]
                                                                                            }
                                                                                          }
                                                                                        ],
                                                                                        null,
                                                                                        true
                                                                                      )
                                                                                    },
                                                                                    [
                                                                                      _vm._v(
                                                                                        " "
                                                                                      ),
                                                                                      _c(
                                                                                        "span",
                                                                                        [
                                                                                          _vm._v(
                                                                                            _vm._s(
                                                                                              item.description
                                                                                            )
                                                                                          )
                                                                                        ]
                                                                                      )
                                                                                    ]
                                                                                  )
                                                                                ]
                                                                              : _vm._e()
                                                                          ],
                                                                          2
                                                                        ),
                                                                        _vm._v(
                                                                          " "
                                                                        ),
                                                                        _c(
                                                                          "div",
                                                                          {
                                                                            staticClass:
                                                                              "field-divider"
                                                                          }
                                                                        ),
                                                                        _vm._v(
                                                                          " "
                                                                        ),
                                                                        _c(
                                                                          "div",
                                                                          {
                                                                            staticClass:
                                                                              "field-value py-2"
                                                                          },
                                                                          [
                                                                            !(
                                                                              item.field_name in
                                                                              _vm
                                                                                .data[
                                                                                "pv_park"
                                                                              ]
                                                                            ) ||
                                                                            !_vm
                                                                              .data[
                                                                              "pv_park"
                                                                            ][
                                                                              item
                                                                                .field_name
                                                                            ]
                                                                              ? [
                                                                                  _vm._v(
                                                                                    "\n                            -\n                          "
                                                                                  )
                                                                                ]
                                                                              : item.data_type ==
                                                                                "date"
                                                                              ? [
                                                                                  _vm._v(
                                                                                    "\n                            " +
                                                                                      _vm._s(
                                                                                        _vm._f(
                                                                                          "formatDate"
                                                                                        )(
                                                                                          _vm
                                                                                            .data[
                                                                                            "pv_park"
                                                                                          ][
                                                                                            item
                                                                                              .field_name
                                                                                          ]
                                                                                        )
                                                                                      ) +
                                                                                      "\n                            "
                                                                                  ),
                                                                                  item.portal_field_route
                                                                                    ? _c(
                                                                                        "v-btn",
                                                                                        {
                                                                                          attrs: {
                                                                                            color:
                                                                                              "info",
                                                                                            icon:
                                                                                              "",
                                                                                            href: _vm.route(
                                                                                              item.portal_field_route.module.toLowerCase() +
                                                                                                ".linked",
                                                                                              {
                                                                                                field:
                                                                                                  item
                                                                                                    .portal_field_route
                                                                                                    .link_field_id,
                                                                                                value:
                                                                                                  _vm
                                                                                                    .data[
                                                                                                    "pv_park"
                                                                                                  ][
                                                                                                    item
                                                                                                      .field_name
                                                                                                  ]
                                                                                              }
                                                                                            )
                                                                                          }
                                                                                        },
                                                                                        [
                                                                                          _c(
                                                                                            "v-icon",
                                                                                            [
                                                                                              _vm._v(
                                                                                                "mdi-link-variant"
                                                                                              )
                                                                                            ]
                                                                                          )
                                                                                        ],
                                                                                        1
                                                                                      )
                                                                                    : _vm._e()
                                                                                ]
                                                                              : item.data_type ==
                                                                                "number"
                                                                              ? [
                                                                                  _vm._v(
                                                                                    "\n                            " +
                                                                                      _vm._s(
                                                                                        _vm._f(
                                                                                          "formatNumber"
                                                                                        )(
                                                                                          _vm
                                                                                            .data[
                                                                                            "pv_park"
                                                                                          ][
                                                                                            item
                                                                                              .field_name
                                                                                          ]
                                                                                        )
                                                                                      ) +
                                                                                      "\n                            "
                                                                                  ),
                                                                                  item.portal_field_route
                                                                                    ? _c(
                                                                                        "v-btn",
                                                                                        {
                                                                                          attrs: {
                                                                                            color:
                                                                                              "info",
                                                                                            icon:
                                                                                              "",
                                                                                            href: _vm.route(
                                                                                              item.portal_field_route.module.toLowerCase() +
                                                                                                ".linked",
                                                                                              {
                                                                                                field:
                                                                                                  item
                                                                                                    .portal_field_route
                                                                                                    .link_field_id,
                                                                                                value:
                                                                                                  _vm
                                                                                                    .data[
                                                                                                    "pv_park"
                                                                                                  ][
                                                                                                    item
                                                                                                      .field_name
                                                                                                  ]
                                                                                              }
                                                                                            )
                                                                                          }
                                                                                        },
                                                                                        [
                                                                                          _c(
                                                                                            "v-icon",
                                                                                            [
                                                                                              _vm._v(
                                                                                                "mdi-link-variant"
                                                                                              )
                                                                                            ]
                                                                                          )
                                                                                        ],
                                                                                        1
                                                                                      )
                                                                                    : _vm._e()
                                                                                ]
                                                                              : item.data_type ==
                                                                                "coordinate"
                                                                              ? [
                                                                                  _vm._v(
                                                                                    "\n                            " +
                                                                                      _vm._s(
                                                                                        _vm._f(
                                                                                          "formatCoordinate"
                                                                                        )(
                                                                                          _vm
                                                                                            .data[
                                                                                            "pv_park"
                                                                                          ][
                                                                                            item
                                                                                              .field_name
                                                                                          ]
                                                                                        )
                                                                                      ) +
                                                                                      "\n                            "
                                                                                  ),
                                                                                  item.portal_field_route
                                                                                    ? _c(
                                                                                        "v-btn",
                                                                                        {
                                                                                          attrs: {
                                                                                            color:
                                                                                              "info",
                                                                                            icon:
                                                                                              "",
                                                                                            href: _vm.route(
                                                                                              item.portal_field_route.module.toLowerCase() +
                                                                                                ".linked",
                                                                                              {
                                                                                                field:
                                                                                                  item
                                                                                                    .portal_field_route
                                                                                                    .link_field_id,
                                                                                                value:
                                                                                                  _vm
                                                                                                    .data[
                                                                                                    "pv_park"
                                                                                                  ][
                                                                                                    item
                                                                                                      .field_name
                                                                                                  ]
                                                                                              }
                                                                                            )
                                                                                          }
                                                                                        },
                                                                                        [
                                                                                          _c(
                                                                                            "v-icon",
                                                                                            [
                                                                                              _vm._v(
                                                                                                "mdi-link-variant"
                                                                                              )
                                                                                            ]
                                                                                          )
                                                                                        ],
                                                                                        1
                                                                                      )
                                                                                    : _vm._e()
                                                                                ]
                                                                              : _vm.isURL(
                                                                                  _vm
                                                                                    .data[
                                                                                    "pv_park"
                                                                                  ][
                                                                                    item
                                                                                      .field_name
                                                                                  ]
                                                                                )
                                                                              ? [
                                                                                  _c(
                                                                                    "a",
                                                                                    {
                                                                                      attrs: {
                                                                                        href: _vm.isURL(
                                                                                          _vm
                                                                                            .data[
                                                                                            "pv_park"
                                                                                          ][
                                                                                            item
                                                                                              .field_name
                                                                                          ]
                                                                                        )
                                                                                      }
                                                                                    },
                                                                                    [
                                                                                      _vm._v(
                                                                                        _vm._s(
                                                                                          _vm
                                                                                            .data[
                                                                                            "pv_park"
                                                                                          ][
                                                                                            item
                                                                                              .field_name
                                                                                          ]
                                                                                        )
                                                                                      )
                                                                                    ]
                                                                                  )
                                                                                ]
                                                                              : [
                                                                                  _vm._v(
                                                                                    "\n                            " +
                                                                                      _vm._s(
                                                                                        _vm
                                                                                          .data[
                                                                                          "pv_park"
                                                                                        ][
                                                                                          item
                                                                                            .field_name
                                                                                        ]
                                                                                      ) +
                                                                                      "\n                            "
                                                                                  ),
                                                                                  item.portal_field_route
                                                                                    ? _c(
                                                                                        "v-btn",
                                                                                        {
                                                                                          attrs: {
                                                                                            color:
                                                                                              "info",
                                                                                            icon:
                                                                                              "",
                                                                                            href: _vm.route(
                                                                                              item.portal_field_route.module.toLowerCase() +
                                                                                                ".linked",
                                                                                              {
                                                                                                field:
                                                                                                  item
                                                                                                    .portal_field_route
                                                                                                    .link_field_id,
                                                                                                value:
                                                                                                  _vm
                                                                                                    .data[
                                                                                                    "pv_park"
                                                                                                  ][
                                                                                                    item
                                                                                                      .field_name
                                                                                                  ]
                                                                                              }
                                                                                            )
                                                                                          }
                                                                                        },
                                                                                        [
                                                                                          _c(
                                                                                            "v-icon",
                                                                                            [
                                                                                              _vm._v(
                                                                                                "mdi-link-variant"
                                                                                              )
                                                                                            ]
                                                                                          )
                                                                                        ],
                                                                                        1
                                                                                      )
                                                                                    : _vm._e()
                                                                                ]
                                                                          ],
                                                                          2
                                                                        )
                                                                      ]
                                                                    )
                                                                  }
                                                                )
                                                              }
                                                            }
                                                          ],
                                                          null,
                                                          true
                                                        )
                                                      })
                                                    ],
                                                    1
                                                  )
                                                : _vm._e()
                                            ]
                                          })
                                        ],
                                        2
                                      )
                                    ]
                                  : _vm._e()
                              ],
                              2
                            )
                          : block.module.includes("Brennstoffe")
                          ? _c(
                              "div",
                              [
                                _vm.data["brennstoffe"]
                                  ? _vm._l(_vm.data["brennstoffe"], function(
                                      brennstoff,
                                      index
                                    ) {
                                      return _c(
                                        "v-row",
                                        { key: index },
                                        [
                                          _c(
                                            "v-col",
                                            { attrs: { cols: "12" } },
                                            [
                                              _c("h3", [
                                                _vm._v(
                                                  "Windpark " +
                                                    _vm._s(index + 1)
                                                )
                                              ])
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _vm._l(block.panel_columns, function(
                                            column,
                                            index
                                          ) {
                                            return [
                                              column.portal_fields.length > 0
                                                ? _c(
                                                    "v-col",
                                                    {
                                                      key: index,
                                                      attrs: {
                                                        cols: "12",
                                                        lg:
                                                          12 /
                                                          block.panel_columns
                                                            .length
                                                      }
                                                    },
                                                    [
                                                      _c("v-data-iterator", {
                                                        attrs: {
                                                          "disable-filtering":
                                                            "",
                                                          "disable-pagination":
                                                            "",
                                                          "disable-sort": "",
                                                          "hide-default-footer":
                                                            "",
                                                          items:
                                                            column.portal_fields
                                                        },
                                                        scopedSlots: _vm._u(
                                                          [
                                                            {
                                                              key: "default",
                                                              fn: function(
                                                                ref
                                                              ) {
                                                                var items =
                                                                  ref.items
                                                                return _vm._l(
                                                                  items,
                                                                  function(
                                                                    item
                                                                  ) {
                                                                    return _c(
                                                                      "div",
                                                                      {
                                                                        key:
                                                                          item.id,
                                                                        staticClass:
                                                                          "fields-item px-4 my-1"
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "div",
                                                                          {
                                                                            staticClass:
                                                                              "field-title py-2"
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              "\n                          " +
                                                                                _vm._s(
                                                                                  item.field_name
                                                                                ) +
                                                                                "\n                          "
                                                                            ),
                                                                            item.description
                                                                              ? [
                                                                                  _c(
                                                                                    "v-tooltip",
                                                                                    {
                                                                                      attrs: {
                                                                                        bottom:
                                                                                          ""
                                                                                      },
                                                                                      scopedSlots: _vm._u(
                                                                                        [
                                                                                          {
                                                                                            key:
                                                                                              "activator",
                                                                                            fn: function(
                                                                                              ref
                                                                                            ) {
                                                                                              var on =
                                                                                                ref.on
                                                                                              var attrs =
                                                                                                ref.attrs
                                                                                              return [
                                                                                                _c(
                                                                                                  "v-btn",
                                                                                                  _vm._g(
                                                                                                    _vm._b(
                                                                                                      {
                                                                                                        attrs: {
                                                                                                          color:
                                                                                                            "info",
                                                                                                          icon:
                                                                                                            ""
                                                                                                        }
                                                                                                      },
                                                                                                      "v-btn",
                                                                                                      attrs,
                                                                                                      false
                                                                                                    ),
                                                                                                    on
                                                                                                  ),
                                                                                                  [
                                                                                                    _c(
                                                                                                      "v-icon",
                                                                                                      [
                                                                                                        _vm._v(
                                                                                                          "mdi-information-outline"
                                                                                                        )
                                                                                                      ]
                                                                                                    )
                                                                                                  ],
                                                                                                  1
                                                                                                )
                                                                                              ]
                                                                                            }
                                                                                          }
                                                                                        ],
                                                                                        null,
                                                                                        true
                                                                                      )
                                                                                    },
                                                                                    [
                                                                                      _vm._v(
                                                                                        " "
                                                                                      ),
                                                                                      _c(
                                                                                        "span",
                                                                                        [
                                                                                          _vm._v(
                                                                                            _vm._s(
                                                                                              item.description
                                                                                            )
                                                                                          )
                                                                                        ]
                                                                                      )
                                                                                    ]
                                                                                  )
                                                                                ]
                                                                              : _vm._e()
                                                                          ],
                                                                          2
                                                                        ),
                                                                        _vm._v(
                                                                          " "
                                                                        ),
                                                                        _c(
                                                                          "div",
                                                                          {
                                                                            staticClass:
                                                                              "field-divider"
                                                                          }
                                                                        ),
                                                                        _vm._v(
                                                                          " "
                                                                        ),
                                                                        _c(
                                                                          "div",
                                                                          {
                                                                            staticClass:
                                                                              "field-value py-2"
                                                                          },
                                                                          [
                                                                            !(
                                                                              item.field_name in
                                                                              brennstoff
                                                                            ) ||
                                                                            !brennstoff[
                                                                              item
                                                                                .field_name
                                                                            ]
                                                                              ? [
                                                                                  _vm._v(
                                                                                    "\n                            -\n                          "
                                                                                  )
                                                                                ]
                                                                              : item.data_type ==
                                                                                "date"
                                                                              ? [
                                                                                  _vm._v(
                                                                                    "\n                            " +
                                                                                      _vm._s(
                                                                                        _vm._f(
                                                                                          "formatDate"
                                                                                        )(
                                                                                          brennstoff[
                                                                                            item
                                                                                              .field_name
                                                                                          ]
                                                                                        )
                                                                                      ) +
                                                                                      "\n                            "
                                                                                  ),
                                                                                  item.portal_field_route
                                                                                    ? _c(
                                                                                        "v-btn",
                                                                                        {
                                                                                          attrs: {
                                                                                            color:
                                                                                              "info",
                                                                                            icon:
                                                                                              "",
                                                                                            href: _vm.route(
                                                                                              item.portal_field_route.module.toLowerCase() +
                                                                                                ".linked",
                                                                                              {
                                                                                                field:
                                                                                                  item
                                                                                                    .portal_field_route
                                                                                                    .link_field_id,
                                                                                                value:
                                                                                                  brennstoff[
                                                                                                    item
                                                                                                      .field_name
                                                                                                  ]
                                                                                              }
                                                                                            )
                                                                                          }
                                                                                        },
                                                                                        [
                                                                                          _c(
                                                                                            "v-icon",
                                                                                            [
                                                                                              _vm._v(
                                                                                                "mdi-link-variant"
                                                                                              )
                                                                                            ]
                                                                                          )
                                                                                        ],
                                                                                        1
                                                                                      )
                                                                                    : _vm._e()
                                                                                ]
                                                                              : item.data_type ==
                                                                                "number"
                                                                              ? [
                                                                                  _vm._v(
                                                                                    "\n                            " +
                                                                                      _vm._s(
                                                                                        _vm._f(
                                                                                          "formatNumber"
                                                                                        )(
                                                                                          brennstoff[
                                                                                            item
                                                                                              .field_name
                                                                                          ]
                                                                                        )
                                                                                      ) +
                                                                                      "\n                            "
                                                                                  ),
                                                                                  item.portal_field_route
                                                                                    ? _c(
                                                                                        "v-btn",
                                                                                        {
                                                                                          attrs: {
                                                                                            color:
                                                                                              "info",
                                                                                            icon:
                                                                                              "",
                                                                                            href: _vm.route(
                                                                                              item.portal_field_route.module.toLowerCase() +
                                                                                                ".linked",
                                                                                              {
                                                                                                field:
                                                                                                  item
                                                                                                    .portal_field_route
                                                                                                    .link_field_id,
                                                                                                value:
                                                                                                  brennstoff[
                                                                                                    item
                                                                                                      .field_name
                                                                                                  ]
                                                                                              }
                                                                                            )
                                                                                          }
                                                                                        },
                                                                                        [
                                                                                          _c(
                                                                                            "v-icon",
                                                                                            [
                                                                                              _vm._v(
                                                                                                "mdi-link-variant"
                                                                                              )
                                                                                            ]
                                                                                          )
                                                                                        ],
                                                                                        1
                                                                                      )
                                                                                    : _vm._e()
                                                                                ]
                                                                              : item.data_type ==
                                                                                "coordinate"
                                                                              ? [
                                                                                  _vm._v(
                                                                                    "\n                            " +
                                                                                      _vm._s(
                                                                                        _vm._f(
                                                                                          "formatCoordinate"
                                                                                        )(
                                                                                          brennstoff[
                                                                                            item
                                                                                              .field_name
                                                                                          ]
                                                                                        )
                                                                                      ) +
                                                                                      "\n                            "
                                                                                  ),
                                                                                  item.portal_field_route
                                                                                    ? _c(
                                                                                        "v-btn",
                                                                                        {
                                                                                          attrs: {
                                                                                            color:
                                                                                              "info",
                                                                                            icon:
                                                                                              "",
                                                                                            href: _vm.route(
                                                                                              item.portal_field_route.module.toLowerCase() +
                                                                                                ".linked",
                                                                                              {
                                                                                                field:
                                                                                                  item
                                                                                                    .portal_field_route
                                                                                                    .link_field_id,
                                                                                                value:
                                                                                                  brennstoff[
                                                                                                    item
                                                                                                      .field_name
                                                                                                  ]
                                                                                              }
                                                                                            )
                                                                                          }
                                                                                        },
                                                                                        [
                                                                                          _c(
                                                                                            "v-icon",
                                                                                            [
                                                                                              _vm._v(
                                                                                                "mdi-link-variant"
                                                                                              )
                                                                                            ]
                                                                                          )
                                                                                        ],
                                                                                        1
                                                                                      )
                                                                                    : _vm._e()
                                                                                ]
                                                                              : _vm.isURL(
                                                                                  brennstoff[
                                                                                    item
                                                                                      .field_name
                                                                                  ]
                                                                                )
                                                                              ? [
                                                                                  _c(
                                                                                    "a",
                                                                                    {
                                                                                      attrs: {
                                                                                        href: _vm.isURL(
                                                                                          brennstoff[
                                                                                            item
                                                                                              .field_name
                                                                                          ]
                                                                                        )
                                                                                      }
                                                                                    },
                                                                                    [
                                                                                      _vm._v(
                                                                                        _vm._s(
                                                                                          brennstoff[
                                                                                            item
                                                                                              .field_name
                                                                                          ]
                                                                                        )
                                                                                      )
                                                                                    ]
                                                                                  )
                                                                                ]
                                                                              : [
                                                                                  _vm._v(
                                                                                    "\n                            " +
                                                                                      _vm._s(
                                                                                        brennstoff[
                                                                                          item
                                                                                            .field_name
                                                                                        ]
                                                                                      ) +
                                                                                      "\n                            "
                                                                                  ),
                                                                                  item.portal_field_route
                                                                                    ? _c(
                                                                                        "v-btn",
                                                                                        {
                                                                                          attrs: {
                                                                                            color:
                                                                                              "info",
                                                                                            icon:
                                                                                              "",
                                                                                            href: _vm.route(
                                                                                              item.portal_field_route.module.toLowerCase() +
                                                                                                ".linked",
                                                                                              {
                                                                                                field:
                                                                                                  item
                                                                                                    .portal_field_route
                                                                                                    .link_field_id,
                                                                                                value:
                                                                                                  brennstoff[
                                                                                                    item
                                                                                                      .field_name
                                                                                                  ]
                                                                                              }
                                                                                            )
                                                                                          }
                                                                                        },
                                                                                        [
                                                                                          _c(
                                                                                            "v-icon",
                                                                                            [
                                                                                              _vm._v(
                                                                                                "mdi-link-variant"
                                                                                              )
                                                                                            ]
                                                                                          )
                                                                                        ],
                                                                                        1
                                                                                      )
                                                                                    : _vm._e()
                                                                                ]
                                                                          ],
                                                                          2
                                                                        )
                                                                      ]
                                                                    )
                                                                  }
                                                                )
                                                              }
                                                            }
                                                          ],
                                                          null,
                                                          true
                                                        )
                                                      })
                                                    ],
                                                    1
                                                  )
                                                : _vm._e()
                                            ]
                                          })
                                        ],
                                        2
                                      )
                                    })
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm.data["brennstoff"]
                                  ? [
                                      _c(
                                        "v-row",
                                        [
                                          _vm._l(block.panel_columns, function(
                                            column,
                                            index
                                          ) {
                                            return [
                                              column.portal_fields.length > 0
                                                ? _c(
                                                    "v-col",
                                                    {
                                                      key: index,
                                                      attrs: {
                                                        cols: "12",
                                                        lg:
                                                          12 /
                                                          block.panel_columns
                                                            .length
                                                      }
                                                    },
                                                    [
                                                      _c("v-data-iterator", {
                                                        attrs: {
                                                          "disable-filtering":
                                                            "",
                                                          "disable-pagination":
                                                            "",
                                                          "disable-sort": "",
                                                          "hide-default-footer":
                                                            "",
                                                          items:
                                                            column.portal_fields
                                                        },
                                                        scopedSlots: _vm._u(
                                                          [
                                                            {
                                                              key: "default",
                                                              fn: function(
                                                                ref
                                                              ) {
                                                                var items =
                                                                  ref.items
                                                                return _vm._l(
                                                                  items,
                                                                  function(
                                                                    item
                                                                  ) {
                                                                    return _c(
                                                                      "div",
                                                                      {
                                                                        key:
                                                                          item.id,
                                                                        staticClass:
                                                                          "fields-item px-4 my-1"
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "div",
                                                                          {
                                                                            staticClass:
                                                                              "field-title py-2"
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              "\n                          " +
                                                                                _vm._s(
                                                                                  item.field_name
                                                                                ) +
                                                                                "\n                          "
                                                                            ),
                                                                            item.description
                                                                              ? [
                                                                                  _c(
                                                                                    "v-tooltip",
                                                                                    {
                                                                                      attrs: {
                                                                                        bottom:
                                                                                          ""
                                                                                      },
                                                                                      scopedSlots: _vm._u(
                                                                                        [
                                                                                          {
                                                                                            key:
                                                                                              "activator",
                                                                                            fn: function(
                                                                                              ref
                                                                                            ) {
                                                                                              var on =
                                                                                                ref.on
                                                                                              var attrs =
                                                                                                ref.attrs
                                                                                              return [
                                                                                                _c(
                                                                                                  "v-btn",
                                                                                                  _vm._g(
                                                                                                    _vm._b(
                                                                                                      {
                                                                                                        attrs: {
                                                                                                          color:
                                                                                                            "info",
                                                                                                          icon:
                                                                                                            ""
                                                                                                        }
                                                                                                      },
                                                                                                      "v-btn",
                                                                                                      attrs,
                                                                                                      false
                                                                                                    ),
                                                                                                    on
                                                                                                  ),
                                                                                                  [
                                                                                                    _c(
                                                                                                      "v-icon",
                                                                                                      [
                                                                                                        _vm._v(
                                                                                                          "mdi-information-outline"
                                                                                                        )
                                                                                                      ]
                                                                                                    )
                                                                                                  ],
                                                                                                  1
                                                                                                )
                                                                                              ]
                                                                                            }
                                                                                          }
                                                                                        ],
                                                                                        null,
                                                                                        true
                                                                                      )
                                                                                    },
                                                                                    [
                                                                                      _vm._v(
                                                                                        " "
                                                                                      ),
                                                                                      _c(
                                                                                        "span",
                                                                                        [
                                                                                          _vm._v(
                                                                                            _vm._s(
                                                                                              item.description
                                                                                            )
                                                                                          )
                                                                                        ]
                                                                                      )
                                                                                    ]
                                                                                  )
                                                                                ]
                                                                              : _vm._e()
                                                                          ],
                                                                          2
                                                                        ),
                                                                        _vm._v(
                                                                          " "
                                                                        ),
                                                                        _c(
                                                                          "div",
                                                                          {
                                                                            staticClass:
                                                                              "field-divider"
                                                                          }
                                                                        ),
                                                                        _vm._v(
                                                                          " "
                                                                        ),
                                                                        _c(
                                                                          "div",
                                                                          {
                                                                            staticClass:
                                                                              "field-value py-2"
                                                                          },
                                                                          [
                                                                            !(
                                                                              item.field_name in
                                                                              _vm
                                                                                .data[
                                                                                "brennstoff"
                                                                              ]
                                                                            ) ||
                                                                            !_vm
                                                                              .data[
                                                                              "brennstoff"
                                                                            ][
                                                                              item
                                                                                .field_name
                                                                            ]
                                                                              ? [
                                                                                  _vm._v(
                                                                                    "\n                            -\n                          "
                                                                                  )
                                                                                ]
                                                                              : item.data_type ==
                                                                                "date"
                                                                              ? [
                                                                                  _vm._v(
                                                                                    "\n                            " +
                                                                                      _vm._s(
                                                                                        _vm._f(
                                                                                          "formatDate"
                                                                                        )(
                                                                                          _vm
                                                                                            .data[
                                                                                            "brennstoff"
                                                                                          ][
                                                                                            item
                                                                                              .field_name
                                                                                          ]
                                                                                        )
                                                                                      ) +
                                                                                      "\n                            "
                                                                                  ),
                                                                                  item.portal_field_route
                                                                                    ? _c(
                                                                                        "v-btn",
                                                                                        {
                                                                                          attrs: {
                                                                                            color:
                                                                                              "info",
                                                                                            icon:
                                                                                              "",
                                                                                            href: _vm.route(
                                                                                              item.portal_field_route.module.toLowerCase() +
                                                                                                ".linked",
                                                                                              {
                                                                                                field:
                                                                                                  item
                                                                                                    .portal_field_route
                                                                                                    .link_field_id,
                                                                                                value:
                                                                                                  _vm
                                                                                                    .data[
                                                                                                    "brennstoff"
                                                                                                  ][
                                                                                                    item
                                                                                                      .field_name
                                                                                                  ]
                                                                                              }
                                                                                            )
                                                                                          }
                                                                                        },
                                                                                        [
                                                                                          _c(
                                                                                            "v-icon",
                                                                                            [
                                                                                              _vm._v(
                                                                                                "mdi-link-variant"
                                                                                              )
                                                                                            ]
                                                                                          )
                                                                                        ],
                                                                                        1
                                                                                      )
                                                                                    : _vm._e()
                                                                                ]
                                                                              : item.data_type ==
                                                                                "number"
                                                                              ? [
                                                                                  _vm._v(
                                                                                    "\n                            " +
                                                                                      _vm._s(
                                                                                        _vm._f(
                                                                                          "formatNumber"
                                                                                        )(
                                                                                          _vm
                                                                                            .data[
                                                                                            "brennstoff"
                                                                                          ][
                                                                                            item
                                                                                              .field_name
                                                                                          ]
                                                                                        )
                                                                                      ) +
                                                                                      "\n                            "
                                                                                  ),
                                                                                  item.portal_field_route
                                                                                    ? _c(
                                                                                        "v-btn",
                                                                                        {
                                                                                          attrs: {
                                                                                            color:
                                                                                              "info",
                                                                                            icon:
                                                                                              "",
                                                                                            href: _vm.route(
                                                                                              item.portal_field_route.module.toLowerCase() +
                                                                                                ".linked",
                                                                                              {
                                                                                                field:
                                                                                                  item
                                                                                                    .portal_field_route
                                                                                                    .link_field_id,
                                                                                                value:
                                                                                                  _vm
                                                                                                    .data[
                                                                                                    "brennstoff"
                                                                                                  ][
                                                                                                    item
                                                                                                      .field_name
                                                                                                  ]
                                                                                              }
                                                                                            )
                                                                                          }
                                                                                        },
                                                                                        [
                                                                                          _c(
                                                                                            "v-icon",
                                                                                            [
                                                                                              _vm._v(
                                                                                                "mdi-link-variant"
                                                                                              )
                                                                                            ]
                                                                                          )
                                                                                        ],
                                                                                        1
                                                                                      )
                                                                                    : _vm._e()
                                                                                ]
                                                                              : item.data_type ==
                                                                                "coordinate"
                                                                              ? [
                                                                                  _vm._v(
                                                                                    "\n                            " +
                                                                                      _vm._s(
                                                                                        _vm._f(
                                                                                          "formatCoordinate"
                                                                                        )(
                                                                                          _vm
                                                                                            .data[
                                                                                            "brennstoff"
                                                                                          ][
                                                                                            item
                                                                                              .field_name
                                                                                          ]
                                                                                        )
                                                                                      ) +
                                                                                      "\n                            "
                                                                                  ),
                                                                                  item.portal_field_route
                                                                                    ? _c(
                                                                                        "v-btn",
                                                                                        {
                                                                                          attrs: {
                                                                                            color:
                                                                                              "info",
                                                                                            icon:
                                                                                              "",
                                                                                            href: _vm.route(
                                                                                              item.portal_field_route.module.toLowerCase() +
                                                                                                ".linked",
                                                                                              {
                                                                                                field:
                                                                                                  item
                                                                                                    .portal_field_route
                                                                                                    .link_field_id,
                                                                                                value:
                                                                                                  _vm
                                                                                                    .data[
                                                                                                    "brennstoff"
                                                                                                  ][
                                                                                                    item
                                                                                                      .field_name
                                                                                                  ]
                                                                                              }
                                                                                            )
                                                                                          }
                                                                                        },
                                                                                        [
                                                                                          _c(
                                                                                            "v-icon",
                                                                                            [
                                                                                              _vm._v(
                                                                                                "mdi-link-variant"
                                                                                              )
                                                                                            ]
                                                                                          )
                                                                                        ],
                                                                                        1
                                                                                      )
                                                                                    : _vm._e()
                                                                                ]
                                                                              : _vm.isURL(
                                                                                  _vm
                                                                                    .data[
                                                                                    "brennstoff"
                                                                                  ][
                                                                                    item
                                                                                      .field_name
                                                                                  ]
                                                                                )
                                                                              ? [
                                                                                  _c(
                                                                                    "a",
                                                                                    {
                                                                                      attrs: {
                                                                                        href: _vm.isURL(
                                                                                          _vm
                                                                                            .data[
                                                                                            "brennstoff"
                                                                                          ][
                                                                                            item
                                                                                              .field_name
                                                                                          ]
                                                                                        )
                                                                                      }
                                                                                    },
                                                                                    [
                                                                                      _vm._v(
                                                                                        _vm._s(
                                                                                          _vm
                                                                                            .data[
                                                                                            "brennstoff"
                                                                                          ][
                                                                                            item
                                                                                              .field_name
                                                                                          ]
                                                                                        )
                                                                                      )
                                                                                    ]
                                                                                  )
                                                                                ]
                                                                              : [
                                                                                  _vm._v(
                                                                                    "\n                            " +
                                                                                      _vm._s(
                                                                                        _vm
                                                                                          .data[
                                                                                          "brennstoff"
                                                                                        ][
                                                                                          item
                                                                                            .field_name
                                                                                        ]
                                                                                      ) +
                                                                                      "\n                            "
                                                                                  ),
                                                                                  item.portal_field_route
                                                                                    ? _c(
                                                                                        "v-btn",
                                                                                        {
                                                                                          attrs: {
                                                                                            color:
                                                                                              "info",
                                                                                            icon:
                                                                                              "",
                                                                                            href: _vm.route(
                                                                                              item.portal_field_route.module.toLowerCase() +
                                                                                                ".linked",
                                                                                              {
                                                                                                field:
                                                                                                  item
                                                                                                    .portal_field_route
                                                                                                    .link_field_id,
                                                                                                value:
                                                                                                  _vm
                                                                                                    .data[
                                                                                                    "brennstoff"
                                                                                                  ][
                                                                                                    item
                                                                                                      .field_name
                                                                                                  ]
                                                                                              }
                                                                                            )
                                                                                          }
                                                                                        },
                                                                                        [
                                                                                          _c(
                                                                                            "v-icon",
                                                                                            [
                                                                                              _vm._v(
                                                                                                "mdi-link-variant"
                                                                                              )
                                                                                            ]
                                                                                          )
                                                                                        ],
                                                                                        1
                                                                                      )
                                                                                    : _vm._e()
                                                                                ]
                                                                          ],
                                                                          2
                                                                        )
                                                                      ]
                                                                    )
                                                                  }
                                                                )
                                                              }
                                                            }
                                                          ],
                                                          null,
                                                          true
                                                        )
                                                      })
                                                    ],
                                                    1
                                                  )
                                                : _vm._e()
                                            ]
                                          })
                                        ],
                                        2
                                      )
                                    ]
                                  : _vm._e()
                              ],
                              2
                            )
                          : block.module.includes("Windenergieanlagen")
                          ? _c(
                              "div",
                              _vm._l(_vm.data["wea"], function(wea, index) {
                                return _c(
                                  "v-row",
                                  { key: index },
                                  [
                                    _c("v-col", { attrs: { cols: "12" } }, [
                                      _c("h3", [
                                        _vm._v(
                                          "Windenergieanlage " +
                                            _vm._s(index + 1)
                                        )
                                      ])
                                    ]),
                                    _vm._v(" "),
                                    _vm._l(block.panel_columns, function(
                                      column,
                                      index
                                    ) {
                                      return [
                                        column.portal_fields.length > 0
                                          ? _c(
                                              "v-col",
                                              {
                                                key: index,
                                                attrs: {
                                                  cols: "12",
                                                  lg:
                                                    12 /
                                                    block.panel_columns.length
                                                }
                                              },
                                              [
                                                _c("v-data-iterator", {
                                                  attrs: {
                                                    "disable-filtering": "",
                                                    "disable-pagination": "",
                                                    "disable-sort": "",
                                                    "hide-default-footer": "",
                                                    items: column.portal_fields
                                                  },
                                                  scopedSlots: _vm._u(
                                                    [
                                                      {
                                                        key: "default",
                                                        fn: function(ref) {
                                                          var items = ref.items
                                                          return _vm._l(
                                                            items,
                                                            function(item) {
                                                              return _c(
                                                                "div",
                                                                {
                                                                  key: item.id,
                                                                  staticClass:
                                                                    "fields-item px-4 my-1"
                                                                },
                                                                [
                                                                  _c(
                                                                    "div",
                                                                    {
                                                                      staticClass:
                                                                        "field-title py-2"
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "\n                        " +
                                                                          _vm._s(
                                                                            item.field_name
                                                                          ) +
                                                                          "\n                        "
                                                                      ),
                                                                      item.description
                                                                        ? [
                                                                            _c(
                                                                              "v-tooltip",
                                                                              {
                                                                                attrs: {
                                                                                  bottom:
                                                                                    ""
                                                                                },
                                                                                scopedSlots: _vm._u(
                                                                                  [
                                                                                    {
                                                                                      key:
                                                                                        "activator",
                                                                                      fn: function(
                                                                                        ref
                                                                                      ) {
                                                                                        var on =
                                                                                          ref.on
                                                                                        var attrs =
                                                                                          ref.attrs
                                                                                        return [
                                                                                          _c(
                                                                                            "v-btn",
                                                                                            _vm._g(
                                                                                              _vm._b(
                                                                                                {
                                                                                                  attrs: {
                                                                                                    color:
                                                                                                      "info",
                                                                                                    icon:
                                                                                                      ""
                                                                                                  }
                                                                                                },
                                                                                                "v-btn",
                                                                                                attrs,
                                                                                                false
                                                                                              ),
                                                                                              on
                                                                                            ),
                                                                                            [
                                                                                              _c(
                                                                                                "v-icon",
                                                                                                [
                                                                                                  _vm._v(
                                                                                                    "mdi-information-outline"
                                                                                                  )
                                                                                                ]
                                                                                              )
                                                                                            ],
                                                                                            1
                                                                                          )
                                                                                        ]
                                                                                      }
                                                                                    }
                                                                                  ],
                                                                                  null,
                                                                                  true
                                                                                )
                                                                              },
                                                                              [
                                                                                _vm._v(
                                                                                  " "
                                                                                ),
                                                                                _c(
                                                                                  "span",
                                                                                  [
                                                                                    _vm._v(
                                                                                      _vm._s(
                                                                                        item.description
                                                                                      )
                                                                                    )
                                                                                  ]
                                                                                )
                                                                              ]
                                                                            )
                                                                          ]
                                                                        : _vm._e()
                                                                    ],
                                                                    2
                                                                  ),
                                                                  _vm._v(" "),
                                                                  _c("div", {
                                                                    staticClass:
                                                                      "field-divider"
                                                                  }),
                                                                  _vm._v(" "),
                                                                  _c(
                                                                    "div",
                                                                    {
                                                                      staticClass:
                                                                        "field-value py-2"
                                                                    },
                                                                    [
                                                                      !wea[
                                                                        item
                                                                          .field_name
                                                                      ]
                                                                        ? [
                                                                            _vm._v(
                                                                              " - "
                                                                            )
                                                                          ]
                                                                        : item.data_type ==
                                                                          "date"
                                                                        ? [
                                                                            _vm._v(
                                                                              "\n                          " +
                                                                                _vm._s(
                                                                                  _vm._f(
                                                                                    "formatDate"
                                                                                  )(
                                                                                    wea[
                                                                                      item
                                                                                        .field_name
                                                                                    ]
                                                                                  )
                                                                                ) +
                                                                                "\n                          "
                                                                            ),
                                                                            item.portal_field_route
                                                                              ? _c(
                                                                                  "v-btn",
                                                                                  {
                                                                                    attrs: {
                                                                                      color:
                                                                                        "info",
                                                                                      icon:
                                                                                        "",
                                                                                      href: _vm.route(
                                                                                        item.portal_field_route.module.toLowerCase() +
                                                                                          ".linked",
                                                                                        {
                                                                                          field:
                                                                                            item
                                                                                              .portal_field_route
                                                                                              .link_field_id,
                                                                                          value:
                                                                                            wea[
                                                                                              item
                                                                                                .field_name
                                                                                            ]
                                                                                        }
                                                                                      )
                                                                                    }
                                                                                  },
                                                                                  [
                                                                                    _c(
                                                                                      "v-icon",
                                                                                      [
                                                                                        _vm._v(
                                                                                          "mdi-link-variant"
                                                                                        )
                                                                                      ]
                                                                                    )
                                                                                  ],
                                                                                  1
                                                                                )
                                                                              : _vm._e()
                                                                          ]
                                                                        : item.data_type ==
                                                                          "number"
                                                                        ? [
                                                                            _vm._v(
                                                                              "\n                          " +
                                                                                _vm._s(
                                                                                  _vm._f(
                                                                                    "formatNumber"
                                                                                  )(
                                                                                    wea[
                                                                                      item
                                                                                        .field_name
                                                                                    ]
                                                                                  )
                                                                                ) +
                                                                                "\n                          "
                                                                            ),
                                                                            item.portal_field_route
                                                                              ? _c(
                                                                                  "v-btn",
                                                                                  {
                                                                                    attrs: {
                                                                                      color:
                                                                                        "info",
                                                                                      icon:
                                                                                        "",
                                                                                      href: _vm.route(
                                                                                        item.portal_field_route.module.toLowerCase() +
                                                                                          ".linked",
                                                                                        {
                                                                                          field:
                                                                                            item
                                                                                              .portal_field_route
                                                                                              .link_field_id,
                                                                                          value:
                                                                                            wea[
                                                                                              item
                                                                                                .field_name
                                                                                            ]
                                                                                        }
                                                                                      )
                                                                                    }
                                                                                  },
                                                                                  [
                                                                                    _c(
                                                                                      "v-icon",
                                                                                      [
                                                                                        _vm._v(
                                                                                          "mdi-link-variant"
                                                                                        )
                                                                                      ]
                                                                                    )
                                                                                  ],
                                                                                  1
                                                                                )
                                                                              : _vm._e()
                                                                          ]
                                                                        : item.data_type ==
                                                                          "coordinate"
                                                                        ? [
                                                                            _vm._v(
                                                                              "\n                          " +
                                                                                _vm._s(
                                                                                  _vm._f(
                                                                                    "formatCoordinate"
                                                                                  )(
                                                                                    wea[
                                                                                      item
                                                                                        .field_name
                                                                                    ]
                                                                                  )
                                                                                ) +
                                                                                "\n                          "
                                                                            ),
                                                                            item.portal_field_route
                                                                              ? _c(
                                                                                  "v-btn",
                                                                                  {
                                                                                    attrs: {
                                                                                      color:
                                                                                        "info",
                                                                                      icon:
                                                                                        "",
                                                                                      href: _vm.route(
                                                                                        item.portal_field_route.module.toLowerCase() +
                                                                                          ".linked",
                                                                                        {
                                                                                          field:
                                                                                            item
                                                                                              .portal_field_route
                                                                                              .link_field_id,
                                                                                          value:
                                                                                            wea[
                                                                                              item
                                                                                                .field_name
                                                                                            ]
                                                                                        }
                                                                                      )
                                                                                    }
                                                                                  },
                                                                                  [
                                                                                    _c(
                                                                                      "v-icon",
                                                                                      [
                                                                                        _vm._v(
                                                                                          "mdi-link-variant"
                                                                                        )
                                                                                      ]
                                                                                    )
                                                                                  ],
                                                                                  1
                                                                                )
                                                                              : _vm._e()
                                                                          ]
                                                                        : _vm.isURL(
                                                                            wea[
                                                                              item
                                                                                .field_name
                                                                            ]
                                                                          )
                                                                        ? [
                                                                            _c(
                                                                              "a",
                                                                              {
                                                                                attrs: {
                                                                                  href: _vm.isURL(
                                                                                    wea[
                                                                                      item
                                                                                        .field_name
                                                                                    ]
                                                                                  )
                                                                                }
                                                                              },
                                                                              [
                                                                                _vm._v(
                                                                                  _vm._s(
                                                                                    wea[
                                                                                      item
                                                                                        .field_name
                                                                                    ]
                                                                                  )
                                                                                )
                                                                              ]
                                                                            )
                                                                          ]
                                                                        : [
                                                                            _vm._v(
                                                                              "\n                          " +
                                                                                _vm._s(
                                                                                  wea[
                                                                                    item
                                                                                      .field_name
                                                                                  ]
                                                                                ) +
                                                                                "\n                          "
                                                                            ),
                                                                            item.portal_field_route
                                                                              ? _c(
                                                                                  "v-btn",
                                                                                  {
                                                                                    attrs: {
                                                                                      color:
                                                                                        "info",
                                                                                      icon:
                                                                                        "",
                                                                                      href: _vm.route(
                                                                                        item.portal_field_route.module.toLowerCase() +
                                                                                          ".linked",
                                                                                        {
                                                                                          field:
                                                                                            item
                                                                                              .portal_field_route
                                                                                              .link_field_id,
                                                                                          value:
                                                                                            wea[
                                                                                              item
                                                                                                .field_name
                                                                                            ]
                                                                                        }
                                                                                      )
                                                                                    }
                                                                                  },
                                                                                  [
                                                                                    _c(
                                                                                      "v-icon",
                                                                                      [
                                                                                        _vm._v(
                                                                                          "mdi-link-variant"
                                                                                        )
                                                                                      ]
                                                                                    )
                                                                                  ],
                                                                                  1
                                                                                )
                                                                              : _vm._e()
                                                                          ]
                                                                    ],
                                                                    2
                                                                  )
                                                                ]
                                                              )
                                                            }
                                                          )
                                                        }
                                                      }
                                                    ],
                                                    null,
                                                    true
                                                  )
                                                })
                                              ],
                                              1
                                            )
                                          : _vm._e()
                                      ]
                                    })
                                  ],
                                  2
                                )
                              }),
                              1
                            )
                          : block.module.includes("Photovoltaikanlagen")
                          ? _c(
                              "div",
                              _vm._l(_vm.data["pva"], function(pva, index) {
                                return _c(
                                  "v-row",
                                  { key: index },
                                  [
                                    _c("v-col", { attrs: { cols: "12" } }, [
                                      _c("h3", [
                                        _vm._v(
                                          "Photovoltaikanlage " +
                                            _vm._s(index + 1)
                                        )
                                      ])
                                    ]),
                                    _vm._v(" "),
                                    _vm._l(block.panel_columns, function(
                                      column,
                                      index
                                    ) {
                                      return [
                                        column.portal_fields.length > 0
                                          ? _c(
                                              "v-col",
                                              {
                                                key: index,
                                                attrs: {
                                                  cols: "12",
                                                  lg:
                                                    12 /
                                                    block.panel_columns.length
                                                }
                                              },
                                              [
                                                _c("v-data-iterator", {
                                                  attrs: {
                                                    "disable-filtering": "",
                                                    "disable-pagination": "",
                                                    "disable-sort": "",
                                                    "hide-default-footer": "",
                                                    items: column.portal_fields
                                                  },
                                                  scopedSlots: _vm._u(
                                                    [
                                                      {
                                                        key: "default",
                                                        fn: function(ref) {
                                                          var items = ref.items
                                                          return _vm._l(
                                                            items,
                                                            function(item) {
                                                              return _c(
                                                                "div",
                                                                {
                                                                  key: item.id,
                                                                  staticClass:
                                                                    "fields-item px-4 my-1"
                                                                },
                                                                [
                                                                  _c(
                                                                    "div",
                                                                    {
                                                                      staticClass:
                                                                        "field-title py-2"
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "\n                        " +
                                                                          _vm._s(
                                                                            item.field_name
                                                                          ) +
                                                                          "\n                      "
                                                                      )
                                                                    ]
                                                                  ),
                                                                  _vm._v(" "),
                                                                  _c("div", {
                                                                    staticClass:
                                                                      "field-divider"
                                                                  }),
                                                                  _vm._v(" "),
                                                                  _c(
                                                                    "div",
                                                                    {
                                                                      staticClass:
                                                                        "field-value py-2"
                                                                    },
                                                                    [
                                                                      !(
                                                                        item.field_name in
                                                                        pva
                                                                      ) ||
                                                                      !pva[
                                                                        item
                                                                          .field_name
                                                                      ]
                                                                        ? [
                                                                            _vm._v(
                                                                              "\n                          -\n                        "
                                                                            )
                                                                          ]
                                                                        : item.data_type ==
                                                                          "date"
                                                                        ? [
                                                                            _vm._v(
                                                                              "\n                          " +
                                                                                _vm._s(
                                                                                  _vm._f(
                                                                                    "formatDate"
                                                                                  )(
                                                                                    pva[
                                                                                      item
                                                                                        .field_name
                                                                                    ]
                                                                                  )
                                                                                ) +
                                                                                "\n                          "
                                                                            ),
                                                                            item.portal_field_route
                                                                              ? _c(
                                                                                  "v-btn",
                                                                                  {
                                                                                    attrs: {
                                                                                      color:
                                                                                        "info",
                                                                                      icon:
                                                                                        "",
                                                                                      href: _vm.route(
                                                                                        item.portal_field_route.module.toLowerCase() +
                                                                                          ".linked",
                                                                                        {
                                                                                          field:
                                                                                            item
                                                                                              .portal_field_route
                                                                                              .link_field_id,
                                                                                          value:
                                                                                            pva[
                                                                                              item
                                                                                                .field_name
                                                                                            ]
                                                                                        }
                                                                                      )
                                                                                    }
                                                                                  },
                                                                                  [
                                                                                    _c(
                                                                                      "v-icon",
                                                                                      [
                                                                                        _vm._v(
                                                                                          "mdi-link-variant"
                                                                                        )
                                                                                      ]
                                                                                    )
                                                                                  ],
                                                                                  1
                                                                                )
                                                                              : _vm._e()
                                                                          ]
                                                                        : item.data_type ==
                                                                          "number"
                                                                        ? [
                                                                            _vm._v(
                                                                              "\n                          " +
                                                                                _vm._s(
                                                                                  _vm._f(
                                                                                    "formatNumber"
                                                                                  )(
                                                                                    pva[
                                                                                      item
                                                                                        .field_name
                                                                                    ]
                                                                                  )
                                                                                ) +
                                                                                "\n                          "
                                                                            ),
                                                                            item.portal_field_route
                                                                              ? _c(
                                                                                  "v-btn",
                                                                                  {
                                                                                    attrs: {
                                                                                      color:
                                                                                        "info",
                                                                                      icon:
                                                                                        "",
                                                                                      href: _vm.route(
                                                                                        item.portal_field_route.module.toLowerCase() +
                                                                                          ".linked",
                                                                                        {
                                                                                          field:
                                                                                            item
                                                                                              .portal_field_route
                                                                                              .link_field_id,
                                                                                          value:
                                                                                            pva[
                                                                                              item
                                                                                                .field_name
                                                                                            ]
                                                                                        }
                                                                                      )
                                                                                    }
                                                                                  },
                                                                                  [
                                                                                    _c(
                                                                                      "v-icon",
                                                                                      [
                                                                                        _vm._v(
                                                                                          "mdi-link-variant"
                                                                                        )
                                                                                      ]
                                                                                    )
                                                                                  ],
                                                                                  1
                                                                                )
                                                                              : _vm._e()
                                                                          ]
                                                                        : item.data_type ==
                                                                          "coordinate"
                                                                        ? [
                                                                            _vm._v(
                                                                              "\n                          " +
                                                                                _vm._s(
                                                                                  _vm._f(
                                                                                    "formatCoordinate"
                                                                                  )(
                                                                                    pva[
                                                                                      item
                                                                                        .field_name
                                                                                    ]
                                                                                  )
                                                                                ) +
                                                                                "\n                          "
                                                                            ),
                                                                            item.portal_field_route
                                                                              ? _c(
                                                                                  "v-btn",
                                                                                  {
                                                                                    attrs: {
                                                                                      color:
                                                                                        "info",
                                                                                      icon:
                                                                                        "",
                                                                                      href: _vm.route(
                                                                                        item.portal_field_route.module.toLowerCase() +
                                                                                          ".linked",
                                                                                        {
                                                                                          field:
                                                                                            item
                                                                                              .portal_field_route
                                                                                              .link_field_id,
                                                                                          value:
                                                                                            pva[
                                                                                              item
                                                                                                .field_name
                                                                                            ]
                                                                                        }
                                                                                      )
                                                                                    }
                                                                                  },
                                                                                  [
                                                                                    _c(
                                                                                      "v-icon",
                                                                                      [
                                                                                        _vm._v(
                                                                                          "mdi-link-variant"
                                                                                        )
                                                                                      ]
                                                                                    )
                                                                                  ],
                                                                                  1
                                                                                )
                                                                              : _vm._e()
                                                                          ]
                                                                        : _vm.isURL(
                                                                            pva[
                                                                              item
                                                                                .field_name
                                                                            ]
                                                                          )
                                                                        ? [
                                                                            _c(
                                                                              "a",
                                                                              {
                                                                                attrs: {
                                                                                  href: _vm.isURL(
                                                                                    pva[
                                                                                      item
                                                                                        .field_name
                                                                                    ]
                                                                                  )
                                                                                }
                                                                              },
                                                                              [
                                                                                _vm._v(
                                                                                  _vm._s(
                                                                                    pva[
                                                                                      item
                                                                                        .field_name
                                                                                    ]
                                                                                  )
                                                                                )
                                                                              ]
                                                                            )
                                                                          ]
                                                                        : [
                                                                            _vm._v(
                                                                              "\n                          " +
                                                                                _vm._s(
                                                                                  pva[
                                                                                    item
                                                                                      .field_name
                                                                                  ]
                                                                                ) +
                                                                                "\n                          "
                                                                            ),
                                                                            item.portal_field_route
                                                                              ? _c(
                                                                                  "v-btn",
                                                                                  {
                                                                                    attrs: {
                                                                                      color:
                                                                                        "info",
                                                                                      icon:
                                                                                        "",
                                                                                      href: _vm.route(
                                                                                        item.portal_field_route.module.toLowerCase() +
                                                                                          ".linked",
                                                                                        {
                                                                                          field:
                                                                                            item
                                                                                              .portal_field_route
                                                                                              .link_field_id,
                                                                                          value:
                                                                                            pva[
                                                                                              item
                                                                                                .field_name
                                                                                            ]
                                                                                        }
                                                                                      )
                                                                                    }
                                                                                  },
                                                                                  [
                                                                                    _c(
                                                                                      "v-icon",
                                                                                      [
                                                                                        _vm._v(
                                                                                          "mdi-link-variant"
                                                                                        )
                                                                                      ]
                                                                                    )
                                                                                  ],
                                                                                  1
                                                                                )
                                                                              : _vm._e()
                                                                          ]
                                                                    ],
                                                                    2
                                                                  )
                                                                ]
                                                              )
                                                            }
                                                          )
                                                        }
                                                      }
                                                    ],
                                                    null,
                                                    true
                                                  )
                                                })
                                              ],
                                              1
                                            )
                                          : _vm._e()
                                      ]
                                    })
                                  ],
                                  2
                                )
                              }),
                              1
                            )
                          : block.module.includes("Brennstoffanlagen")
                          ? _c(
                              "div",
                              _vm._l(_vm.data["bsa"], function(bsa, index) {
                                return _c(
                                  "v-row",
                                  { key: index },
                                  [
                                    _c("v-col", { attrs: { cols: "12" } }, [
                                      _c("h3", [
                                        _vm._v(
                                          "Thermische Anlage " +
                                            _vm._s(index + 1)
                                        )
                                      ])
                                    ]),
                                    _vm._v(" "),
                                    _vm._l(block.panel_columns, function(
                                      column,
                                      index
                                    ) {
                                      return [
                                        column.portal_fields.length > 0
                                          ? _c(
                                              "v-col",
                                              {
                                                key: index,
                                                attrs: {
                                                  cols: "12",
                                                  lg:
                                                    12 /
                                                    block.panel_columns.length
                                                }
                                              },
                                              [
                                                _c("v-data-iterator", {
                                                  attrs: {
                                                    "disable-filtering": "",
                                                    "disable-pagination": "",
                                                    "disable-sort": "",
                                                    "hide-default-footer": "",
                                                    items: column.portal_fields
                                                  },
                                                  scopedSlots: _vm._u(
                                                    [
                                                      {
                                                        key: "default",
                                                        fn: function(ref) {
                                                          var items = ref.items
                                                          return _vm._l(
                                                            items,
                                                            function(item) {
                                                              return _c(
                                                                "div",
                                                                {
                                                                  key: item.id,
                                                                  staticClass:
                                                                    "fields-item px-4 my-1"
                                                                },
                                                                [
                                                                  _c(
                                                                    "div",
                                                                    {
                                                                      staticClass:
                                                                        "field-title py-2"
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "\n                        " +
                                                                          _vm._s(
                                                                            item.field_name
                                                                          ) +
                                                                          "\n                      "
                                                                      )
                                                                    ]
                                                                  ),
                                                                  _vm._v(" "),
                                                                  _c("div", {
                                                                    staticClass:
                                                                      "field-divider"
                                                                  }),
                                                                  _vm._v(" "),
                                                                  _c(
                                                                    "div",
                                                                    {
                                                                      staticClass:
                                                                        "field-value py-2"
                                                                    },
                                                                    [
                                                                      !(
                                                                        item.field_name in
                                                                        bsa
                                                                      ) ||
                                                                      !bsa[
                                                                        item
                                                                          .field_name
                                                                      ]
                                                                        ? [
                                                                            _vm._v(
                                                                              "\n                          -\n                        "
                                                                            )
                                                                          ]
                                                                        : item.data_type ==
                                                                          "date"
                                                                        ? [
                                                                            _vm._v(
                                                                              "\n                          " +
                                                                                _vm._s(
                                                                                  _vm._f(
                                                                                    "formatDate"
                                                                                  )(
                                                                                    bsa[
                                                                                      item
                                                                                        .field_name
                                                                                    ]
                                                                                  )
                                                                                ) +
                                                                                "\n                          "
                                                                            ),
                                                                            item.portal_field_route
                                                                              ? _c(
                                                                                  "v-btn",
                                                                                  {
                                                                                    attrs: {
                                                                                      color:
                                                                                        "info",
                                                                                      icon:
                                                                                        "",
                                                                                      href: _vm.route(
                                                                                        item.portal_field_route.module.toLowerCase() +
                                                                                          ".linked",
                                                                                        {
                                                                                          field:
                                                                                            item
                                                                                              .portal_field_route
                                                                                              .link_field_id,
                                                                                          value:
                                                                                            bsa[
                                                                                              item
                                                                                                .field_name
                                                                                            ]
                                                                                        }
                                                                                      )
                                                                                    }
                                                                                  },
                                                                                  [
                                                                                    _c(
                                                                                      "v-icon",
                                                                                      [
                                                                                        _vm._v(
                                                                                          "mdi-link-variant"
                                                                                        )
                                                                                      ]
                                                                                    )
                                                                                  ],
                                                                                  1
                                                                                )
                                                                              : _vm._e()
                                                                          ]
                                                                        : item.data_type ==
                                                                          "number"
                                                                        ? [
                                                                            _vm._v(
                                                                              "\n                          " +
                                                                                _vm._s(
                                                                                  _vm._f(
                                                                                    "formatNumber"
                                                                                  )(
                                                                                    bsa[
                                                                                      item
                                                                                        .field_name
                                                                                    ]
                                                                                  )
                                                                                ) +
                                                                                "\n                          "
                                                                            ),
                                                                            item.portal_field_route
                                                                              ? _c(
                                                                                  "v-btn",
                                                                                  {
                                                                                    attrs: {
                                                                                      color:
                                                                                        "info",
                                                                                      icon:
                                                                                        "",
                                                                                      href: _vm.route(
                                                                                        item.portal_field_route.module.toLowerCase() +
                                                                                          ".linked",
                                                                                        {
                                                                                          field:
                                                                                            item
                                                                                              .portal_field_route
                                                                                              .link_field_id,
                                                                                          value:
                                                                                            bsa[
                                                                                              item
                                                                                                .field_name
                                                                                            ]
                                                                                        }
                                                                                      )
                                                                                    }
                                                                                  },
                                                                                  [
                                                                                    _c(
                                                                                      "v-icon",
                                                                                      [
                                                                                        _vm._v(
                                                                                          "mdi-link-variant"
                                                                                        )
                                                                                      ]
                                                                                    )
                                                                                  ],
                                                                                  1
                                                                                )
                                                                              : _vm._e()
                                                                          ]
                                                                        : item.data_type ==
                                                                          "coordinate"
                                                                        ? [
                                                                            _vm._v(
                                                                              "\n                          " +
                                                                                _vm._s(
                                                                                  _vm._f(
                                                                                    "formatCoordinate"
                                                                                  )(
                                                                                    bsa[
                                                                                      item
                                                                                        .field_name
                                                                                    ]
                                                                                  )
                                                                                ) +
                                                                                "\n                          "
                                                                            ),
                                                                            item.portal_field_route
                                                                              ? _c(
                                                                                  "v-btn",
                                                                                  {
                                                                                    attrs: {
                                                                                      color:
                                                                                        "info",
                                                                                      icon:
                                                                                        "",
                                                                                      href: _vm.route(
                                                                                        item.portal_field_route.module.toLowerCase() +
                                                                                          ".linked",
                                                                                        {
                                                                                          field:
                                                                                            item
                                                                                              .portal_field_route
                                                                                              .link_field_id,
                                                                                          value:
                                                                                            bsa[
                                                                                              item
                                                                                                .field_name
                                                                                            ]
                                                                                        }
                                                                                      )
                                                                                    }
                                                                                  },
                                                                                  [
                                                                                    _c(
                                                                                      "v-icon",
                                                                                      [
                                                                                        _vm._v(
                                                                                          "mdi-link-variant"
                                                                                        )
                                                                                      ]
                                                                                    )
                                                                                  ],
                                                                                  1
                                                                                )
                                                                              : _vm._e()
                                                                          ]
                                                                        : _vm.isURL(
                                                                            bsa[
                                                                              item
                                                                                .field_name
                                                                            ]
                                                                          )
                                                                        ? [
                                                                            _c(
                                                                              "a",
                                                                              {
                                                                                attrs: {
                                                                                  href: _vm.isURL(
                                                                                    bsa[
                                                                                      item
                                                                                        .field_name
                                                                                    ]
                                                                                  )
                                                                                }
                                                                              },
                                                                              [
                                                                                _vm._v(
                                                                                  _vm._s(
                                                                                    bsa[
                                                                                      item
                                                                                        .field_name
                                                                                    ]
                                                                                  )
                                                                                )
                                                                              ]
                                                                            )
                                                                          ]
                                                                        : [
                                                                            _vm._v(
                                                                              "\n                          " +
                                                                                _vm._s(
                                                                                  bsa[
                                                                                    item
                                                                                      .field_name
                                                                                  ]
                                                                                ) +
                                                                                "\n                          "
                                                                            ),
                                                                            item.portal_field_route
                                                                              ? _c(
                                                                                  "v-btn",
                                                                                  {
                                                                                    attrs: {
                                                                                      color:
                                                                                        "info",
                                                                                      icon:
                                                                                        "",
                                                                                      href: _vm.route(
                                                                                        item.portal_field_route.module.toLowerCase() +
                                                                                          ".linked",
                                                                                        {
                                                                                          field:
                                                                                            item
                                                                                              .portal_field_route
                                                                                              .link_field_id,
                                                                                          value:
                                                                                            bsa[
                                                                                              item
                                                                                                .field_name
                                                                                            ]
                                                                                        }
                                                                                      )
                                                                                    }
                                                                                  },
                                                                                  [
                                                                                    _c(
                                                                                      "v-icon",
                                                                                      [
                                                                                        _vm._v(
                                                                                          "mdi-link-variant"
                                                                                        )
                                                                                      ]
                                                                                    )
                                                                                  ],
                                                                                  1
                                                                                )
                                                                              : _vm._e()
                                                                          ]
                                                                    ],
                                                                    2
                                                                  )
                                                                ]
                                                              )
                                                            }
                                                          )
                                                        }
                                                      }
                                                    ],
                                                    null,
                                                    true
                                                  )
                                                })
                                              ],
                                              1
                                            )
                                          : _vm._e()
                                      ]
                                    })
                                  ],
                                  2
                                )
                              }),
                              1
                            )
                          : _c(
                              "v-row",
                              {
                                class: {
                                  "flex-nowrap": _vm.$vuetify.breakpoint.lgAndUp
                                }
                              },
                              [
                                _vm._l(block.panel_columns, function(
                                  column,
                                  index
                                ) {
                                  return [
                                    column.portal_fields.length > 0
                                      ? _c(
                                          "v-col",
                                          {
                                            key: index,
                                            attrs: {
                                              cols: "12",
                                              lg:
                                                12 / block.panel_columns.length
                                            }
                                          },
                                          [
                                            _c("v-data-iterator", {
                                              attrs: {
                                                "disable-filtering": "",
                                                "disable-pagination": "",
                                                "disable-sort": "",
                                                "hide-default-footer": "",
                                                items: column.portal_fields
                                              },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "default",
                                                    fn: function(ref) {
                                                      var items = ref.items
                                                      return _vm._l(
                                                        items,
                                                        function(item) {
                                                          return _c(
                                                            "div",
                                                            {
                                                              key: item.id,
                                                              staticClass:
                                                                "fields-item px-4 my-1"
                                                            },
                                                            [
                                                              _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "field-title py-2"
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "\n                      " +
                                                                      _vm._s(
                                                                        item.field_name
                                                                      ) +
                                                                      "\n                      "
                                                                  ),
                                                                  item.description
                                                                    ? [
                                                                        _c(
                                                                          "v-tooltip",
                                                                          {
                                                                            attrs: {
                                                                              bottom:
                                                                                ""
                                                                            },
                                                                            scopedSlots: _vm._u(
                                                                              [
                                                                                {
                                                                                  key:
                                                                                    "activator",
                                                                                  fn: function(
                                                                                    ref
                                                                                  ) {
                                                                                    var on =
                                                                                      ref.on
                                                                                    var attrs =
                                                                                      ref.attrs
                                                                                    return [
                                                                                      _c(
                                                                                        "v-btn",
                                                                                        _vm._g(
                                                                                          _vm._b(
                                                                                            {
                                                                                              attrs: {
                                                                                                color:
                                                                                                  "info",
                                                                                                icon:
                                                                                                  ""
                                                                                              }
                                                                                            },
                                                                                            "v-btn",
                                                                                            attrs,
                                                                                            false
                                                                                          ),
                                                                                          on
                                                                                        ),
                                                                                        [
                                                                                          _c(
                                                                                            "v-icon",
                                                                                            [
                                                                                              _vm._v(
                                                                                                "mdi-information-outline"
                                                                                              )
                                                                                            ]
                                                                                          )
                                                                                        ],
                                                                                        1
                                                                                      )
                                                                                    ]
                                                                                  }
                                                                                }
                                                                              ],
                                                                              null,
                                                                              true
                                                                            )
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              " "
                                                                            ),
                                                                            _c(
                                                                              "span",
                                                                              [
                                                                                _vm._v(
                                                                                  _vm._s(
                                                                                    item.description
                                                                                  )
                                                                                )
                                                                              ]
                                                                            )
                                                                          ]
                                                                        )
                                                                      ]
                                                                    : _vm._e()
                                                                ],
                                                                2
                                                              ),
                                                              _vm._v(" "),
                                                              _c("div", {
                                                                staticClass:
                                                                  "field-divider"
                                                              }),
                                                              _vm._v(" "),
                                                              block.module ==
                                                              _vm.module
                                                                ? _c(
                                                                    "div",
                                                                    {
                                                                      staticClass:
                                                                        "field-value py-2"
                                                                    },
                                                                    [
                                                                      !(
                                                                        item.field_name in
                                                                        _vm.data
                                                                      ) ||
                                                                      !_vm.data[
                                                                        item
                                                                          .field_name
                                                                      ]
                                                                        ? [
                                                                            _vm._v(
                                                                              "\n                        -\n                      "
                                                                            )
                                                                          ]
                                                                        : item.data_type ==
                                                                          "date"
                                                                        ? [
                                                                            _vm._v(
                                                                              "\n                        " +
                                                                                _vm._s(
                                                                                  _vm._f(
                                                                                    "formatDate"
                                                                                  )(
                                                                                    _vm
                                                                                      .data[
                                                                                      item
                                                                                        .field_name
                                                                                    ]
                                                                                  )
                                                                                ) +
                                                                                "\n                        "
                                                                            ),
                                                                            item.portal_field_route
                                                                              ? _c(
                                                                                  "v-btn",
                                                                                  {
                                                                                    attrs: {
                                                                                      color:
                                                                                        "info",
                                                                                      icon:
                                                                                        "",
                                                                                      href: _vm.route(
                                                                                        item.portal_field_route.module.toLowerCase() +
                                                                                          ".linked",
                                                                                        {
                                                                                          field:
                                                                                            item
                                                                                              .portal_field_route
                                                                                              .link_field_id,
                                                                                          value:
                                                                                            _vm
                                                                                              .data[
                                                                                              item
                                                                                                .field_name
                                                                                            ]
                                                                                        }
                                                                                      )
                                                                                    }
                                                                                  },
                                                                                  [
                                                                                    _c(
                                                                                      "v-icon",
                                                                                      [
                                                                                        _vm._v(
                                                                                          "mdi-link-variant"
                                                                                        )
                                                                                      ]
                                                                                    )
                                                                                  ],
                                                                                  1
                                                                                )
                                                                              : _vm._e()
                                                                          ]
                                                                        : item.data_type ==
                                                                          "number"
                                                                        ? [
                                                                            _vm._v(
                                                                              "\n                        " +
                                                                                _vm._s(
                                                                                  _vm._f(
                                                                                    "formatNumber"
                                                                                  )(
                                                                                    _vm
                                                                                      .data[
                                                                                      item
                                                                                        .field_name
                                                                                    ]
                                                                                  )
                                                                                ) +
                                                                                "\n                        "
                                                                            ),
                                                                            item.portal_field_route
                                                                              ? _c(
                                                                                  "v-btn",
                                                                                  {
                                                                                    attrs: {
                                                                                      color:
                                                                                        "info",
                                                                                      icon:
                                                                                        "",
                                                                                      href: _vm.route(
                                                                                        item.portal_field_route.module.toLowerCase() +
                                                                                          ".linked",
                                                                                        {
                                                                                          field:
                                                                                            item
                                                                                              .portal_field_route
                                                                                              .link_field_id,
                                                                                          value:
                                                                                            _vm
                                                                                              .data[
                                                                                              item
                                                                                                .field_name
                                                                                            ]
                                                                                        }
                                                                                      )
                                                                                    }
                                                                                  },
                                                                                  [
                                                                                    _c(
                                                                                      "v-icon",
                                                                                      [
                                                                                        _vm._v(
                                                                                          "mdi-link-variant"
                                                                                        )
                                                                                      ]
                                                                                    )
                                                                                  ],
                                                                                  1
                                                                                )
                                                                              : _vm._e()
                                                                          ]
                                                                        : item.data_type ==
                                                                          "coordinate"
                                                                        ? [
                                                                            _vm._v(
                                                                              "\n                        " +
                                                                                _vm._s(
                                                                                  _vm._f(
                                                                                    "formatCoordinate"
                                                                                  )(
                                                                                    _vm
                                                                                      .data[
                                                                                      item
                                                                                        .field_name
                                                                                    ]
                                                                                  )
                                                                                ) +
                                                                                "\n                        "
                                                                            ),
                                                                            item.portal_field_route
                                                                              ? _c(
                                                                                  "v-btn",
                                                                                  {
                                                                                    attrs: {
                                                                                      color:
                                                                                        "info",
                                                                                      icon:
                                                                                        "",
                                                                                      href: _vm.route(
                                                                                        item.portal_field_route.module.toLowerCase() +
                                                                                          ".linked",
                                                                                        {
                                                                                          field:
                                                                                            item
                                                                                              .portal_field_route
                                                                                              .link_field_id,
                                                                                          value:
                                                                                            _vm
                                                                                              .data[
                                                                                              item
                                                                                                .field_name
                                                                                            ]
                                                                                        }
                                                                                      )
                                                                                    }
                                                                                  },
                                                                                  [
                                                                                    _c(
                                                                                      "v-icon",
                                                                                      [
                                                                                        _vm._v(
                                                                                          "mdi-link-variant"
                                                                                        )
                                                                                      ]
                                                                                    )
                                                                                  ],
                                                                                  1
                                                                                )
                                                                              : _vm._e()
                                                                          ]
                                                                        : _vm.isURL(
                                                                            _vm
                                                                              .data[
                                                                              item
                                                                                .field_name
                                                                            ]
                                                                          )
                                                                        ? [
                                                                            _c(
                                                                              "a",
                                                                              {
                                                                                attrs: {
                                                                                  href: _vm.isURL(
                                                                                    _vm
                                                                                      .data[
                                                                                      item
                                                                                        .field_name
                                                                                    ]
                                                                                  )
                                                                                }
                                                                              },
                                                                              [
                                                                                _vm._v(
                                                                                  _vm._s(
                                                                                    _vm
                                                                                      .data[
                                                                                      item
                                                                                        .field_name
                                                                                    ]
                                                                                  )
                                                                                )
                                                                              ]
                                                                            )
                                                                          ]
                                                                        : [
                                                                            _vm._v(
                                                                              "\n                        " +
                                                                                _vm._s(
                                                                                  _vm
                                                                                    .data[
                                                                                    item
                                                                                      .field_name
                                                                                  ]
                                                                                ) +
                                                                                "\n                        "
                                                                            ),
                                                                            item.portal_field_route
                                                                              ? _c(
                                                                                  "v-btn",
                                                                                  {
                                                                                    attrs: {
                                                                                      color:
                                                                                        "info",
                                                                                      icon:
                                                                                        "",
                                                                                      href: _vm.route(
                                                                                        item.portal_field_route.module.toLowerCase() +
                                                                                          ".linked",
                                                                                        {
                                                                                          field:
                                                                                            item
                                                                                              .portal_field_route
                                                                                              .link_field_id,
                                                                                          value:
                                                                                            _vm
                                                                                              .data[
                                                                                              item
                                                                                                .field_name
                                                                                            ]
                                                                                        }
                                                                                      )
                                                                                    }
                                                                                  },
                                                                                  [
                                                                                    _c(
                                                                                      "v-icon",
                                                                                      [
                                                                                        _vm._v(
                                                                                          "mdi-link-variant"
                                                                                        )
                                                                                      ]
                                                                                    )
                                                                                  ],
                                                                                  1
                                                                                )
                                                                              : _vm._e()
                                                                          ]
                                                                    ],
                                                                    2
                                                                  )
                                                                : _vm.data[
                                                                    block.module.toLowerCase()
                                                                  ]
                                                                ? _c(
                                                                    "div",
                                                                    {
                                                                      staticClass:
                                                                        "field-value py-2"
                                                                    },
                                                                    [
                                                                      !(
                                                                        item.field_name in
                                                                        _vm
                                                                          .data[
                                                                          block.module.toLowerCase()
                                                                        ]
                                                                      ) ||
                                                                      !_vm.data[
                                                                        block.module.toLowerCase()
                                                                      ][
                                                                        item
                                                                          .field_name
                                                                      ]
                                                                        ? [
                                                                            _vm._v(
                                                                              "\n                        -\n                      "
                                                                            )
                                                                          ]
                                                                        : item.data_type ==
                                                                          "date"
                                                                        ? [
                                                                            _vm._v(
                                                                              "\n                        " +
                                                                                _vm._s(
                                                                                  _vm._f(
                                                                                    "formatDate"
                                                                                  )(
                                                                                    _vm
                                                                                      .data[
                                                                                      block.module.toLowerCase()
                                                                                    ][
                                                                                      item
                                                                                        .field_name
                                                                                    ]
                                                                                  )
                                                                                ) +
                                                                                "\n                        "
                                                                            ),
                                                                            item.portal_field_route
                                                                              ? _c(
                                                                                  "v-btn",
                                                                                  {
                                                                                    attrs: {
                                                                                      color:
                                                                                        "info",
                                                                                      icon:
                                                                                        "",
                                                                                      href: _vm.route(
                                                                                        item.portal_field_route.module.toLowerCase() +
                                                                                          ".linked",
                                                                                        {
                                                                                          field:
                                                                                            item
                                                                                              .portal_field_route
                                                                                              .link_field_id,
                                                                                          value:
                                                                                            _vm
                                                                                              .data[
                                                                                              block.module.toLowerCase()
                                                                                            ][
                                                                                              item
                                                                                                .field_name
                                                                                            ]
                                                                                        }
                                                                                      )
                                                                                    }
                                                                                  },
                                                                                  [
                                                                                    _c(
                                                                                      "v-icon",
                                                                                      [
                                                                                        _vm._v(
                                                                                          "mdi-link-variant"
                                                                                        )
                                                                                      ]
                                                                                    )
                                                                                  ],
                                                                                  1
                                                                                )
                                                                              : _vm._e()
                                                                          ]
                                                                        : item.data_type ==
                                                                          "number"
                                                                        ? [
                                                                            _vm._v(
                                                                              "\n                        " +
                                                                                _vm._s(
                                                                                  _vm._f(
                                                                                    "formatNumber"
                                                                                  )(
                                                                                    _vm
                                                                                      .data[
                                                                                      block.module.toLowerCase()
                                                                                    ][
                                                                                      item
                                                                                        .field_name
                                                                                    ]
                                                                                  )
                                                                                ) +
                                                                                "\n                        "
                                                                            ),
                                                                            item.portal_field_route
                                                                              ? _c(
                                                                                  "v-btn",
                                                                                  {
                                                                                    attrs: {
                                                                                      color:
                                                                                        "info",
                                                                                      icon:
                                                                                        "",
                                                                                      href: _vm.route(
                                                                                        item.portal_field_route.module.toLowerCase() +
                                                                                          ".linked",
                                                                                        {
                                                                                          field:
                                                                                            item
                                                                                              .portal_field_route
                                                                                              .link_field_id,
                                                                                          value:
                                                                                            _vm
                                                                                              .data[
                                                                                              block.module.toLowerCase()
                                                                                            ][
                                                                                              item
                                                                                                .field_name
                                                                                            ]
                                                                                        }
                                                                                      )
                                                                                    }
                                                                                  },
                                                                                  [
                                                                                    _c(
                                                                                      "v-icon",
                                                                                      [
                                                                                        _vm._v(
                                                                                          "mdi-link-variant"
                                                                                        )
                                                                                      ]
                                                                                    )
                                                                                  ],
                                                                                  1
                                                                                )
                                                                              : _vm._e()
                                                                          ]
                                                                        : item.data_type ==
                                                                          "coordinate"
                                                                        ? [
                                                                            _vm._v(
                                                                              "\n                        " +
                                                                                _vm._s(
                                                                                  _vm._f(
                                                                                    "formatCoordinate"
                                                                                  )(
                                                                                    _vm
                                                                                      .data[
                                                                                      block.module.toLowerCase()
                                                                                    ][
                                                                                      item
                                                                                        .field_name
                                                                                    ]
                                                                                  )
                                                                                ) +
                                                                                "\n                        "
                                                                            ),
                                                                            item.portal_field_route
                                                                              ? _c(
                                                                                  "v-btn",
                                                                                  {
                                                                                    attrs: {
                                                                                      color:
                                                                                        "info",
                                                                                      icon:
                                                                                        "",
                                                                                      href: _vm.route(
                                                                                        item.portal_field_route.module.toLowerCase() +
                                                                                          ".linked",
                                                                                        {
                                                                                          field:
                                                                                            item
                                                                                              .portal_field_route
                                                                                              .link_field_id,
                                                                                          value:
                                                                                            _vm
                                                                                              .data[
                                                                                              block.module.toLowerCase()
                                                                                            ][
                                                                                              item
                                                                                                .field_name
                                                                                            ]
                                                                                        }
                                                                                      )
                                                                                    }
                                                                                  },
                                                                                  [
                                                                                    _c(
                                                                                      "v-icon",
                                                                                      [
                                                                                        _vm._v(
                                                                                          "mdi-link-variant"
                                                                                        )
                                                                                      ]
                                                                                    )
                                                                                  ],
                                                                                  1
                                                                                )
                                                                              : _vm._e()
                                                                          ]
                                                                        : _vm.isURL(
                                                                            _vm
                                                                              .data[
                                                                              block.module.toLowerCase()
                                                                            ][
                                                                              item
                                                                                .field_name
                                                                            ]
                                                                          )
                                                                        ? [
                                                                            _c(
                                                                              "a",
                                                                              {
                                                                                attrs: {
                                                                                  href: _vm.isURL(
                                                                                    _vm
                                                                                      .data[
                                                                                      block.module.toLowerCase()
                                                                                    ][
                                                                                      item
                                                                                        .field_name
                                                                                    ]
                                                                                  )
                                                                                }
                                                                              },
                                                                              [
                                                                                _vm._v(
                                                                                  _vm._s(
                                                                                    _vm
                                                                                      .data[
                                                                                      block.module.toLowerCase()
                                                                                    ][
                                                                                      item
                                                                                        .field_name
                                                                                    ]
                                                                                  )
                                                                                )
                                                                              ]
                                                                            )
                                                                          ]
                                                                        : [
                                                                            _vm._v(
                                                                              "\n                        " +
                                                                                _vm._s(
                                                                                  _vm
                                                                                    .data[
                                                                                    block.module.toLowerCase()
                                                                                  ][
                                                                                    item
                                                                                      .field_name
                                                                                  ]
                                                                                ) +
                                                                                "\n                        "
                                                                            ),
                                                                            item.portal_field_route
                                                                              ? _c(
                                                                                  "v-btn",
                                                                                  {
                                                                                    attrs: {
                                                                                      color:
                                                                                        "info",
                                                                                      icon:
                                                                                        "",
                                                                                      href: _vm.route(
                                                                                        item.portal_field_route.module.toLowerCase() +
                                                                                          ".linked",
                                                                                        {
                                                                                          field:
                                                                                            item
                                                                                              .portal_field_route
                                                                                              .link_field_id,
                                                                                          value:
                                                                                            _vm
                                                                                              .data[
                                                                                              block.module.toLowerCase()
                                                                                            ][
                                                                                              item
                                                                                                .field_name
                                                                                            ]
                                                                                        }
                                                                                      )
                                                                                    }
                                                                                  },
                                                                                  [
                                                                                    _c(
                                                                                      "v-icon",
                                                                                      [
                                                                                        _vm._v(
                                                                                          "mdi-link-variant"
                                                                                        )
                                                                                      ]
                                                                                    )
                                                                                  ],
                                                                                  1
                                                                                )
                                                                              : _vm._e()
                                                                          ]
                                                                    ],
                                                                    2
                                                                  )
                                                                : _c(
                                                                    "div",
                                                                    {
                                                                      staticClass:
                                                                        "field-value py-2"
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "-"
                                                                      )
                                                                    ]
                                                                  )
                                                            ]
                                                          )
                                                        }
                                                      )
                                                    }
                                                  }
                                                ],
                                                null,
                                                true
                                              )
                                            })
                                          ],
                                          1
                                        )
                                      : _vm._e()
                                  ]
                                })
                              ],
                              2
                            )
                      ],
                      1
                    )
                  : block.type === "map"
                  ? _c(
                      "v-expansion-panel-content",
                      [
                        _c("v-divider", { staticClass: "mb-4" }),
                        _vm._v(" "),
                        _c("google-map", {
                          attrs: {
                            location: _vm.data["location" + block.id],
                            data: _vm.data,
                            wea:
                              _vm.module === "Windparks"
                                ? _vm.data["wea"]
                                : undefined,
                            pva:
                              _vm.module === "Photovoltaikparks"
                                ? _vm.data["pva"]
                                : undefined,
                            bsa:
                              _vm.module === "Brennstoffe"
                                ? _vm.data["bsa"]
                                : undefined,
                            module: _vm.module
                          }
                        })
                      ],
                      1
                    )
                  : _vm._e()
              ],
              1
            )
          }),
          _vm._v(" "),
          _vm.panelsLoaded &&
          (_vm.module === "Organisationen" ||
            _vm.module === "Ansprechpartner" ||
            _vm.module === "Betreiber" ||
            _vm.module === "Eigentümer")
            ? _c(
                "v-row",
                { staticClass: "my-2" },
                [
                  _vm.data.windparks && _vm.data.windparks.length > 0
                    ? _c(
                        "v-col",
                        { attrs: { cols: "12", lg: "4" } },
                        [
                          _c(
                            "v-expansion-panel",
                            { attrs: { "active-class": "active" } },
                            [
                              _c(
                                "v-expansion-panel-header",
                                { staticClass: "text-h6" },
                                [
                                  _c("v-col", { attrs: { cols: "6" } }, [
                                    _vm._v(" Windparks ")
                                  ])
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "v-expansion-panel-content",
                                [
                                  _c("v-divider", { staticClass: "mb-4" }),
                                  _vm._v(" "),
                                  _c(
                                    "v-row",
                                    {
                                      class: {
                                        "flex-nowrap":
                                          _vm.$vuetify.breakpoint.lgAndUp
                                      }
                                    },
                                    [
                                      _c(
                                        "v-col",
                                        { attrs: { cols: "12" } },
                                        [
                                          _c("v-data-iterator", {
                                            attrs: {
                                              "disable-filtering": "",
                                              "disable-pagination": "",
                                              "disable-sort": "",
                                              "hide-default-footer": "",
                                              items: _vm.data.windparks
                                            },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "default",
                                                  fn: function(ref) {
                                                    var items = ref.items
                                                    return _vm._l(
                                                      items,
                                                      function(item) {
                                                        return _c(
                                                          "a",
                                                          {
                                                            key: item.Id,
                                                            staticClass:
                                                              "d-block primary--text",
                                                            attrs: {
                                                              href: _vm.route(
                                                                "windparks.details",
                                                                item.Id
                                                              )
                                                            }
                                                          },
                                                          [
                                                            _vm._v(
                                                              "\n                      " +
                                                                _vm._s(
                                                                  item.Name_Windpark
                                                                ) +
                                                                "\n                    "
                                                            )
                                                          ]
                                                        )
                                                      }
                                                    )
                                                  }
                                                }
                                              ],
                                              null,
                                              false,
                                              3160790697
                                            )
                                          })
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.data.pv_parks && _vm.data.pv_parks.length > 0
                    ? _c(
                        "v-col",
                        { attrs: { cols: "12", lg: "4" } },
                        [
                          _c(
                            "v-expansion-panel",
                            { attrs: { "active-class": "active" } },
                            [
                              _c(
                                "v-expansion-panel-header",
                                { staticClass: "text-h6" },
                                [
                                  _c("v-col", { attrs: { cols: "6" } }, [
                                    _vm._v(" Photovoltaikparks ")
                                  ])
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "v-expansion-panel-content",
                                [
                                  _c("v-divider", { staticClass: "mb-4" }),
                                  _vm._v(" "),
                                  _c(
                                    "v-row",
                                    {
                                      class: {
                                        "flex-nowrap":
                                          _vm.$vuetify.breakpoint.lgAndUp
                                      }
                                    },
                                    [
                                      _c(
                                        "v-col",
                                        { attrs: { cols: "12" } },
                                        [
                                          _c("v-data-iterator", {
                                            attrs: {
                                              "disable-filtering": "",
                                              "disable-pagination": "",
                                              "disable-sort": "",
                                              "hide-default-footer": "",
                                              items: _vm.data.pv_parks
                                            },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "default",
                                                  fn: function(ref) {
                                                    var items = ref.items
                                                    return _vm._l(
                                                      items,
                                                      function(item) {
                                                        return _c(
                                                          "a",
                                                          {
                                                            key: item.Id,
                                                            staticClass:
                                                              "d-block primary--text",
                                                            attrs: {
                                                              href: _vm.route(
                                                                "photovoltaikparks.details",
                                                                item.Id
                                                              )
                                                            }
                                                          },
                                                          [
                                                            _vm._v(
                                                              "\n                      " +
                                                                _vm._s(
                                                                  item.Name_PV_Park
                                                                ) +
                                                                "\n                    "
                                                            )
                                                          ]
                                                        )
                                                      }
                                                    )
                                                  }
                                                }
                                              ],
                                              null,
                                              false,
                                              3660897726
                                            )
                                          })
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.data.brennstoffe && _vm.data.brennstoffe.length > 0
                    ? _c(
                        "v-col",
                        { attrs: { cols: "12", lg: "4" } },
                        [
                          _c(
                            "v-expansion-panel",
                            { attrs: { "active-class": "active" } },
                            [
                              _c(
                                "v-expansion-panel-header",
                                { staticClass: "text-h6" },
                                [
                                  _c("v-col", { attrs: { cols: "6" } }, [
                                    _vm._v(" Brennstoffe ")
                                  ])
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "v-expansion-panel-content",
                                [
                                  _c("v-divider", { staticClass: "mb-4" }),
                                  _vm._v(" "),
                                  _c(
                                    "v-row",
                                    {
                                      class: {
                                        "flex-nowrap":
                                          _vm.$vuetify.breakpoint.lgAndUp
                                      }
                                    },
                                    [
                                      _c(
                                        "v-col",
                                        { attrs: { cols: "12" } },
                                        [
                                          _c("v-data-iterator", {
                                            attrs: {
                                              "disable-filtering": "",
                                              "disable-pagination": "",
                                              "disable-sort": "",
                                              "hide-default-footer": "",
                                              items: _vm.data.brennstoffe
                                            },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "default",
                                                  fn: function(ref) {
                                                    var items = ref.items
                                                    return _vm._l(
                                                      items,
                                                      function(item) {
                                                        return _c(
                                                          "a",
                                                          {
                                                            key: item.Id,
                                                            staticClass:
                                                              "d-block primary--text",
                                                            attrs: {
                                                              href: _vm.route(
                                                                "brennstoffe.details",
                                                                item.Id
                                                              )
                                                            }
                                                          },
                                                          [
                                                            _vm._v(
                                                              "\n                      " +
                                                                _vm._s(
                                                                  item.Name_Anlage
                                                                ) +
                                                                "\n                    "
                                                            )
                                                          ]
                                                        )
                                                      }
                                                    )
                                                  }
                                                }
                                              ],
                                              null,
                                              false,
                                              2258143234
                                            )
                                          })
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e()
                ],
                1
              )
            : _vm._e()
        ],
        2
      ),
      _vm._v(" "),
      _c(
        "v-dialog",
        {
          attrs: { "max-width": "400" },
          model: {
            value: _vm.show,
            callback: function($$v) {
              _vm.show = $$v
            },
            expression: "show"
          }
        },
        [
          _c(
            "v-card",
            [
              _c(
                "v-toolbar",
                { attrs: { color: "info", dark: "", flat: "" } },
                [_c("v-toolbar-title", [_vm._v("Daten exportieren")])],
                1
              ),
              _vm._v(" "),
              _c(
                "v-card-text",
                { staticClass: "pt-4 px-4" },
                [
                  _c(
                    "v-form",
                    {
                      ref: "exportSettings",
                      model: {
                        value: _vm.exportValid,
                        callback: function($$v) {
                          _vm.exportValid = $$v
                        },
                        expression: "exportValid"
                      }
                    },
                    [
                      _c("v-text-field", {
                        staticClass: "mb-4",
                        attrs: {
                          rules: _vm.validationRules,
                          filled: "",
                          dense: "",
                          "hide-details": "auto",
                          label: "Dateiname"
                        },
                        model: {
                          value: _vm.fileName,
                          callback: function($$v) {
                            _vm.fileName = $$v
                          },
                          expression: "fileName"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-card-actions",
                [
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "error", "x-small": "" },
                      on: {
                        click: function($event) {
                          $event.stopPropagation()
                          return _vm.$emit("close")
                        }
                      }
                    },
                    [_vm._v("Abbrechen")]
                  ),
                  _vm._v(" "),
                  _c("v-spacer"),
                  _vm._v(" "),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "primary", type: "btn", small: "" },
                      on: { click: _vm.exportData }
                    },
                    [_vm._v("Exportieren")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }